import React, { Component } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { connect } from 'react-redux';
import { TabMenu } from 'primereact/tabmenu';
import { InputSwitch } from 'primereact/inputswitch';
import Authorizer, { PERMISSIONS, isAuthorized } from '../../session/authorizer';
import Authentication from '../../session';
import { baseUrlAdmin, primaryColor } from '../../../store/apiConstants';
import withRouter from '../../lib/withRouter';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { AcademicSessionCalIcon, AddIconInGrade, AssignFormIcon, BoardsIconActive, BoardsIconInActive, ClassetEditIcon, CodeLinkIcon, GradesIcon, GradesIconActive } from '../../svgIcons';
import { getFormFields, isFormValid, onDropDownChange, onNumberChange, onTextChange } from '../../../utile/formHelper';
import { Dropdown } from 'primereact/dropdown';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import { getBoardsData, getBranchesLatest } from '../../../store/actions';
import LoadingComponent from '../../loadingComponent';
import { trimObj } from '../../../utile';
import { cloneDeep } from 'lodash';
import uuidv4 from 'uuid/v4';
import Service from '../../../services';
import { Toast } from 'primereact/toast';
import { bgsSortByPriority } from '../../../store/selectors/userAssignedBoards';

const createGradeFields = require('./createGradeFields.json');

const baseSection = [
    {
        sectionName: '',
        sectionCode: '',
        description: '',
        priority: null,
        isActive: true,
        autoCreate: false,
        maxStrength: null
    }
];
class GradeSection extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createGradeFields);

        this.state = {
            isDialogOpenForCreateGrade: false,
            isDialogOpenForCreateSection: false,
            grade: this.formFields.data,
            formValidations: this.formFields.formValidations,
            description: '',
            editGrade: false,
            editSection: false,
            selectedBoard: {},
            selectedGrade: {},
            sections: baseSection,
            selectedBoardIndex: 0,
            selectedGradeIndex: 0
        };
        this.service = new Service();
    }

    componentDidMount() {
        this.props.getBoardsData(true);
        this.props.getBranchesLatest(true);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.branchData)

        if (this.state.selectedBoard?.boardId == undefined && prevProps.boards?.length) {
            this.setState({ selectedBoard: prevProps.boards[0], selectedGrade: prevProps.boards[0]?.classes[0], }, () => this.getBranchDropdownValues());
        }
    }
    getBranchDropdownValues = () => {
        if (this.props.branchData) {
            this.setState({ branchesDdValues: this.props?.branchData?.filter((obj) => obj.level === 1 && this.state.selectedBoard?.assignedBranches?.includes(obj.key)).map((obj) => ({ value: obj.key, label: obj.name.toUpperCase() })) });
        }
    };
    getGradesBySelectedBoard = (board, index) => {
        this.setState({ selectedBoard: board, selectedBoardIndex: index, selectedGrade: board.classes[0], selectedGradeIndex: 0 }, () => this.getBranchDropdownValues());
    };
    getSectionssBySelectedGrade = (grade, index) => {
        this.setState({ selectedGrade: grade, grade: grade, selectedGradeIndex: index });
    };

    onSubmitCreateOrUpdatedGrade = () => {
        const formStatus = isFormValid(createGradeFields, this.formFields.formValidations, trimObj(this.state.grade));

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            });
        } else {
            this.props.getBoardsData(true);
            let gradePayload = cloneDeep(this.state.grade);

            gradePayload.boardId = this.state.selectedBoard?.boardId;

            if (this.state.editGrade) {
            } else {
                gradePayload.classId = uuidv4();
                gradePayload.isActivity = true;
                gradePayload.groups = [
                    {
                        groupId: uuidv4(),
                        groupName: 'NO_GROUP',
                        isGroupActivity: true
                    }
                ];
            }
            console.log(gradePayload);

            this.setState({
                isLoading: true
            });
            let url = `${baseUrlAdmin}/organization/grade`;

            let etag = this.props?.boardsDataFor?.etag;
            let headers = {
                'If-Match': etag
            };

            this.service
                .post(url, gradePayload, true, headers)
                .then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.state.editGrade
                            ? this.toast.show({ severity: 'success', summary: 'Grade Updated Successfully', detail: ``, life: 3000 })
                            : this.toast.show({ severity: 'success', summary: 'Grade Created Successfully', detail: ``, life: 3000 });
                        this.setState(
                            {
                                isLoading: false,
                                isDialogOpenForCreateGrade: false,
                                selectedGradeIndex: this.props?.boards[this.state.selectedBoardIndex]?.classes.length - 1 || 0,
                                editGrade: false
                            },
                            () => {
                                this.props.getBoardsData(true);
                            }
                        );
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch((err) => {
                    console.log(err, 'err');
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                });
        }
    };

    addSectionFields = (name, value, index) => {
        let sections = cloneDeep(this.state.sections);
        sections[index][name] = value;
        this.setState({
            sections: sections
        });
    };

    createSection = () => {
        let sectionForm = cloneDeep(this.state.sections);

        if (!this.state?.selectedBranchId?.length) {
            this.toast.show({ severity: 'error', summary: `Branch Selection is required. Please Select the Branch Dropdown  `, detail: '', life: 3000 });
            return;
        }

        let validation = true;
        sectionForm.map((section, index) => {
            if (!section.sectionName.length) {
                this.toast.show({ severity: 'error', summary: `Section name is required ,Please enter section name  `, detail: '', life: 3000 });
                validation = false;
                return;
            }
            if (!section.sectionCode.length) {
                this.toast.show({ severity: 'error', summary: `Section code  is required ,Please enter section code  `, detail: '', life: 3000 });
                validation = false;
                return;
            }
            // if (section.sectionCode.length !== 3) {
            //     this.toast.show({ severity: 'error', summary: `Section code must be 3 characters long.`, detail: '', life: 3000 });
            //     validation = false;
            //     return;
            // }
            if (section.priority < 0 || section.priority == null || section.priority.length <= 0) {
                this.toast.show({ severity: 'error', summary: `Section Priority  is required ,Please enter positive number  `, detail: '', life: 3000 });
                validation = false;
                return;
            }
            if (section.maxStrength < 0 || section.maxStrength == null || section.maxStrength.length <= 0) {
                this.toast.show({ severity: 'error', summary: `Section Max strength  is required Please enter positive number  `, detail: '', life: 3000 });
                validation = false;
                return;
            }
        });

        if (validation) {
            let SectionPayload = {
                boardId: this.state.selectedBoard.boardId,
                classId: this.state.selectedGrade.classId,
                branchId: this.state.selectedBranchId,
                branchName: this.state.branchesDdValues.find((branch) => branch.value == this.state.selectedBranchId).label,
                academicYear: localStorage.getItem('userAcademicYear')
            };
            if (this.state.editSection) {
                SectionPayload.sections = sectionForm;
            } else {
                sectionForm.forEach((section) => {
                    section.sectionId = uuidv4();
                    section.maxStrength = Number(section.maxStrength);
                    section.priority = Number(section.priority);
                });
                SectionPayload.sections = sectionForm;
            }

            let url = `${baseUrlAdmin}/organization/section`;

            let etag = this.props?.boardsDataFor?.etag;
            let headers = {
                'If-Match': etag
            };
            this.setState({
                isLoading: true
            });
            this.service
                .post(url, SectionPayload, true, headers)
                .then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.state.editSection
                            ? this.toast.show({ severity: 'success', summary: 'Section Updated Successfully', detail: ``, life: 3000 })
                            : this.toast.show({ severity: 'success', summary: 'Section Created Successfully', detail: ``, life: 3000 });
                        this.setState(
                            {
                                isLoading: false,
                                isDialogOpenForCreateSection: false,
                                editSection: false,
                                sections: baseSection
                            },
                            () => {
                                this.props.getBoardsData(true);
                            }
                        );
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch((err) => {
                    console.log(err, 'err');
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
                });
        }
    };

    addNewSection = () => {
        let sec = cloneDeep(this.state.sections[this.state.sections.length - 1]);
        if (this.state.selectedBranchId?.length && sec.sectionName?.length > 0 && sec.priority > 0 && sec.sectionCode?.length > 0 && sec.sectionCode?.length === 3 && sec.maxStrength > 0) {
            this.setState({
                sections: [...this.state.sections, ...baseSection]
            });
        } else {
            this.state.sections.map((section, index) => {
                if (!section.sectionName.length) {
                    this.toast.show({ severity: 'error', summary: `Section name is required ,Please enter section name  `, detail: '', life: 3000 });
                    return;
                }
                if (!section.sectionCode.length) {
                    this.toast.show({ severity: 'error', summary: `Section code  is required ,Please enter section code  `, detail: '', life: 3000 });
                    return;
                }
                if (section.sectionCode.length != 3) {
                    this.toast.show({ severity: 'error', summary: `Section code should be 3 Characters  `, detail: '', life: 3000 });
                    return;
                }
                if (section.priority < 0 || section.priority == null || section.priority.length <= 0) {
                    this.toast.show({ severity: 'error', summary: `Section Priority  is required ,Please enter positive number  `, detail: '', life: 3000 });
                    return;
                }
                if (section.maxStrength < 0 || section.maxStrength == null || section.priority.length <= 0) {
                    this.toast.show({ severity: 'error', summary: `Section Max strength  is required Please enter positive number  `, detail: '', life: 3000 });
                    return;
                }
            });
        }
    };

    render() {
        const { grade, formValidations } = this.state;

        return (
            <div>
                <div className="grid col-12 mt-1">
                    <div className="col-4 mr-12">
                        <h4 style={{ fontWeight: 'bold' }}>Boards</h4>
                        <div>
                            <div className="ma-scroll-vr-smoke" style={{ height: '63vh' }}>
                                {this.props?.boards?.map((board, index) => {
                                    return (
                                        <div
                                            className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5 "
                                            style={{ backgroundColor: board.boardId == this.state.selectedBoard?.boardId ? '#D1E4FF' : '' }}
                                            onClick={() => this.getGradesBySelectedBoard(board, index)}
                                        >
                                            <div className="flex flex-row justify-content-start">
                                                <div className="mr-3">{board.boardId == this.state.selectedBoard?.boardId ? <BoardsIconActive height={16.5} width={19.5} /> : <BoardsIconInActive height={16.5} width={19.5} />}</div>
                                                <div className="-mt-3">
                                                    <div className="w-full flex justify-content-start ">
                                                        <p className="Montserrat24" style={{ fontWeight: 'bold', fontSize: '16px', color: board.boardId == this.state.selectedBoard?.boardId ? '#0056D0' : '' }}>
                                                            {board.boardName}
                                                        </p>
                                                    </div>
                                                    <p style={{ fontSize: '15px', color: board.boardId == this.state.selectedBoard?.boardId ? '#0056D0' : '' }}>{board?.classes?.length + ' Grades'}</p>
                                                </div>
                                                {/* <div className='-mt-3'>
                                                    <div className="w-full flex justify-content-start ">
                                                        <p className='Montserrat24' style={{ fontWeight: 'bold', fontSize: '16px', color: board.boardId == this.state.selectedBoard?.boardId ? '#0056D0' : '' }}>{board.boardName}</p>
                                                        <p style={{ fontSize: '15px', }}>{board.classes?.length + ' Grades'}</p>
                                                    </div>
                                                    <p style={{ fontSize: '15px', color: board.boardId == this.state.selectedBoard?.boardId ? '#0056D0' : '' }}>{board.admission}</p>
                                                </div> */}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-4 mr-12">
                        {this.state.selectedBoard ? <h4 style={{ fontWeight: 'bold' }}>Grades</h4> : null}
                        <div className="ma-scroll-vr-smoke" style={{ maxHeight: '63vh', overflowY: 'auto' }}>
                            {this.props?.boards &&
                                this.props?.boards[this.state.selectedBoardIndex]?.classes?.map((grade, index) => (
                                    <div
                                        key={index}
                                        className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5"
                                        style={{ backgroundColor: grade.classId == this.state.selectedGrade?.classId ? '#E6E8EA' : '' }}
                                        onClick={() => this.getSectionssBySelectedGrade(grade, index)}
                                    >
                                        <div className="flex flex-row justify-content-between">
                                            <div className="flex flex-row justify-content-start">
                                                <div className="mr-3">
                                                    <GradesIcon height={16.5} width={19.5} />
                                                </div>
                                                <div className="-mt-3">
                                                    <div className="w-full flex justify-content-start ">
                                                        <p className="Montserrat24" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                            {grade.className}
                                                        </p>
                                                    </div>
                                                    <p style={{ fontSize: '15px' }}>{grade.sections?.length} Sections</p>
                                                </div>
                                            </div>
                                            <Authorizer permId={PERMISSIONS.UPDATE_GRADE}>
                                                <div onClick={() => this.setState({ isDialogOpenForCreateGrade: true, grade: this.state.selectedGrade, editGrade: true })}>
                                                    <ClassetEditIcon width={18.75} height={18.75} color={'#000000'} />
                                                </div>
                                            </Authorizer>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {
                            <Authorizer permId={PERMISSIONS.CREATE_GRADE}>
                                {this.state?.selectedBoard?.boardId ? (
                                    <div
                                        className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5"
                                        style={{ backgroundColor: '#D1E4FF' }}
                                        onClick={() => this.setState({ isDialogOpenForCreateGrade: true, editGrade: false, grade: {} })}
                                    >
                                        <div className="flex flex-row justify-content-between">
                                            <div className="flex flex-row justify-content-start">
                                                <div className="mr-3">
                                                    <GradesIconActive />
                                                </div>
                                                <div className="-mt-2">
                                                    <div className="w-full flex justify-content-start ">
                                                        <p className="inter24Text" style={{ color: '#0056D0' }}>
                                                            {'Add Grade'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <AddIconInGrade />
                                        </div>
                                    </div>
                                ) : null}
                            </Authorizer>
                        }
                    </div>

                    <div className="col-4 mr-12">
                        {this.state.selectedGrade?.className ? <h4 style={{ fontWeight: 'bold' }}>Sections</h4> : null}
                        <div className="ma-scroll-vr-smoke" style={{ maxHeight: '63vh', overflowY: 'auto' }}>
                            {this.props?.boards &&
                                this.props?.boards[this.state.selectedBoardIndex]?.classes &&
                                this.props?.boards[this.state.selectedBoardIndex]?.classes[this.state.selectedGradeIndex]?.sections?.map((section, index) => (
                                    <div key={index} className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5">
                                        <div className="flex flex-row justify-content-between">
                                            <div className="flex flex-row justify-content-start">
                                                <div className="mr-3">
                                                    <GradesIcon height={16.5} width={19.5} />
                                                </div>
                                                <div className="-mt-3">
                                                    <div className="w-full flex justify-content-start">
                                                        <p className="Montserrat24" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                            {section.sectionName}
                                                        </p>
                                                    </div>
                                                    <p style={{ fontSize: '15px' }}>{section.students}</p>
                                                </div>
                                            </div>
                                            <Authorizer permId={PERMISSIONS.CREATE_SECTION}>
                                                <div onClick={() => this.setState({ isDialogOpenForCreateSection: true, sections: [section], editSection: true, selectedBranchId: section.branchId })}>
                                                    <ClassetEditIcon width={18.75} height={18.75} color={'#000000'} />
                                                </div>
                                            </Authorizer>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {this.props?.boards && this.props?.boards[this.state.selectedBoardIndex]?.classes.length && this.state.selectedGradeIndex >= 0 ? (
                            <Authorizer permId={PERMISSIONS.CREATE_SECTION}>
                                <div
                                    className="mx-3 flex flex-column col-10 justify-content-center card cursor-pointer mb-2 h-6rem shadow-5"
                                    style={{ backgroundColor: '#D1E4FF' }}
                                    onClick={() => this.setState({ isDialogOpenForCreateSection: true })}
                                >
                                    <div className="flex flex-row justify-content-between">
                                        <div className="flex flex-row justify-content-start">
                                            <div className="mr-3">
                                                <GradesIconActive />
                                            </div>
                                            <div className="-mt-2">
                                                <div className="w-full flex justify-content-start ">
                                                    <p className="inter24Text" style={{ color: '#0056D0' }}>
                                                        {'Add Section'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <AddIconInGrade />
                                    </div>
                                </div>
                            </Authorizer>
                        ) : <></>}
                    </div>
                </div>
                <Dialog
                    visible={this.state.isDialogOpenForCreateGrade}
                    draggable={false}
                    closeOnEscape={false}
                    // position={"right"}
                    className="ma-alert-box "
                    style={{ width: '1000px', height: '700px' }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (
                            <div className="mt-2">
                                <h4 className="formHeadingInter">Add Grade</h4>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button style={{ borderRadius: '10px' }} className="formSubmitButton px-8" onClick={() => this.onSubmitCreateOrUpdatedGrade()}>
                                <p className="formSubmitButtonText">{this.state.editGrade ? 'Update' : 'Create'}</p>
                            </Button>
                            <Button style={{ borderRadius: '10px' }} className="formCancelButton px-6" onClick={() => this.setState({ grade: {}, isDialogOpenForCreateGrade: false, editGrade: false, })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ grade: {}, isDialogOpenForCreateGrade: false, editGrade: false })}
                >
                    <div className=" pl-5">
                        <div className="grid col-12 px-8 ">
                            <div className=" col-6">
                                <ClassetInputText
                                    title="GradeName"
                                    width={'100%'}
                                    required={true}
                                    label={'Grade Name'}
                                    icon={<GradesIcon />}
                                    placeholder={'Grade '}
                                    tooltipInfo={
                                        <div>
                                            <p className="font-semibold text-sm">Enter the Name of Grade </p>
                                            <p className="-mt-1">Name will be displayed throughout</p>
                                        </div>
                                    }
                                    value={grade.className}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'className', this, createGradeFields, grade, formValidations, 'grade', 'formValidations');
                                    }}
                                />

                                {formValidations && !formValidations.fields['className'].isValid && <p className="p-error font-semibold">{formValidations.fields['className'].errorMsg}</p>}
                            </div>
                            <div className=" col-6 ">
                                <ClassetInputText
                                    required={true}
                                    width={'100%'}
                                    label={'Grade Code'}
                                    icon={<CodeLinkIcon height={22} />}
                                    title={'GradeCode'}
                                    placeholder={'Code (Alpha Numeric)'}
                                    tooltipInfo={
                                        <div>
                                            <p className="font-semibold text-sm">Enter the Code of Grade </p>
                                            <p className="-mt-1">Code will be displayed throughout</p>
                                        </div>
                                    }
                                    value={grade.classCode}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'classCode', this, createGradeFields, grade, formValidations, 'grade', 'formValidations');
                                    }}
                                />
                                {formValidations && !formValidations.fields['classCode'].isValid && <p className="p-error font-semibold">{formValidations.fields['classCode'].errorMsg}</p>}
                            </div>
                            <div className=" col-6 ">
                                <ClassetInputText
                                    required={true}
                                    width={'100%'}
                                    label={'Board'}
                                    icon={<CodeLinkIcon height={22} />}
                                    title={'Board'}
                                    tooltipInfo={
                                        <div>
                                            <p className="font-semibold text-sm">Enter the Board of Grade </p>
                                            <p className="-mt-1">Board will be displayed throughout</p>
                                        </div>
                                    }
                                    value={this.state?.selectedBoard?.boardName}
                                    disabled={true}
                                />
                            </div>
                            <div className=" col-6 ">
                                <ClassetInputText
                                    required={true}
                                    width={'100%'}
                                    label={'Priority'}
                                    icon={<CodeLinkIcon height={22} />}
                                    placeholder={'Code (Alpha Numeric)'}
                                    title={'Priority'}
                                    tooltipInfo={
                                        <div>
                                            <p className="font-semibold text-sm">Enter the Priority of Grade </p>
                                            <p className="-mt-1">Priority will be displayed throughout</p>
                                        </div>
                                    }
                                    value={grade.priority}
                                    onChange={(e) => {
                                        onNumberChange(e.target.value, 'priority', this, createGradeFields, grade, formValidations, 'grade', 'formValidations');
                                    }}
                                    type="number"
                                />
                                {formValidations && !formValidations.fields['priority'].isValid && <p className="p-error font-semibold">{formValidations.fields['priority'].errorMsg}</p>}
                            </div>
                            <div className="col-12  ">
                                <ClassetTextArea
                                    width={'100%'}
                                    style={{ height: '119px' }}
                                    label={'Description'}
                                    title={'Description'}
                                    tooltipInfo={
                                        <div>
                                            <p className="font-semibold text-sm">Description</p>
                                            <p className="-mt-1">Write a brief to understand this Grade for others</p>
                                        </div>
                                    }
                                    value={grade.description}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'description', this, createGradeFields, grade, formValidations, 'grade', 'formValidations');
                                    }}
                                    placeholder="Description"
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.isDialogOpenForCreateSection}
                    draggable={false}
                    closeOnEscape={false}
                    // position={"right"}
                    className="ma-alert-box"
                    style={{ width: '65vw' }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (
                            <div className="mt-2">
                                <h4 className="formHeadingInter">Add Section</h4>
                            </div>
                        );
                    }}
                    // footer={() => (

                    // )}
                    onHide={() => this.setState({ isDialogOpenForCreateSection: false, sections: baseSection, editSection: false })}
                >
                    <div className="mx-5">
                        <div className="ml-3">
                            <ClassetDropdown
                                required={true}
                                label={'Branch'}
                                icon={<AcademicSessionCalIcon />}
                                placeholder={'Select Branch'}
                                options={this.state.branchesDdValues}
                                onChange={(e) => this.setState({ selectedBranchId: e.target.value })}
                                value={this.state.selectedBranchId}
                            />
                            <div className="my-3" style={{ backgroundColor: 'black', height: 0.5, width: '100%', padding: 0 }}></div>
                        </div>
                        {this.state?.sections?.map((section, index) => {
                            return (
                                <div>
                                    <div className="grid col-12 justify-content-center ">
                                        <div className=" col-6 ">
                                            <ClassetInputText
                                                title="SectionName"
                                                required={true}
                                                label={'Section Name'}
                                                icon={<GradesIcon />}
                                                placeholder={'Section Name '}
                                                tooltipInfo={
                                                    <div>
                                                        <p className="font-semibold text-sm">Enter the Name of Section</p>
                                                        <p className="-mt-1">Name will be displayed throughout</p>
                                                    </div>
                                                }
                                                value={section.sectionName}
                                                onChange={(e) => this.addSectionFields('sectionName', e.target.value, index)}
                                            />
                                        </div>

                                        <div className=" col-6 ">
                                            <ClassetInputText
                                                title="SectionCode"
                                                required={true}
                                                label={'Section Code'}
                                                icon={<CodeLinkIcon height={22} />}
                                                placeholder={'Code (Alpha Numeric) '}
                                                tooltipInfo={
                                                    <div>
                                                        <p className="font-semibold text-sm">Enter the Code of Section</p>
                                                        <p className="-mt-1">Code will be displayed throughout</p>
                                                    </div>
                                                }
                                                value={section.sectionCode}
                                                onChange={(e) => this.addSectionFields('sectionCode', e.target.value, index)}
                                            />
                                        </div>

                                        <div className=" col-6 ">
                                            <ClassetInputText
                                                title="Priority"
                                                required={true}
                                                label={'Priority'}
                                                icon={<CodeLinkIcon height={22} />}
                                                type="number"
                                                placeholder={'Priority (Numeric) '}
                                                value={section.priority}
                                                tooltipInfo={
                                                    <div>
                                                        <p className="font-semibold text-sm">Enter the Priority of Section</p>
                                                        <p className="-mt-1">Priority will be displayed throughout</p>
                                                    </div>
                                                }
                                                onChange={(e) => this.addSectionFields('priority', e.target.value, index)}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <label htmlFor="description" className="block font-serif font-semibold text-900 text-lg">
                                                Description
                                            </label>
                                            <div className="">
                                                <ClassetTextArea
                                                    title="Description"
                                                    className=" -pt-5 "
                                                    id="description"
                                                    placeholder="Description"
                                                    rows={3}
                                                    value={section.description}
                                                    tooltipInfo={
                                                        <div>
                                                            <p className="font-semibold text-sm">Description</p>
                                                            <p className="-mt-1">Write a brief to understand this section for others</p>
                                                        </div>
                                                    }
                                                    onChange={(e) => this.addSectionFields('description', e.target.value, index)}
                                                />
                                            </div>
                                        </div>
                                        <div className=" col-6 ">
                                            <ClassetInputText
                                                required={true}
                                                label={'Max Section Strength'}
                                                icon={<CodeLinkIcon height={22} />}
                                                placeholder={'Numeric'}
                                                type="number"
                                                value={section.maxStrength}
                                                onChange={(e) => this.addSectionFields('maxStrength', e.target.value, index)}
                                            />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <div className="flex ">
                                                <div className="mr-3">
                                                    <Checkbox checked={section.autoCreate} onChange={(e) => this.addSectionFields('autoCreate', !section.autoCreate, index)} />
                                                </div>
                                                <div>
                                                    <p className="ma-label-s2 font-serif font-semibold text-900 text-lg">{'AutoCreate Sections'}</p>
                                                    <p className="inter24Text" style={{ fontSize: '12px', color: '#4E616B' }}>
                                                        {'Sections will be autocreated as the maxstrength threshhold is reached'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ backgroundColor: 'black', height: 0.5, width: '100%', padding: 0 }}></div>
                                </div>
                            );
                        })}
                        {!this.state.editSection && (
                            <div className="m-4">
                                <Button className="formSubmitButton" style={{ borderRadius: 10 }} onClick={() => this.addNewSection()}>
                                    <p className="formSubmitButtonText"> Add Section </p>
                                </Button>
                            </div>
                        )}

                        <div className="flex gap-3 justify-content-center my-4">
                            <Button style={{ borderRadius: '10px' }} className="formSubmitButton px-4" onClick={() => this.createSection()}>
                                <p className="formSubmitButtonText px-5"> {this.state.editSection ? 'Update' : 'Create'} </p>
                            </Button>
                            <Button style={{ borderRadius: '10px' }} className="formCancelButton px-6" onClick={() => this.setState({ isDialogOpenForCreateSection: false, sections: baseSection, editSection: false })}>
                                <p className="formCancelButtonText ">Cancel</p>
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {(this.props.isBoardsLoading || this.state.isLoading) && (
                    <>
                        <LoadingComponent />
                    </>
                )}
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({
    isBoardsLoading: state.boardsData.isLoading,
    boards: bgsSortByPriority(state.boardsData.boards),
    boardsDataFor: state.boardsData,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(Authentication(withRouter(GradeSection)));
