import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '../../lib/withRouter';
import Authentication from './../../session';
import { getBranchesLatest } from '../../../store/actions';
import { RadioButton } from 'primereact/radiobutton';
import ClassetStudentSearch from '../../../classetComponents/classetStudentSearch';
import BoardToSectionDD from '../../BaseDropdownComponents/BoardToSectionDD';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { CancelRed, CrossInBox, DownloadIcon, GreenCheck, MailIcon, RightMarkInCircle } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import Service from '../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import moment from 'moment';
import { BasicLazyParams } from '../../../utile';
import { debounce } from 'lodash';
import ClassetInputText from '../../../classetComponents/classetInputText';
import { Dialog } from 'primereact/dialog';
import '../styles.scss';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import BranchToSectionApprovalHierarchy from '../../BaseDropdownComponents/BranchToSectionApprovalHierarchy';

const searchTypeOptions = [
    { label: 'Student Name', value: 'studentName' },
    { label: 'Enrollment Number', value: 'enrollmentNumber' }
];

class FeeUnassignApproval extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lazyParams: BasicLazyParams,
            searchType: 1,
            searchInput: '',
            studentInfo: [],
            raisedFeeRefundData: {},
            studentId: null,
            searchLevel: 1,
            searchType: searchTypeOptions[0].value,
            viewRefundDetails: false,
            remarks: ''
        };
        this.debouncedApiCall = debounce(this.getStudentsInfo, 500);
        this.service = new Service();
    }

    setDropdownValues = (dropdownsData) => {
        this.setState(
            {
                dropdownsData,
                studentId: null
            },
            () => {
                this.getStudentsInfo();
            }
        );
    };

    getStudents = () => {
        let academicYear = localStorage.getItem('userAcademicYear');
        // let { lazyParams } = this.state;
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/fine/student`;
        let payload = {
            branchId: this.state.dropdownsData?.branchId,
            boardId: this.state.dropdownsData?.boardId,
            classId: this.state.dropdownsData?.classId,
            sectionId: this.state.dropdownsData?.sectionId,
            academicYear: academicYear
        };
        if (payload?.sectionId) {
            this.service
                .post(url, payload, true)
                .then((data) => {
                    // console.log(data, "datastudent")
                    const studentsData = data?.res?.data.map((each) => {
                        return {
                            label: each?.name,
                            value: each?.user_id
                        };
                    });

                    if (data?.status) {
                        this.setState({
                            students: studentsData,
                            isLoading: false
                        });
                    } else {
                        this.setState({ isLoading: false });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
                    }
                })
                .catch((error) => {
                    this.setState({ isLoading: false });
                });
        } else {
            this.setState({ isLoading: false });
        }
    };

    onHandleViewFeeConcessions = (data) => {
        this.setState({
            viewRefundDetails: true,
            raisedFeeRefundData: data
        });
    };
    onHandleRefundClose = () => {
        this.setState(
            {
                viewRefundDetails: false,
                raisedFeeRefundData: {},
                remarks: ''
            },
            () => this.getStudentsInfo()
        );
    };
    onShowDetailedReport = () => {
        this.setState({
            isLoading: true
        });
    };
    onHandleApprovedRefunds = () => {
        this.setState({ isLoading: true });
        const { raisedFeeRefundData, remarks } = this.state;
        let isAllRefundChecked = true;

        if (raisedFeeRefundData?.refundFeeTypesData?.length) {
            for (const el of raisedFeeRefundData.refundFeeTypesData) {
                if (!el.hasOwnProperty('isRefundApproved')) {
                    isAllRefundChecked = false;
                    break;
                }
            }
        }
        if (!isAllRefundChecked) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Please approve/reject all the fee types', detail: 'Please approve/reject all the fee types', life: 3000 });
            return;
        }
        let payload = {
            _id: raisedFeeRefundData?._id,
            user_id: raisedFeeRefundData?.user_id,
            isClosed: true,
            refundFeeTypesData: raisedFeeRefundData?.refundFeeTypesData,
            remarks: remarks,
            academicYear: raisedFeeRefundData?.academicYear
        };

        let url = `${baseUrlAdmin}/refund-approval/update-refund-approvals`;
        this.service
            .put(url, payload, true)
            .then((res) => {
                if (res?.status && res?.res?.status) {
                    this.setState(
                        {
                            viewRefundDetails: false,
                            raisedFeeRefundData: {},
                            remarks: ''
                        },
                        () => {
                            this.props.navigate('/closedRefundApprovals');
                        }
                    );
                } else {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((error) => {
                this.setState({ isLoading: false });
            });
    };

    handleSetSearchValue = (value) => {
        this.setState(
            {
                searchValue: value,
                dropdownsData: { ...this.state.dropdownsData, boardId: '', branchId: '', classId: '', sectionId: '' }
            },
            () => {
                this.debouncedApiCall();
            }
        );
    };
    handleSearchLevel = (value) => {
        this.setState({ searchLevel: value, studentId: '', searchValue: '', studentsData: [] });
    };

    onStudentChange = (e) => {
        this.setState({ studentId: e.value }, () => {
            this.getStudentsInfo();
        });
    };

    handleTermWisePercentageValue = (e, rowData) => {
        let concessionAmt = Number(e.target.value);
        if (rowData.dueAmount > 0) {
            if (rowData?.dueAmount >= concessionAmt) {
                const updatedData = this.state.raiseData.map((item) => {
                    if (item._id === rowData._id) {
                        return { ...item, currentConAmt: concessionAmt };
                    }
                    return item;
                });
                this.setState({ raiseData: updatedData });
            }
        }
    };

    handleSetSearchTypeValue = (value) => {
        this.setState({ searchType: value }, () => {
            if (this.state.searchType == 'studentName') {
                this.setState({ isUserNameSearch: true });
            } else {
                this.setState({ isUserEnrollSearch: true });
            }
        });
    };

    getStudentsInfo = () => {
        let { lazyParams, globalSearch, filterData } = this.state;
        this.setState({ isLoading: true, studentInfo: [] });
        const url = `${baseUrlAdmin}/unassign-approval/get-unassign-approvals?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

        let academicYear = localStorage.getItem('userAcademicYear');
        let username = this.state.searchType === 'studentName' ? this.state?.searchValue : '';
        let enrollmentNo = this.state.searchType === 'enrollmentNumber' ? this.state?.searchValue : '';

        let payload = {
            branchId: this.state?.dropdownsData?.branchId,
            boardId: this.state?.dropdownsData?.boardId,
            gradeId: this.state?.dropdownsData?.classId,
            sectionId: this.state?.dropdownsData?.sectionId,
            studentId: this.state?.studentId,
            academicYear: academicYear,
            username: this.state.searchLevel == 2 ? username : '',
            enrollmentNo: enrollmentNo,
            assignedHeirarchy: this.state?.dropdownsData?.heirarchy,
        };

        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    if (res.res?.data && res.res?.data?.length) {
                        this.setState({
                            isLoading: false,
                            studentInfo: res?.res?.data,
                            totalRecords: res?.res?.totalRecords
                        });
                    } else if (res.res?.data?.length == 0) {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'success', summary: 'No records found.', detail: '', life: 3000 });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            });
    };

    onHandleFeUnassignApprove = (rowData, isConfirmed) => {
        this.setState({
            isApproved: isConfirmed ? true : false,
            isRejected: !isConfirmed ? true : false,
            selectedFeeType: rowData
        });
    };
    onUnassignFeeConfirm = (status) => {
        this.setState({
            isLoading: true
        });

        let payload = {
            ...this.state.selectedFeeType,
            isAssigned: status,
            remarks: this.state.remarks,
            handledBy: this.state.selectedFeeType?.handledBy || [],
            levelAndBranchDetails: this.state.dropdownsData?.heirarchy?.adminLevels?.filter((al) => al._id == this.state.selectedFeeType?.branchId)
        };
        let url = `${baseUrlAdmin}/unassign-approval/unassign-fee-approval`;
        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            isRejected: false,
                            isApproved: false,
                            selectedFeeType: {},
                            remarks: ''
                        },
                        () => this.getStudentsInfo()
                    );
                    this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            });
    };

    render() {
        return (
            <div className="ma-main">
                <div className=" datatable-crud-demo ma-m30 mx-2 mb-5">
                    <div className="flex">
                        <div className="flex flex-column justify-content-center align-items-center mt-3">
                            <RadioButton inputId="ingredient1" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                        </div>

                        <div>
                            {/* <BoardToSectionDD selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} /> */}
                            <BranchToSectionApprovalHierarchy selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} approvalType={'feeUnassign'} />
                        </div>
                    </div>
                    {/* <div style={{ marginLeft: '100px' }} className="flex justify-content-start align-items-center">
                        <span className="">OR</span>
                    </div>
                    <div className="flex mt-4">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                        </div>

                        <div className="field ml-4">
                            <ClassetStudentSearch selectedValue={this.state.searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                        </div>
                    </div> */}

                    {/* <div className="col-12 ml-5">
                        <label className="label">
                            Student<span className="ma-required">*</span>
                        </label>
                        <div className="mt-2">
                            <ClassetDropdown
                                icon={<MailIcon />}
                                id="student"
                                className=""
                                height="48px"
                                width="327px"
                                options={this.state?.students}
                                value={this.state.studentId}
                                disabled={this.state?.searchLevel === 2}
                                //     onChange={(e) => {this.setState({studentId:e.value})
                                //     this.getTermBasedData()

                                // }}
                                onChange={this.onStudentChange}
                                placeholder={
                                    <div>
                                        <span className="">Select Student</span>
                                    </div>
                                }
                            />
                        </div>
                    </div> */}
                </div>

                <DataTable
                    ref={(el) => (this.dt = el)}
                    value={this.state.studentInfo}
                    dataKey="id"
                    // paginator
                    lazy
                    responsiveLayout="scroll"
                    className="dataTableValuesText"
                    showGridlines
                    selectionMode={'checkbox'}
                    columnResizeMode="expand"
                    resizableColumns
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))}
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                >
                    <Column
                        alignHeader={'center'}
                        sortable
                        bodyClassName="text-center"
                        header="Student Name"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <div className="flex justify-content-center">
                                    {rowData?.firstName} {rowData?.lastName}
                                </div>
                            );
                        }}
                    ></Column>
                    <Column filterField="" field="enrollmentId" sortable bodyClassName="text-center" header="Enrollment Id" alignHeader={'center'} />
                    <Column filterField="" field="feeType" sortable bodyClassName="text-center" header="Fee Type" alignHeader={'center'} />
                    <Column filterField="" field="totalAmount" sortable bodyClassName="text-center" header="Total Fee" alignHeader={'center'} />
                    <Column filterField="" field="concession" sortable bodyClassName="text-center" header="Concession" alignHeader={'center'} />
                    <Column filterField="" field="fineAmt" sortable bodyClassName="text-center" header="Fine Amount" alignHeader={'center'} />
                    <Column filterField="" field="dueAmount" sortable bodyClassName="text-center" header="Assigned Fee" alignHeader={'center'} />
                    <Column filterField="" field="amountPaid" sortable bodyClassName="text-center" header="Paid Fee" alignHeader={'center'} />
                    <Column
                        style={{ width: '15rem', height: '6rem' }}
                        alignHeader={'center'}
                        sortable
                        headerClassName="text-color-secondary"
                        bodyClassName="text-center"
                        className=" "
                        header="Approve / Reject"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    {rowData?.isRefundApproved == undefined && (
                                        <div className="flex justify-content-evenly">
                                            <div className="cursor-pointer" onClick={() => this.onHandleFeUnassignApprove(rowData, true)}>
                                                <GreenCheck />
                                            </div>

                                            <div className="cursor-pointer" onClick={() => this.onHandleFeUnassignApprove(rowData, false)}>
                                                <CancelRed />
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                        }}
                    ></Column>
                </DataTable>

                <Dialog
                    visible={this.state.isRejected}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <CrossInBox bgcolor={'#BF0031'} color={'white'} />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Reject Unassign Fee</p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Fee Type : {this.state.selectedFeeType?.feeType}</p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Total Amount : {this.state.selectedFeeType?.totalAmount}</p>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#BF0031', border: 'none' }}
                                onClick={() => {
                                    this.onUnassignFeeConfirm(false);
                                }}
                            >
                                <p className="formSubmitButtonText">Reject</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isRejected: false, selectedFeeType: {} })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isRejected: false, selectedFeeType: {} })}
                >
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <div className="">
                            <ClassetTextArea width={'100%'} style={{ height: '119px' }} label={'Remarks:'} placeholder="Remarks" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })} />
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.isApproved}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <RightMarkInCircle bgcolor={'#BF0031'} color={'white'} />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Confirm Unassign Fee </p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Fee Type : {this.state.selectedFeeType?.feeType}</p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Total Amount : {this.state.selectedFeeType?.totalAmount}</p>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#7CDD56', border: 'none' }}
                                onClick={() => {
                                    this.onUnassignFeeConfirm(true);
                                }}
                            >
                                <p className="formSubmitButtonText">Approve</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isApproved: false, selectedFeeType: {} })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isApproved: false, selectedFeeType: {} })}
                >
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <div className="">
                            <ClassetTextArea width={'100%'} style={{ height: '119px' }} label={'Remarks:'} placeholder="Remarks" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })} />
                        </div>
                    </div>
                </Dialog>

                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}

export default withRouter(Authentication(FeeUnassignApproval));
