import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '../../../lib/withRouter';

import { getFormFields, isFormValid, onTextChange, isFieldValid, onNumberChange, onEmailChange, onDropDownChange } from '../../../../utile/formHelper';
import ClassetDropdown from '../../../../classetComponents/classetDropDown';
import { FileAttachmentIcon, MailIcon } from '../../../svgIcons';
import ClassetInputText from '../../../../classetComponents/classetInputText';
import ClassetCalendar from '../../../../classetComponents/classetCalender';
import { Button } from 'primereact/button';
import ClassetMultiSelect from '../../../../classetComponents/classetMultiSelect';
import { convertUtc, trimObj } from '../../../../utile';
import { Toast } from 'primereact/toast';
import FileUpload from '../../../fileUpload';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import Service from '../../../services';
import LoadingComponent from '../../../loadingComponent';
const createInsuranceFields = require('./insurance.json');
const formFields = createInsuranceFields;
class Insurance extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createInsuranceFields);
        this.state = {
            formValidations: this.formFields.formValidations,
            insurances: this.props.vehicleInfo[0]?.insurances ?? this.formFields.data,
            vehicleInfo: this.props.vehicleInfo[0],
            vehicleType: [
                { label: 'Bus', value: 'BUS' },
                { label: 'Car', value: 'CAR' },
                { label: 'Mini Bus', value: 'MINI BUS' },
                { label: 'Mini Van', value: 'MINI VAN' },
                { label: 'Van', value: 'VAN' },
                { label: 'Electric Bus', value: 'ELECTRIC BUS' },
                { label: 'Auto', value: 'AUTO' },
                { label: 'Jeep', value: 'JEEP' }
            ]
        };
        this.service = new Service();
    }
    validateDates = (startDate, expiryDate) => {
        const now = new Date();
        const start = new Date(startDate);
        const expiry = new Date(expiryDate);

        return expiry > now && start < expiry;
    }
    onSave = async () => {
        this.setState({
            isLoading: true
        });
        const { insurances } = this.state;
        insurances.startDate = convertUtc(insurances?.startDate ?? new Date())
        insurances.expiryDate = convertUtc(insurances?.expiryDate ?? new Date())
        const formStatus = isFormValid(createInsuranceFields, this.formFields.formValidations, trimObj(insurances));
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            });
            isCompleteFormValid = false;
        }
        if (!insurances?.uploadInsurancePhoto) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Upload Insurance photo.', life: 3000 });
            return
        }
        let isVaild = await this.validateDates(insurances?.startDate, insurances?.expiryDate)
        if (!isVaild) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: "Error", detail: 'Start date should be less than expiry date', life: 3000 });
            isCompleteFormValid = false
            return
        }

        if (isCompleteFormValid) {
            let payload = {
                insurances: this.state.insurances,
                _id: this.state.vehicleInfo?._id
            };
            const url = `${baseUrlAdmin}/transport-vehicle/vehicle-info`;
            this.service
                .put(url, payload, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState({ isLoading: false });
                        this.props.onSuccessToast()
                        this.props.onCancelMoreInfo()
                        this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Something went wrong.', detail: 'Something went wrong.', life: 3000 });
                });
        } else {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Please fill all the required fields.', detail: 'Please fill all the required fields.', life: 3000 });
        }
    };

    _onProgress = (filesInfo) => { };

    _afterFilesUploaded = (files) => { };
    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    };
    _afterFilesUploaded = (files) => { };
    addAttachment = (fieldName, fileName, data) => {
        this.setState((prevState) => ({
            insurances: {
                ...prevState.insurances,
                [fieldName]: {
                    fileName,
                    storageBucket: data.Bucket,
                    storageKey: data.Key,
                    storageLocation: data.Location
                }
            }
        }));
    };

    _onInsurancePhotoSuccess = (fileName, data) => {
        this.addAttachment('uploadInsurancePhoto', fileName, data);
    };

    render() {
        const { insurances, formValidations, vehicleInfo } = this.state;
        return (
            <div>
                <div className="grid mt-5 mx-5 md:col-11 lg:col-11 column-gap-8  row-gap-6">
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            Registration Number<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText className=" border-round-md  w-full " disabled={true} value={vehicleInfo?.vehicleRegistrationNo} placeholder="Registered Vehicle Number" />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            Vehicle Category<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetDropdown
                                optionLabel="label"
                                optionValue="value"
                                className=" w-full"
                                disabled={true}
                                value={vehicleInfo?.vehicleType}
                                options={this.state.vehicleType}
                                placeholder={
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className="">Select Vehicle Type</span>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            Vehicle Name<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText className=" border-round-md  w-full " disabled={true} value={vehicleInfo?.vehicleName} />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            Insurance Amount<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                className=" border-round-md  w-full "
                                value={insurances?.insuranceAmt}
                                keyfilter="int"
                                onChange={(e) => onTextChange(e.target.value, 'insuranceAmt', this, formFields, insurances, formValidations, 'insurances', 'formValidations')}
                                placeholder="Enter Insurance Amount"
                            />
                        </div>
                        {formValidations && !formValidations.fields['insuranceAmt'].isValid && <p className="p-error">{formValidations.fields['insuranceAmt'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            Insurance Number<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                className=" border-round-md  w-full "
                                value={insurances?.insuranceNo}
                                onChange={(e) => onTextChange(e.target.value, 'insuranceNo', this, formFields, insurances, formValidations, 'insurances', 'formValidations')}
                                placeholder="Enter Insurance Number"
                            />
                        </div>
                        {formValidations && !formValidations.fields['insuranceNo'].isValid && <p className="p-error">{formValidations.fields['insuranceNo'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            Insurance Company<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                className=" border-round-md  w-full "
                                value={insurances?.insuranceCompany}
                                onChange={(e) => onTextChange(e.target.value, 'insuranceCompany', this, formFields, insurances, formValidations, 'insurances', 'formValidations')}
                                placeholder="Enter Insurance Company"
                            />
                        </div>
                        {formValidations && !formValidations.fields['insuranceCompany'].isValid && <p className="p-error">{formValidations.fields['insuranceCompany'].errorMsg}</p>}
                    </div>

                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            Start Date<span className="ma-required">*</span>
                        </p>

                        <div className="flex">
                            <ClassetCalendar
                                icon={<MailIcon width={24} height={24} color={'black'} />}
                                className=" border-round-md add-vehicle-field-input text-2xl w-full"
                                calendarMode={'single'}
                                value={insurances.startDate ? new Date(insurances.startDate) : new Date()}
                                maxDate={new Date()}
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'startDate', this, formFields, insurances, formValidations, 'insurances', 'formValidations');
                                }}
                                placeholder="Start Date"
                            />
                        </div>
                        {formValidations && !formValidations.fields['startDate'].isValid && <p className="p-error">{formValidations.fields['startDate'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            Expiry Date<span className="ma-required">*</span>
                        </p>

                        <div className="flex">
                            <ClassetCalendar
                                icon={<MailIcon width={24} height={24} color={'black'} />}
                                className=" border-round-md add-vehicle-field-input text-2xl w-full"
                                calendarMode={'single'}
                                minDate={new Date()}
                                value={insurances?.expiryDate ? new Date(insurances?.expiryDate) : new Date()}
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'expiryDate', this, formFields, insurances, formValidations, 'insurances', 'formValidations');
                                }}
                                placeholder="Expiry Date"
                            />
                        </div>
                        {formValidations && !formValidations.fields['expiryDate'].isValid && <p className="p-error">{formValidations.fields['expiryDate'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className="transport-fee-form-label">
                            Upload Insurance Photo<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <FileUpload
                                id={'insurancePhoto'}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onInsurancePhotoSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'images'}
                                title={'Images'}
                                afterFilesUploaded={this._afterFilesUploaded}
                                className="border-solid"
                            >
                                <ClassetInputText
                                    // width={250}
                                    icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                    className="add-vehicle-field-input w-full "
                                    value={insurances.uploadInsurancePhoto?.fileName}
                                    // onChange={(e) => onTextChange(e.target.value, 'studentPhoto', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Upload Insurance Photo"
                                    disabled={true}
                                />
                            </FileUpload>
                            {/*   */}
                        </div>
                    </div>
                </div>

                <div className="flex justify-content-center mb-4 gap-3 mt-8">
                    <Button label="Save" className="confirmDialogAcceptBtn px-6 mr-5 w-19rem" onClick={() => this.onSave()} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" onClick={() => this.props.onCancelMoreInfo()} />
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
            </div>
        );
    }
}
export default connect()(withRouter(Insurance));
