import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '../../lib/withRouter';
import Authentication from './../../session';
import { getBranchesLatest } from '../../../store/actions';
import { CancelRed, CrossInBox, DownloadIcon, GreenCheck, MailIcon, RightMarkInCircle } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import Service from '../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { BasicLazyParams } from '../../../utile';
import { Dialog } from 'primereact/dialog';
import '../styles.scss';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import BranchesApprovalHierarchy from '../../BaseDropdownComponents/BranchesApprovalHierarchy';

class RequestedStop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branchId: null,
            branches: [],
            lazyParams: BasicLazyParams,
            requestedStopInfo: [],
            searchLevel: 1,

        };
        this.service = new Service();
    }
    componentDidMount() {
        this.props.getBranchesLatest(true);
    }
    getRequestedStopInfo = async () => {
        let academicYear = localStorage.getItem('userAcademicYear');
        let lazyParams = this.state.lazyParams;
        const url = `${baseUrlAdmin}/transport-stops-approval/get-pending-approvals?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=`;
        let branchId = this.state.branchId;
        let payload = {
            academicYear,
            branchId,
            assignedHeirarchy: this.state.dropdownsData.heirarchy
        };
        if (typeof (payload.branchId) == "string") {
            this.setState({ isLoading: true, requestedStopInfo: [] });

            try {
                const res = await this.service.post(url, payload, true);
                if (res && res.status && res?.res?.status) {
                    if (res.res?.records?.length) {
                        this.setState({
                            isLoading: false,
                            requestedStopInfo: res.res.records,
                            totalRecords: res.res.totalRecords
                        });
                    } else if (res.res?.records?.length == 0) {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'success', summary: 'No records found.', detail: '', life: 3000 });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            } catch (err) {
                this.setState({ isLoading: false });
                console.log(err);
            }
        }

    };
    onHandleRequestedStopApprove = (rowData, isConfirmed) => {
        this.setState({
            isApproved: isConfirmed ? true : false,
            isRejected: !isConfirmed ? true : false,
            selectedStopInfo: rowData
        });
    };

    onApproveStops = (status) => {
        this.setState({
            isLoading: true
        });
        let payload = {
            ...this.state.selectedStopInfo,
            isApproved: status,
            remarks: this.state.remarks
        };
        payload = {
            ...payload,
            handledBy: this.state.selectedStopInfo?.handledBy || [],
            levelAndBranchDetails: this.state.dropdownsData?.heirarchy?.adminLevels?.filter((al) => al._id == this.state.branchId)
        }
        let url = `${baseUrlAdmin}/transport-stops-approval/approve-stops`;
        this.service
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        isRejected: false,
                        isApproved: false,
                        selectedStopInfo: null,
                        remarks: ''
                    }, () => this.getRequestedStopInfo());
                    this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            });
    };
    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData,
            branchId: dropdownsData.branchIds
        }, () => this.getRequestedStopInfo())
    }

    render() {
        return (
            <div className="ma-main">
                <div className="col-span-1 my-3 mx-5">
                    <BranchesApprovalHierarchy selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} approvalType={'transportRoute'} />

                    {/* <p className="add-vehicle-field-label">Branch</p>
                    <div className="flex justify-content-start  align-items-center">
                        <ClassetDropdown
                            icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                            optionLabel="name"
                            optionValue="key"
                            value={this.state.branchId}
                            options={this.props.branchData}
                            onChange={(e) =>
                                this.setState(
                                    {
                                        branchId: e.target.value
                                    },
                                    () => this.getRequestedStopInfo()
                                )
                            }
                            className="w-full"
                            placeholder={
                                <div className="flex justify-content-start  align-items-center">
                                    <span className="">Select Branch</span>
                                </div>
                            }
                        />
                    </div> */}
                </div>

                <DataTable
                    ref={(el) => (this.dt = el)}
                    value={this.state.requestedStopInfo}
                    dataKey="id"
                    // paginator
                    lazy
                    responsiveLayout="scroll"
                    className="dataTableValuesText"
                    showGridlines
                    selectionMode={'checkbox'}
                    columnResizeMode="expand"
                    resizableColumns
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))}
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={this.state.globalFilter}
                >
                    <Column alignHeader={'center'} sortable bodyClassName="text-center" header="Vehicle Name" field="vehicleName"></Column>
                    <Column alignHeader={'center'} sortable bodyClassName="text-center" header="Vehicle No" field="vehicleRegistrationNo"></Column>
                    <Column filterField="routeName" field="routeName" sortable bodyClassName="text-center" header="Route Name" alignHeader={'center'} />
                    <Column
                        sortable
                        bodyClassName="text-center"
                        header="Area Name"
                        alignHeader={'center'}
                        body={(rowData) => {
                            return (
                                <>
                                    <p>{rowData?.stops[0]?.areaName}</p>
                                </>
                            );
                        }}
                    />
                    <Column
                        sortable
                        bodyClassName="text-center"
                        header="Stop Name"
                        alignHeader={'center'}
                        body={(rowData) => {
                            return (
                                <>
                                    <p>{rowData?.stops[0]?.stopName}</p>
                                </>
                            );
                        }}
                    />
                    <Column
                        alignHeader={'center'}
                        sortable
                        headerClassName="text-color-secondary"
                        bodyClassName="text-center"
                        className=" "
                        header="Approve / Reject"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    {rowData?.isRefundApproved == undefined && (
                                        <div className="flex justify-content-center gap-2">
                                            <div className="cursor-pointer" onClick={() => this.onHandleRequestedStopApprove(rowData, true)}>
                                                <GreenCheck />
                                            </div>

                                            <div className="cursor-pointer" onClick={() => this.onHandleRequestedStopApprove(rowData, false)}>
                                                <CancelRed />
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                        }}
                    ></Column>
                </DataTable>

                <Dialog
                    visible={this.state.isRejected}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <CrossInBox bgcolor={'#BF0031'} color={'white'} />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Reject Stop</p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Stop Name: {this.state?.selectedStopInfo?.stops[0]?.stopName}</p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Route Number : {this.state?.selectedStopInfo?.stops[0]?.routeNo}</p>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#BF0031', border: 'none' }}
                                onClick={() => {
                                    this.onApproveStops(false);
                                }}
                            >
                                <p className="formSubmitButtonText">Reject</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isRejected: false, selectedFeeType: {} })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isRejected: false, selectedFeeType: {} })}
                >
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <div className="">
                            <ClassetTextArea width={'100%'} style={{ height: '119px' }} label={'Remarks:'} placeholder="Remarks" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })} />
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    visible={this.state.isApproved}
                    draggable={false}
                    closeOnEscape={false}
                    style={{ width: 400 }}
                    dismissableMask={false}
                    closable={false}
                    header={() => {
                        return (
                            <div className="mt-2 formHeadingInter">
                                <div className="mb-3">
                                    <RightMarkInCircle bgcolor={'#BF0031'} color={'white'} />
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>Approve Stop </p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Stop Name: {this.state?.selectedStopInfo?.stops[0]?.stopName}</p>
                                <p style={{ fontWeight: 'semiRegular', fontSize: 14 }}>Route Number : {this.state?.selectedStopInfo?.stops[0]?.routeNo}</p>
                            </div>
                        );
                    }}
                    footer={() => (
                        <div className="flex justify-content-center mb-4">
                            <Button
                                className="formSubmitButton px-4"
                                style={{ borderRadius: '10px', backgroundColor: '#7CDD56', border: 'none' }}
                                onClick={() => {
                                    this.onApproveStops(true);
                                }}
                            >
                                <p className="formSubmitButtonText">Approve</p>
                            </Button>
                            <Button className="formCancelButton px-4" style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isApproved: false, selectedFeeType: {} })}>
                                <p className="formCancelButtonText">Cancel</p>
                            </Button>
                        </div>
                    )}
                    onHide={() => this.setState({ isApproved: false, selectedFeeType: {} })}
                >
                    <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <div className="">
                            <ClassetTextArea width={'100%'} style={{ height: '119px' }} label={'Remarks:'} placeholder="Remarks" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })} />
                        </div>
                    </div>
                </Dialog>

                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        [],
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._idF
});
export default connect(mapStateToProps, {
    getBranchesLatest
})(Authentication(withRouter(RequestedStop)));
