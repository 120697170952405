import React, { Component } from 'react'

import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
//import Navbar from './../navbar';
import { Button } from 'primereact/button';
import SvgViewer from '../../customComponents/svgViewer';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
import Authentication from '../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import IndividualUsageAnalytics from '../../analytics/usageAnalytics/individualUsageAnalytics';
import { AnalyticsIcon2, ClassetEditIcon, WarningIcon } from '../../svgIcons';
import ReactApexChart from 'react-apexcharts';
import { I18Next } from '../../../internationalization';
import withRouter from '../../lib/withRouter';
import TeacherDiaryFt from './teacherDiaryFormat';
import './styles.scss';
import { template } from 'lodash';

const items = [
    { label: 'Management' },
    { label: 'User Management' },
    { label: 'Users List', className: 'ma-active-item' }
];




const home = { icon: 'pi pi-home' }


class TeacherDiaryTemplate extends Component {

    constructor(props) {

        super(props);
        this.state = {
            isLoading: false,
            templates: [],
            isShowUserInfo: false,
            isShowCreateForm: false,
            isShowAnalytics: false,
            selectedUser: null,
            isCreateUser: false,
            //filterData: {},
            editUser: null,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            filterData: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            columns: [
                { columnName: 'Created At', isVisible: false, id: 'createDateTime' }],
            showingReports: false,
            series: [
                { name: 'Max marks', data: [44, 55, 57, 56, 61, 58] },
                { name: 'Sub.high score', data: [76, 85, 99, 98, 87, 105] },
                { name: 'Obtained marks', data: [35, 41, 36, 26, 45, 48] }
            ],
            options: {
                xaxis: {
                    categories: ['TELUGU', 'HINDI', 'ENGLISH', 'MATHS', 'SCIENCE', 'SOCIAL'],
                },
                yaxis: {
                    title: { text: 'Marks' }
                },
                chart: {
                    toolbar: {
                        show: false
                    }
                }
            },

        }

        this.service = new Service();
    }


    rightToolbarTemplate = () => {
        const { defaultLanguage } = this.props
        const langType = defaultLanguage
        return (
            <React.Fragment>
                {/* <h4 className='ma-tab-title'>User Management</h4> */}
                <h4 className='ma-tab-title'>{I18Next('VehicleManagement', langType).COMMON_KEY}</h4>
                {/* <p>{this.state.isShowChapters && 'Chapters'}</p> */}
            </React.Fragment>
        )
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <BreadCrumb model={items} home={home} />
            </React.Fragment>
        )
    }


    componentDidMount() {

        this.getTemplatesData()
    }


    showUserInfo = (user) => {
        this.setState({
            selectedUser: user,
            isShowUserInfo: true
        })
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, () => {
            if (e.target.value.length > 2) {
                //this.startGlobalFilter()
            }

        });
    }


    onEditUserClick = (editUser) => {
        this.setState({
            editUser: editUser,
            isShowCreateForm: true
        });
    }



    onCreateOrEditSuccess = () => {
        if (this.state.editUser) {

            this.setState({ isCreateUser: false, editUser: false }, () => {
                this.filterUsersData();
            })


        } else {
            this.setState({ isCreateUser: false, editUser: false })
        }


    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id === c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    editTemplate = (rowData) => {
        return (<>
            {/* <div className="p-d-flex ma-mlr10 user-edit-t"> */}
            <div className="">
                <Button label="Reset Password" className='p-button-outlined' onClick={() => { this.resetPassword(rowData) }} />
            </div>
            {/* </div> */}
        </>
        );
    }


    onCancel = () => {
        this.setState({
            isShowCreateSuccess: false,
            editUser: null,
            isShowCreateForm: false
        })
    }

    getTemplatesData = async () => {
        this.setState({ isLoading: true });
        const academicYear = localStorage.getItem("userAcademicYear");
        let lazyParams = this.state.lazyParams;
        let globalSearch = this.state.globalSearch;
        let url = `${baseUrlAdmin}/teacher-template/get?academicYear=${academicYear}?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}&academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({

                    templates: res.res.data.records.map((r, index) => {
                        r.slNo = index + 1;
                        return r;
                    }),
                    totalRecords: res.res.data.totalRecords,
                    isLoading: false
                });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: res.res.data.message, life: 3000 });
            }
        } catch (e) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: e, life: 3000 });
        }

    };

    onPage = (event) => {

        console.log("CALLPAGE")
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getTemplatesData);


    }




    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getTemplatesData);
    }

    render() {
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    <h4 className=''>Vehicles List</h4>
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <Authorizer permId={PERMISSIONS.USER_SEARCH} >
                            <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.USER_VIEWCOLUMNS} >
                            <i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.USER_DOWNLOADCSV} >
                            <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        const { selectedUser, isShowUserInfo, isShowAnalytics, editUser } = this.state
        return (
            <>

                {this.state.isCreateUser ?
                    <TeacherDiaryFt
                        onCancel={this.onCancel}
                        editData={editUser}
                        onCreateOrEditSuccess={this.onCreateOrEditSuccess}
                        getTemplatesData={this.getTemplatesData}
                    /> :
                    <>


                        <div className="text-right lg:mt-4 md:mt-6 mb-2">

                            {/* <Authorizer permId={PERMISSIONS.USER_ADDNEWUSER} > */}
                            <Button
                                icon={'pi pi-plus-circle text-lg px-1 mt-1'}
                                className='addButtonTeacher'
                                onClick={() => { this.setState({ isShowCreateForm: true }) }}>
                                Add Template
                            </Button>
                            {/* </Authorizer> */}
                        </div>
                        <div className="card datatable-crud-demo ma-m30">
                            <DataTable ref={(el) => this.dt = el}
                                className="dataTableValuesText"
                                columnResizeMode="expand"
                                value={this.state.templates}
                                dataKey="id"
                                paginator
                                lazy
                                responsiveLayout="scroll"
                                // rows={10}
                                onSort={this.onSort}
                                onPage={this.onPage}
                                first={this.state.lazyParams.first}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter}
                            >

                                <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="S.No." className="datatable-cell" body={(rowData, index) => {
                                    return (
                                        <>
                                            <p>{(this.state.lazyParams.page * this.state.lazyParams.rows) + rowData.slNo}</p>
                                        </>
                                    )
                                }} />

                                <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="tctitle" field="tctitle" header="Title" />
                                <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="tcdescription" field="tcdescription" header="Description" />


                                <Column className=' ' alignHeader={"center"} header="Actions" body={(rowData, { rowIndex }) => {
                                    return (<>
                                        <div className="flex justify-content-center align-items-center">
                                            <Tooltip className="table-li-tooltip2" autoHide={false} target={`.awl${rowIndex}`} />
                                            <span data-pr-tooltip="Edit Template" data-pr-position="" className={`ma-ml10 awl${rowIndex}`} onClick={() => { this.onEditUserClick(rowData) }}>
                                                <ClassetEditIcon height={25} width={25} />
                                            </span>
                                            <Tooltip className="table-li-tooltip2" autoHide={false} target={`.uy${rowIndex}`} />
                                        </div>
                                    </>
                                    );
                                }} ></Column>
                            </DataTable>
                        </div>
                    </>
                }


                <Dialog
                    blockScroll={true}
                    visible={this.state.isShowCreateForm}
                    draggable={false}
                    closeOnEscape={false}
                    className='ma-alert-box'
                    style={{
                        width: "90%",
                        maxWidth: "1310px",
                        height: "auto",
                        maxHeight: "90%",
                        margin: "0 auto"
                    }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return (

                            <h2 className='transport-header py-2'>{!this.state?.editUser ? 'Add' : 'Edit'} Template</h2>
                        )
                    }}

                    onHide={() => {
                        this.setState({
                            isShowCreateForm: false,
                            newPassword: null,
                            editUser: null
                        })
                    }}>
                    <div className=''>
                        <TeacherDiaryFt onCancel={this.onCancel} editData={editUser} getTemplatesData={this.getTemplatesData} toastMessage={this.toast} />
                    </div>
                </Dialog>

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {
                    (this.state.isLoading &&
                        <LoadingComponent />)

                }
               
            </>
        )
    }
}

export default connect(null, {

})(Authentication(withRouter(TeacherDiaryTemplate)));