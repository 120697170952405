import React, { Component } from 'react'
import BranchToSectionDD from '../../BaseDropdownComponents/BranchToSectionDD'
import { CancelRed, GreenCheck } from '../../svgIcons';
import { Column } from 'react-formio/lib/types';
import { DataTable } from 'primereact/datatable';
import Service from '../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { BasicLazyParams } from '../../../utile';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import withRouter from '../../lib/withRouter';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import Authentication from "../../../session"
import { getBoardsData } from '../../../store/actions';
import BranchToSectionApprovalHierarchy from '../../BaseDropdownComponents/BranchToSectionApprovalHierarchy';
class ApprovalRequestSubstitution extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lazyParams: BasicLazyParams,
            substitutionRequests: [],
            openCreateFeeDialog: false,
            searchType: 1,
            searchLevel: 1,
            searchInput: ""
        }
        this.service = new Service();
    }

    getSubstitutionRequests = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        const url = `${baseUrlAdmin}/timetable/get-substitution-requests`
        let payload = {
            sectionId: this.state?.dropdownsData?.sectionId,
            academicYear: academicYear,
            assignedHeirarchy: this.state?.dropdownsData?.heirarchy,
        };
        try {
            const res = await this.service.post(url, payload, true);
            if (res && res.status && res.res.status && res.res.data.length > 0) {
                this.setState({ substitutionRequests: res.res.data });
                this.toast.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Successfully Fetched',
                    life: 3000
                });
            } else {
                this.setState({ substitutionRequests: [] });
            }
            console.log(res);
        } catch (err) {
            console.log(err);
            this.toast.show({
                severity: 'error',
                summary: 'Invalid',
                detail: 'Some Error Occured',
                life: 3000
            });
        }
    }
    setDropdownValues = (dropdownsData) => {
        this.setState({ dropdownsData }, () => this.getSubstitutionRequests(dropdownsData))

    }
    onHandleSubstitutionApprove = async (rowData) => {
        let payload = {
            ...rowData,
            handledBy: rowData?.handledBy || [],
            levelAndBranchDetails: this.state.dropdownsData?.heirarchy?.adminLevels?.filter((al) => al._id == rowData?.branchId)
        };
        try {
            const url = `${baseUrlAdmin}/timetable/approve-raised-request`;
            const res = await this.service.post(url, payload, true);
            if (res && res.status && res.res.status) {
                this.getSubstitutionRequests(this.state.dropdownsData);
                this.toast.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Successfully Approved',
                    life: 3000
                });
            } else {
                this.toast.show({
                    severity: 'error',
                    summary: 'Invalid',
                    detail: 'Some Error Occured',
                    life: 3000
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    onHandleSubstitutionReject = async (rowData) => {
        // console.log(rowData);
        let payload = {
            ...rowData,
            handledBy: rowData?.handledBy || [],
            levelAndBranchDetails: this.state.dropdownsData?.heirarchy?.adminLevels?.filter((al) => al._id == rowData?.branchId)
        };
        try {
            const url = `${baseUrlAdmin}/timetable/reject-raised-request/${rowData._id}`;
            const res = await this.service.put(url, payload, true);
            if (res && res.status && res.res.status) {
                this.getSubstitutionRequests(this.state.dropdownsData);
                this.toast.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Successfully Rejected',
                    life: 3000
                });
            } else {
                this.toast.show({
                    severity: 'error',
                    summary: 'Invalid',
                    detail: 'Some Error Occured',
                    life: 3000
                });

            }
        } catch (err) {
            console.log(err);
            this.toast.show({
                severity: 'error',
                summary: 'Invalid',
                detail: 'Some Error Occured',
                life: 3000
            });
        }
    }
    render() {
        return (
            <div>
                <div className='grid m-2 mb-6'>
                    <BranchToSectionApprovalHierarchy selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} approvalType={'substitutionRequest'} />
                </div>
                <DataTable
                    ref={(el) => (this.dt = el)}
                    value={this.state.substitutionRequests}
                    dataKey="id"
                    // paginator
                    lazy
                    responsiveLayout="scroll"
                    // className="dataTableValuesText"
                    // showGridlines
                    selectionMode={'checkbox'}
                    columnResizeMode="expand"
                    resizableColumns
                    // selection={this.state.selectedProducts}
                    // onSelectionChange={(e) => this.setState((prevState)=>({selectedProducts:[...prevState.selectedProducts,e.value]}))}
                    onPage={this.onPage}
                    first={this.state.lazyParams.first}
                    last={this.state.totalRecords}
                    rows={this.state.lazyParams.rows}
                    totalRecords={this.state.totalRecords}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                // globalFilter={this.state.globalFilter}
                >
                    <Column
                        alignHeader={'center'}
                        sortable
                        bodyClassName="text-center"
                        header="S.no"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <div className="flex justify-content-center">
                                    {rowIndex + 1}
                                </div>
                            );
                        }}
                    ></Column>
                    <Column filterField="" body={(rowData) => {
                        return (<div>{rowData.substitutionSubject?.subjectName}</div>)
                    }} sortable bodyClassName="text-center" header="Subject" alignHeader={'center'} />
                    <Column filterField="" body={(rowData) => {
                        return (<div>{rowData?.Teacher ? rowData.Teacher.username : "No Teacher"}</div>)
                    }} sortable bodyClassName="text-center" header="Original Teacher Name" alignHeader={'center'} />
                    <Column filterField="" body={(rowData, { rowIndex }) => {
                        return (
                            <div className="flex justify-content-center">
                                {rowData.substitutionTeacher?.username}
                            </div>
                        );
                    }} sortable bodyClassName="text-center" header="Substitute Teacher Name" alignHeader={'center'} />
                    <Column filterField="" field="Day" sortable bodyClassName="text-center" header="Day" alignHeader={'center'} />

                    <Column
                        filterField=""
                        body={(rowData) => {
                            const substitutionDate = new Date(rowData?.substitutionDate);
                            const formattedDate = substitutionDate.toLocaleDateString(); // Formats the date

                            const startTime = new Date(rowData?.startTime); // Convert startTime to a Date object
                            const formattedTime = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Formats the time (HH:MM)

                            return (
                                <div>
                                    {formattedDate} {formattedTime}
                                </div>
                            );
                        }}

                        sortable
                        bodyClassName="text-center"
                        header="Date"
                        alignHeader="center"
                    />
                    <Column filterField="" field="name" sortable bodyClassName="text-center" header="Period No" alignHeader={'center'} />
                    <Column filterField="" field="reason" sortable bodyClassName="text-center" header="Reason" alignHeader={'center'} />
                    <Column
                        style={{ width: '15rem', height: '6rem' }}
                        alignHeader={'center'}
                        sortable
                        headerClassName="text-color-secondary"
                        bodyClassName="text-center"
                        className=" "
                        header="Approve / Reject"
                        body={(rowData, { rowIndex }) => {
                            return (
                                <>
                                    {rowData?.isRefundApproved == undefined && (
                                        <div className="flex justify-content-evenly">
                                            <div className="cursor-pointer" onClick={() => this.onHandleSubstitutionApprove(rowData, true)}>
                                                <GreenCheck />
                                            </div>

                                            <div className="cursor-pointer" onClick={() => this.onHandleSubstitutionReject(rowData, false)}>
                                                <CancelRed />
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                        }}
                    ></Column>
                </DataTable>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
        )
    }
}
const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, { getBoardsData })(Authentication(withRouter(ApprovalRequestSubstitution)));