import React, { Component } from 'react';
import withRouter from '../../../lib/withRouter';
import { connect } from 'react-redux';
import { getFormFields, isFormValid, onTextChange, isFieldValid, onNumberChange, onEmailChange, onDropDownChange } from '../../../../utile/formHelper';
import ClassetDropdown from '../../../../classetComponents/classetDropDown';
import { FileAttachmentIcon, MailIcon } from '../../../svgIcons';
import ClassetInputText from '../../../../classetComponents/classetInputText';
import ClassetCalendar from '../../../../classetComponents/classetCalender';
import ClassetMultiSelect from '../../../../classetComponents/classetMultiSelect';
import { Button } from 'primereact/button';
import { convertUtc, trimObj } from '../../../../utile';
import { Toast } from 'primereact/toast';
import FileUpload from '../../../fileUpload';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import Service from '../../../services';
import LoadingComponent from '../../../loadingComponent';

const registrationCertificates = require('./registrationCertificate.json');
const formFields = registrationCertificates;

class RegistrationCertificate extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(registrationCertificates);
        this.state = {
            formValidations: this.formFields.formValidations,
            certificates: this.props.vehicleInfo[0]?.certificates ?? this.formFields.data,
            vehicleInfo: this.props.vehicleInfo[0],
            vehicleType: [
                { label: 'Bus', value: 'BUS' },
                { label: 'Car', value: 'CAR' },
                { label: 'Mini Bus', value: 'MINI BUS' },
                { label: 'Mini Van', value: 'MINI VAN' },
                { label: 'Van', value: 'VAN' },
                { label: 'Electric Bus', value: 'ELECTRIC BUS' },
                { label: 'Auto', value: 'AUTO' },
                { label: 'Jeep', value: 'JEEP' }
            ]
        };
        this.service = new Service();
    }
    validateDates = (startDate, expiryDate) => {
        const now = new Date();
        const start = new Date(startDate);
        const expiry = new Date(expiryDate);

        return expiry > now && start < expiry;
    }
    onSave = async () => {

        const { certificates, dob } = this.state;
        const formStatus = isFormValid(registrationCertificates, this.formFields.formValidations, trimObj(certificates));
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            });
            isCompleteFormValid = false;
        }
        if (!certificates?.uploadRCFrontPhoto) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Upload RC fornt photo.', life: 3000 });
            return
        } else if (!certificates?.uploadRCBackPhoto) {
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Upload RC Back photo.', life: 3000 });
            return
        }

        certificates.startDate = certificates?.startDate ?? new Date()
        certificates.expiryDate = certificates?.expiryDate ?? new Date()
        let isVaild = await this.validateDates(convertUtc(certificates?.startDate), convertUtc(certificates?.expiryDate))
        if (!isVaild) {
            this.toast.show({ severity: 'error', summary: "Error", detail: 'Start date should be less than expiry date', life: 3000 });
            isCompleteFormValid = false
            return
        }
        if (isCompleteFormValid) {
            let payload = {
                certificates: this.state.certificates,
                _id: this.state.vehicleInfo?._id
            };
            this.setState({
                isLoading: true
            });
            const url = `${baseUrlAdmin}/transport-vehicle/vehicle-info`
            this.service.put(url, payload, true).then(res => {
                if (res && res.status && res.res.status) {
                    this.setState({ isLoading: false });
                    this.props.onSuccessToast()
                    this.props.onCancelMoreInfo()
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Something went wrong.', detail: 'Something went wrong.', life: 3000 });
            })
        } else {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Please fill all the required fields.', detail: 'Please fill all the required fields.', life: 3000 });
        }
    };

    _onProgress = (filesInfo) => { };
    _afterFilesUploaded = (files) => { };
    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    };
    addAttachment = (fieldName, fileName, data) => {
        this.setState((prevState) => ({
            certificates: {
                ...prevState.certificates,
                [fieldName]: {
                    fileName,
                    storageBucket: data.Bucket,
                    storageKey: data.Key,
                    storageLocation: data.Location
                }
            }
        }));
    };

    _onRCBackPhotoSuccess = (fileName, data) => {
        this.addAttachment('uploadRCBackPhoto', fileName, data);
    };

    _onRCFrontPhotoSuccess = (fileName, data) => {
        this.addAttachment('uploadRCFrontPhoto', fileName, data);
    };

    render() {
        const { certificates, formValidations, vehicleInfo } = this.state;
        return (
            <div>
                <div className="grid mt-5 mx-5 md:col-11 lg:col-11 column-gap-8  row-gap-6">
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            Registration Number<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText disabled={true} className=" border-round-md  w-full " value={vehicleInfo?.vehicleRegistrationNo} placeholder="Registered Vehicle Number" />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label  w-auto">
                            Vehicle Category<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetDropdown
                                optionLabel="label"
                                optionValue="value"
                                className=" w-full"
                                disabled={true}
                                value={vehicleInfo?.vehicleType}
                                options={this.state.vehicleType}
                                placeholder={
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className="">Select Vehicle Type</span>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            Vehicle Name<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                disabled={true}
                                // id="title"
                                className=" w-full "
                                value={vehicleInfo?.vehicleName}
                                placeholder="Vehicle Name"
                            />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            RC Book Number<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={'black'} />}
                                className=" border-round-md  w-full "
                                value={certificates.rcBookNumber}
                                onChange={(e) => onTextChange(e.target.value, 'rcBookNumber', this, formFields, certificates, formValidations, 'certificates', 'formValidations')}
                                placeholder="RC Book Number"
                            />
                        </div>
                        {formValidations && !formValidations.fields['rcBookNumber'].isValid && <p className="p-error">{formValidations.fields['rcBookNumber'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            Start Date<span className="ma-required">*</span>
                        </p>

                        <div className="flex">
                            <ClassetCalendar
                                icon={<MailIcon width={24} height={24} color={'black'} />}
                                className=" border-round-md add-vehicle-field-input text-2xl w-full"
                                calendarMode={'single'}
                                value={certificates?.startDate ? new Date(certificates?.startDate) : new Date()}
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'startDate', this, formFields, certificates, formValidations, 'certificates', 'formValidations');
                                }}
                                maxDate={new Date()}
                                placeholder="Start Date"
                            />
                        </div>
                        {formValidations && !formValidations.fields['startDate'].isValid && <p className="p-error">{formValidations.fields['startDate'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className="add-vehicle-field-label">
                            Expiry Date<span className="ma-required">*</span>
                        </p>

                        <div className="flex">
                            <ClassetCalendar
                                icon={<MailIcon width={24} height={24} color={'black'} />}
                                className=" border-round-md add-vehicle-field-input text-2xl w-full"
                                calendarMode={'single'}
                                value={certificates?.expiryDate ? new Date(certificates?.expiryDate) : new Date()}
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'expiryDate', this, formFields, certificates, formValidations, 'certificates', 'formValidations');
                                }}
                                minDate={new Date()}
                                placeholder="Expiry Date"
                            />
                        </div>
                        {formValidations && !formValidations.fields['expiryDate'].isValid && <p className="p-error">{formValidations.fields['expiryDate'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className="transport-fee-form-label">
                            Upload RC Front Photo<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <FileUpload
                                id={'rcFrontPhoto'}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onRCFrontPhotoSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'images'}
                                title={'Images'}
                                afterFilesUploaded={this._afterFilesUploaded}
                                className="border-solid"
                            >
                                <ClassetInputText
                                    // width={250}
                                    icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                    className="add-vehicle-field-input w-full "
                                    value={certificates.uploadRCFrontPhoto?.fileName}
                                    // onChange={(e) => onTextChange(e.target.value, 'studentPhoto', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Upload Front Photo"
                                    disabled={true}
                                />
                            </FileUpload>
                            {/*   */}
                        </div>
                    </div>
                    <div className="col-span-1">
                        <p className="transport-fee-form-label">
                            Upload RC Back Photo<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <FileUpload
                                id={'rcBackPhoto'}
                                multiple={false}
                                onProgress={this._onProgress}
                                onSucess={this._onRCBackPhotoSuccess}
                                //  Reset={this.state.Reset}
                                maxFileSize={307200}
                                onError={this._onFilsUploadError}
                                accept={'images'}
                                title={'Images'}
                                afterFilesUploaded={this._afterFilesUploaded}
                                className="border-solid"
                            >
                                <ClassetInputText
                                    // width={250}
                                    icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                    className="add-vehicle-field-input w-full "
                                    value={certificates.uploadRCBackPhoto?.fileName}
                                    // onChange={(e) => onTextChange(e.target.value, 'studentPhoto', this, formFields, application, formValidations, 'application', 'formValidations')}
                                    placeholder="Upload Back Photo"
                                    disabled={true}
                                />
                            </FileUpload>
                            {/*   */}
                        </div>
                    </div>
                </div>

                <div className="flex justify-content-center mb-4 gap-3 mt-8">
                    <Button label="Save" className="confirmDialogAcceptBtn px-6 mr-5 w-19rem" onClick={() => this.onSave()} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" onClick={() => this.props.onCancelMoreInfo()} />
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
            </div>
        );
    }
}
export default connect()(withRouter(RegistrationCertificate));
