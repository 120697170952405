import React from 'react';
import './login.scss';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { userSignIn, clearSignInData, getUserData } from '../../store/actions';
import { Link } from 'react-router-dom';
import LoadingComponent from '../loadingComponent';
import Authentication from '../../session';
import withRouter from '../lib/withRouter';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import HandLoader from '../loadingComponent/HandLoader';
import { LoginImage } from '../svgIcons';
import { Checkbox } from 'primereact/checkbox';
import ClassetInputText from '../../classetComponents/classetInputText';
import { ProgressSpinner } from 'primereact/progressspinner';
const INITIAL_STATE = {
    number: '',
    password: 'Classet@123',
    error: null,
    passwordErr: null
};

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
            isRemember: false,
        };
    }

    isVaildNumber = (mobile) => {
        if (/^[0-9\b]+$/.test(mobile)) {
            return true;
        } else {
            return false;
        }
    };

    componentDidMount() {
        if (this.props.authenticated) {
            this.props.navigate('/');
        }
        if (sessionStorage.getItem('username')) {
            this.setState({ number: sessionStorage.getItem('username') })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authenticated !== this.props.authenticated) {
            if (this.props.authenticated) {
                this.props.getUserData();
                setTimeout(() => {
                    this.props.navigate('/');
                }, 10);
            }
        }
    }
    handleRememberMeChange = (e) => {
        this.setState({ isRemember: e.checked });
    };
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.userSignIn();
        }
    };
    userSignIn = () => {
        // const number = "+919052565771"  //'shashi2puppy'// `+91${this.state.number}`;
        // const pw ="Lodu@9052565771"       /// 'Dinesh@123'  // this.state.password;
        // const number = `+91${this.state.number}`;
        // console.log(this.state.number === '');
        // return
        if (!this.state.number) {
            return this.toast.show({
                severity: 'error',
                summary: 'Invalid Username',
                detail: 'Please Fill Username',
                life: 3000
            });
        }
        if (!this.state.password) {
            return this.toast.show({
                severity: 'error',
                summary: 'Invalid Password',
                detail: 'Please Fill Password',
                life: 3000
            });
        }
        const pw = this.state.password;
        if (this.state.isRemember) {
            sessionStorage.setItem('username', this.state.number);
        } else {
            sessionStorage.removeItem('username');
        }

        this.props.userSignIn((this.props.orgCode ? this.props.orgCode : '') + this.state.number, pw).then(() => {
            // this.toast.show({
            //     severity: 'success',
            //     summary: 'Login Successful',
            //     detail: 'Welcome back!',
            //     life: 3000,
            // });
        })
            .catch((err) => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Login Failed',
                    detail: err.message || 'Invalid username or password.',
                    life: 3000,
                });
            });
    };

    render() {
        const { userSignIn, number, password, numberErr, passwordErr } = this.state;
        // console.log(this.props.orgCode)
        const showBtn = number === '' || password === '';
        //|| !this.isVaildNumber(number)
        const { logoRectangle, isSuperOrg, orgName } = this.props;
        return (
            <div style={{ backgroundColor: 'f5f5f5', display: 'flex', justifyContent: 'space-around', alignItems: 'center', minHeight: '750px' }}>
                <LoginImage width={500} height={600} />
                <div>
                    {(logoRectangle?.storageLocation || isSuperOrg) ? (
                        <div className='flex flex-column align-items-center'>
                            <img src={`${logoRectangle?.storageLocation ? logoRectangle?.storageLocation : ''}`} alt="" className="img-fluid" />
                            <h3 className="mt-3">
                                <b>{orgName}</b>
                            </h3>
                        </div>
                    ) : (
                        <div className="flex">
                            <h4 style={{ marginRight: '10px' }}>Fetching Organisation Details...</h4>
                            <ProgressSpinner style={{ width: '34px', height: '34px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                    )}
                    <div className="login-wrapper">

                        <div className="flex flex-column align-items-center">
                            {isSuperOrg && <h3>Superadmin</h3>}
                            <div className="">
                                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                                    <h2 className="text-center" style={{ fontWeight: 'bolder', color: 'black' }}>Sign in</h2>
                                    <p style={{ fontWeight: '700' }}>Please Sign in with your account</p>
                                </div>

                                <div className="input-wrapper">
                                    <div style={{ fontWeight: 'bold', fontSize: '15px' }}>Username</div>
                                    <ClassetInputText onKeyDown={this.handleKeyDown} className='p-inputtext-lg' onChange={(e) => this.setState({ number: e.target.value })} value={number} ref="number" type="text" placeholder="Username" />
                                    <div className="ma-danger">{numberErr ? 'Please enter mobile number' : ''}</div>
                                </div>
                                <div className="input-wrapper">
                                    <div style={{ fontWeight: 'bold', fontSize: '15px' }}>Password</div>
                                    <ClassetInputText
                                        onKeyDown={this.handleKeyDown}
                                        className='p-inputtext-lg'
                                        feedback={false}
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                        onBlur={() => (password === '' ? this.setState({ passwordErr: 'please enter the password' }) : this.setState({ passwordErr: '' }))}
                                        ref="password"
                                        value={password}
                                        isPassword={true}
                                        placeholder="Password"
                                    />
                                    <div className="p-error">{passwordErr ? 'Please enter password' : ''}</div>
                                </div>
                                <div className="text-center text-warning">{this.props?.errMsg?.message}</div>
                                <div className='flex align-items-center gap-2' style={{ marginBottom: '5px' }}><Checkbox inputId='remember' checked={this.state.isRemember} onChange={this.handleRememberMeChange} /><label htmlFor='remember' style={{ fontWeight: 'bold', cursor: 'pointer' }}>Remember Me</label></div>
                                <div className="button-wrapper">
                                    <Button disabled={(logoRectangle?.storageLocation || isSuperOrg) ? false : true} label="Sign In" type="button" onClick={() => this.userSignIn()} style={{ fontWeight: '500', backgroundColor: '#076EFF', padding: "10px 35px", fontSize: '15px', borderRadius: '9px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    user: state.login && state.login,
    authenticated: state.auth && state.auth.authenticated,
    loading: state.signInData && state.signInData.loading && state.signInData.loading,
    error: state.signInData && state.signInData.error && state.signInData.error,
    errMsg: state.signInData && state.signInData.errMsg && state.signInData.errMsg.message,
    logoRectangle: state.orgData.logoRectangle,
    orgName: state.orgData.name,
    orgCode: state.orgData.orgCode,
    isLoading: state.orgData.isLoading,
    isSuperOrg: state.orgData.isSuper
});

export default connect(mapStateToProps, { userSignIn, clearSignInData, getUserData })(Authentication(withRouter(LoginPage)));
