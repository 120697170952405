import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Menu } from 'primereact/menu';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
import Authentication from '../../session';
import { BasicLazyParams } from '../../../utile';
import withRouter from '../../lib/withRouter';
import moment from 'moment';
import { SplitButton } from 'primereact/splitbutton';
import { Dialog } from 'primereact/dialog';

class AdmissionEnrollment extends Component {

    constructor(props) {

        super(props);
        this.handleReasonSelect = this.handleReasonSelect.bind(this);
        this.state = {
            isLoading: false,
            filterData: null,
            totalRecords: 0,
            lazyParams: BasicLazyParams,
            applicationInfo: [],
            isgetRegistration: true,
            isActions: false,
            selectedRegistrationForm: {},
            viewEnrollment: false,
            showConfirmationDialog: false,
            selectedReason: ''
        }
        this.items = [
            {
                label: 'School is too Far',
                command: () => this.handleReasonSelect('School is too Far')
            },
            {
                label: 'They found another school',
                command: () => this.handleReasonSelect('They found another school')
            },
            {
                label: "Fee's are high",
                command: () => this.handleReasonSelect("Fee's are high")
            },
            {
                label: 'Rude behaviour of staff',
                command: () => this.handleReasonSelect('Rude behaviour of staff')
            }
        ];
        this.service = new Service();
    }

    componentDidMount() {

        this.getAdmissionInfo()
    }


    getAdmissionInfo = () => {
        this.setState({
            isLoading: true
        })
        const url = `${baseUrlAdmin}/admissions/all?stage=${"Enrollment"}`;
        let academicYear = localStorage.getItem('userAcademicYear')
        let payload = {}
        payload.academicYear = academicYear

        this.service.post(url, payload, true).then(res => {
            if (res && res.status && res.res.status) {
                if (res.res.data && res.res.data.admissionRecords.length) {

                    this.setState({
                        isLoading: false,
                        applicationInfo: res.res.data.admissionRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }
    handleReasonSelect(reason) {
        this.confirmDropOffReason(reason);
    }

    confirmDropOffReason = (reason) => {
        this.setState({
            showConfirmationDialog: true,
            selectedReason: reason
        });
    };

    handleDropOff(reason) {
        let payload = {
            _id: this.state.selectedRegistrationForm?._id,
            stage: "Dropoff",
            dropoffReason: reason
        };
        const url = `${baseUrlAdmin}/admissions/dropOff`;
        this.setState({ isLoading: true });

        this.service.put(url, payload, true).then(res => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    isgetEnquiry: true,
                    isEdit: false,
                    isActions: false,
                    isAplication: false,
                    selectedRegistrationForm: {},
                    applicationInfo: []
                }, () => this.getAdmissionInfo());
                this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
            } else {
                this.setState({
                    isLoading: false,
                    isgetEnquiry: true,
                    isEdit: false,
                    isActions: false,
                    isAplication: false,
                    selectedRegistrationForm: {}
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false,
                isgetEnquiry: true,
                isEdit: false,
                isActions: false,
                isAplication: false,
                selectedRegistrationForm: {}
            });
            this.toast.show({ severity: 'error', summary: "Error", detail: 'Some error occurred', life: 3000 });
        })
    }

    onView = (rowData) => {
        localStorage.setItem('applicationId', rowData?._id)
        this.setState({
            isgetRegistration: false,
            isActions: false,
        }, () => this.props.navigate('/confirm-enrollment'))
    }

    onHandleClose = () => {
        this.setState({
            isgetRegistration: true,
            isActions: false,
        }, () => this.getAdmissionInfo())
    }

    render() {
        return (
            <>
                <>
                    {this.state.isgetRegistration && <>
                        <div className="card datatable-crud-demo ma-m30">
                            <DataTable ref={(el) => this.dt = el}
                                className="dataTableValuesText"
                                columnResizeMode="expand"
                                value={this.state.applicationInfo}
                                dataKey="id"
                                paginator
                                scrollable
                                responsiveLayout="scroll"
                                rows={10}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter}
                            >

                                <Column alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="S.No." className="datatable-cell w-1rem" body={(rowData, index) => {
                                    return (
                                        <>
                                            <p>{(this.state.lazyParams.page * this.state.lazyParams.rows) + index.rowIndex + 1}</p>
                                        </>
                                    )
                                }} />

                                <Column alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="firstName" field="firstName" header="First Name" />
                                <Column alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="lastName" field="lastName" header="Last Name" />
                                <Column alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="contactNo" field="contactNo" header="Phone Number" />
                                <Column alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="parentName" field="parentName" header="Parent Name" />
                                <Column alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData) => {
                                    return (
                                        <div className="">
                                            <p>{rowData?.applicationId}</p>
                                        </div>
                                    )
                                }} header="Application ID" />
                                <Column alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData) => {
                                    return (
                                        <div className="" style={{ width: "8rem", }}>
                                            <p>{moment(rowData?.exam?.date).format("DD-MM-YYYY")}</p>
                                        </div>
                                    )
                                }} header="Applied Date" />
                                <Column alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData) => {
                                    return (
                                        <div className="" style={{ width: "8rem", }}>
                                            <p>{rowData?.exam?.examType}</p>
                                        </div>
                                    )
                                }} header="Exam Type" />
                                <Column alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="stage" field="stage" className='' header="Current Stage" />
                                <Column alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="branch" className='' field="branchName" header="Branch" />
                                <Column alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="board" className='' field="boardName" header="Board" />
                                <Column alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' filterField="grade" className='' field="gradeName" header="Grade" />
                                <Column alignHeader={"center"} sortable headerClassName="text-color-secondary" bodyClassName='text-center' header="Actions" body={(rowData, { rowIndex }) => {
                                    return (
                                        <>
                                            <Button id={rowData?._id} onClick={(event) => this[rowData?._id].toggle(event)} icon="pi pi-ellipsis-v" className=' p-button-text p-button-secondary ma-ml10' />
                                            <Menu model={[
                                                {
                                                    label: 'View Enrollment',
                                                    command: () => { this.onView(rowData) }
                                                },


                                            ]} style={{ width: "150px" }} className="h-7rem card shadow-4 flex flex-column justify-content-center align-items-center" popup={true} ref={el => this[rowData?._id] = el} />
                                        </>
                                    )
                                }} ></Column>
                                <Column
                                    alignHeader={'center'}
                                    sortable
                                    headerClassName="text-color-secondary"
                                    bodyClassName="text-center"
                                    header="Drop Off"
                                    body={(rowData) => {
                                        return (
                                            <div onClick={() => {
                                                if (rowData) {
                                                    this.setState({ selectedRegistrationForm: rowData });
                                                }
                                            }}>
                                                <SplitButton label="Drop Off" model={this.items} className="p-button-outlined p-button-info mr-2 mb-2"></SplitButton>

                                            </div>

                                        );
                                    }}

                                ></Column>
                            </DataTable>
                        </div> </>
                    }
                    {/* {this.state.viewEnrollment && <ConfirmEnrollment editRegistrationForm={this.state.selectedRegistrationForm} onHandleClose={this.onHandleClose} />} */}
                </>
                <Dialog
                    visible={this.state.showConfirmationDialog}
                    style={{ width: '450px' }}
                    header="Confirm Drop Off"
                    modal
                    footer={
                        <div>
                            <Button label="No" icon="pi pi-times" onClick={() => this.setState({ showConfirmationDialog: false })} />
                            <Button label="Yes" icon="pi pi-check" onClick={() => { this.handleDropOff(this.state.selectedReason); this.setState({ showConfirmationDialog: false }); }} autoFocus />
                        </div>
                    }
                    onHide={() => this.setState({ showConfirmationDialog: false })}>
                    <p>Are you sure you want to drop off for the reason: <strong>{this.state.selectedReason}</strong>?</p>
                </Dialog>
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }

                <Toast ref={(el) => this.toast = el} position="bottom-right" />

            </>
        )
    }
}
const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {}
});
export default connect(mapStatesToProps, {

})(Authentication(withRouter(AdmissionEnrollment)));