import { Component } from "react";
import { connect } from "react-redux";
import { userAssignedBoards } from "../../../store/selectors/userAssignedBoards";
import withRouter from "../../lib/withRouter";
import { getBoardsData, getBranchesLatest } from "../../../store/actions";
import Service from '../../services';
import Authentication from '../../session';
import { Button } from "primereact/button";
import ClassetDropdown from "../../../classetComponents/classetDropDown";
import { AddIcon2, FileAttachmentIcon, MailIcon } from "../../svgIcons";
import { getFormFields, isFormValid, onDropDownChange, onTextChange } from "../../../utile/formHelper";
import ClassetInputText from "../../../classetComponents/classetInputText";
import ClassetCalendar from "../../../classetComponents/classetCalender";
import ClassetTextArea from "../../../classetComponents/classetTextArea";
import FileUpload from "../../fileUpload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import _, { cloneDeep } from 'lodash';
import { InputSwitch } from "primereact/inputswitch";
import { baseUrlAdmin } from '../../../store/apiConstants';
import { trimObj } from "../../../utile";
import LoadingComponent from "../../loadingComponent";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { paymentModes } from '../../../constants';
import FeeReciept from '../receipt'

const createFields = require('./collectFee.json');
const createRegistrationFields = require('./registrationCreate.json');
const formFields = createRegistrationFields
class CreateRegistration extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createRegistrationFields);
        this.payformFields = getFormFields(createFields);
        this.state = {
            isLoading: false,
            registration: this.props.editRegistrationForm?._id ? this.props.editRegistrationForm : this.formFields.data,
            formValidations: this.formFields.formValidations,
            payFeeFormData: this.payformFields.data,
            payFee: this.payformFields.data,
            payFormValidations: this.payformFields.formValidations,
            requestedStop: "",
            qualificationData: [
                { label: "Matriculate", value: "matriculate" },
                { label: "Graduate", value: "graduate" },
                { label: "Post Graduate", value: "postGraduate" },
                { label: "Doctorate", value: "doctorate" },
                { label: "Others", value: "others" },
            ],
            relationData: [
                { label: "Father", value: "Father" },
                { label: "Mother", value: "Mother" },
                { label: "Guardian", value: "Guardian" },

            ],
            religionData: [
                { label: "Hindu", value: "hindu" },
                { label: "Muslim", value: "muslim" },
                { label: "Christian", value: "christian" },
                { label: "Others", value: "others" }
            ],
            casteData: [
                { label: "General", value: "general" },
                { label: "BC-A", value: "bc-a" },
                { label: "BC-B", value: "bc-b" },
                { label: "BC-C", value: "bc-c" },
                { label: "BC-D", value: "bc-d" },
                { label: "BC-E", value: "bc-e" },
                { label: "SC", value: "sc" },
                { label: "ST", value: "st" }
            ],
            motherTongueData: [
                {
                    label: "Hindi",
                    value: "hi"
                },
                {
                    label: "Bengali",
                    value: "bn"
                },
                {
                    label: "Tamil",
                    value: "ta"
                },
                {
                    label: "Telugu",
                    value: "te"
                },
                {
                    label: "Marathi",
                    value: "mr"
                },
                {
                    label: "Gujarati",
                    value: "gu"
                },
                {
                    label: "Kannada",
                    value: "kn"
                },
                {
                    label: "Malayalam",
                    value: "ml"
                },
                {
                    label: "Punjabi",
                    value: "pa"
                },
                {
                    label: "English",
                    value: "en"
                }
            ],
            genderData: [
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
            ],
            siblings: [
                { name: "", gender: "", classStudying: "", schoolStudying: "" }
            ],
            prevAcademicDetails: [
                { name: "", classStudied: "", schoolStudied: "", percentageOrGpa: "" }
            ],
            classStudyingDrp: [
                { label: "I", value: "i" }, { label: "II", value: "ii" }, { label: "III", value: "iii" }, { label: "IV", value: "iv" },
                { label: "V", value: "v" }, { label: "VI", value: "vi" }, { label: "VII", value: "vii" }, { label: "VIII", value: "viii" },
                { label: "IX", value: "ix" }, { label: "X", value: "x" }, { label: "XI", value: "xi" }, { label: "XII", value: "xii" },
            ],
            parentDetails: [
                { name: "", relation: "", occupation: "", qualification: "", mobilenumber: "", mailid: "" }
            ],
            classes: [],
            boards: [],
            isEdit: false,
            isShowReceipt: false,
            receiptInfo: {},
            isRegistrationFeePaid: false,
            isShowPayFeeButton: false,
            selectedFeeStructure: ""
        }
        this.service = new Service();
    }

    async componentDidMount() {


        const applicationId = await localStorage.getItem('applicationId')
        this.props.getBranchesLatest()
        this.props.getBoardsData(true);
        await this.getAdmissionTypeDropdown()
        await this.getAcademicSessions()
        if (applicationId !== undefined && applicationId) {
            await this.getAdmissionInfo(applicationId)
        }
        if (this.props.editRegistrationForm?._id) {
            this.onChangeBranch(this.props?.editRegistrationForm?.branch)
            this.onChangeBoard(this.props?.editRegistrationForm?.board)
            this.props?.editRegistrationForm?.registration?.requestedStop && await this.getFeeStructures(this.props?.editRegistrationForm?.registration?.requestedStop)
            this.setState((prevState) => ({
                registration: { ...prevState.registration, ...prevState.registration?.registration },
                parentDetails: this.props?.editRegistrationForm?.registration?.parentDetails,
                siblings: this.props?.editRegistrationForm?.registration?.siblings,
                prevAcademicDetails: this.props?.editRegistrationForm?.registration?.prevAcademicDetails,
                requestedStop: this.props?.editRegistrationForm?.registration?.requestedStop || "",
                isEdit: true,
                selectedFeeStructure: this.props?.editRegistrationForm?.registration?.feeStructure || ""
            }), async () => {
                await this.handlePayFee(false)
                this.state?.registration?.registration?.transport && await this.getTransportInfo()
            })
        }

    }
    handlePayFee = async () => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/application-fee/filter`;
        try {
            let payload = {
                "isActive": true,
                "academicYear": this.state.registration?.academicSession,
                "orgId": this.state.registration?.orgId,
                "board": this.state.registration?.board,
                "grade": this.state.registration?.grade,
                "admissionStep": "Registration"
            }
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({ feePayInfo: res?.res?.data, isShowPayFeeButton: (res?.res?.data && Object.keys(res?.res?.data).length) ? true : false, isLoading: false }, () => this.onCheckFeePaidInfo(false));
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occurred', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    };

    getAdmissionTypeDropdown = async () => {
        this.setState({
            isLoading: true
        });
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const result = res?.res?.data?.map(admission => ({
                    label: admission.categoryName,
                    value: admission._id
                }));

                this.setState({ academicCategory: result, isLoading: false });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occurred', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }

    };

    _onProgress = (filesInfo) => {

    }
    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    addAttachment = (fieldName, fileName, data) => {
        this.setState((prevState) => (
            {
                registration: {
                    ...prevState.registration, [fieldName]: {
                        fileName,
                        storageBucket: data.Bucket,
                        storageKey: data.Key,
                        storageLocation: data.Location,
                    }
                }
            }
        ));
    }
    _onStudentPhotoSuccess = (fileName, data) => {
        this.addAttachment('studentPhoto', fileName, data);
    }

    _afterFilesUploaded = (files) => {

    }
    getAcademicSessions = async () => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/academic-years/filter`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const result = res?.res?.data?.map(admission => ({
                    label: admission.academicCode,
                    value: admission._id
                }));
                this.setState({ academicSession: result, isLoading: false });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occurred', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }

    };

    onChangeBranch = (branchId) => {

        this.setState({
            boards: [], classes: [],
        });

        let selectedbranchName = this.props.branchData?.find((branchd) => branchd.key == branchId)?.name;
        let filteredBoards = this.props.boards.filter(board => board.assignedBranches.includes(branchId));
        this.setState({
            branchId: branchId,
            selectedbranchName: selectedbranchName,
            boards: filteredBoards,
            classes: [],
            boardId: '',
            selectedBoardName: '',
            classId: '',
            selectedClass: {},
            branches: [],
            selectedClassName: ''
        });
    };

    onChangeBoard = (boardId) => {

        this.setState({
            classes: [], sections: [],
        });
        const selectedBoard = this.state.boards && this.props.boards.find((board) => board.boardId === boardId);

        this.setState({
            classes: selectedBoard?.classes,
            boardId,
            selectedBoardName: selectedBoard?.boardName,
            classId: '',
            selectedClass: {},
            branches: [],
            selectedClassName: '',
        });
    };

    getAdmissionInfo = async (applicationId) => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/admissions/form?id=${applicationId}`;
        this.service.get(url, true).then(res => {
            if (res && res.status && res.res.status) {
                if (res.res.data && res.res.data.admissionRecords.length) {
                    this.setState({
                        isLoading: false,
                        registration: res.res.data.admissionRecords[0],
                    }, async () => {
                        this.onChangeBranch(this.state?.registration?.branch)
                        this.onChangeBoard(this.state?.registration?.board)
                        await this.handlePayFee(false)
                        //  await this.getFeeStructures(false)
                        localStorage.removeItem('applicationId')
                    });
                }
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
            console.log(e);
        })
    }
    onChangeInput = (value, index, field, arr) => {
        if (arr === "siblings") {
            let data = cloneDeep(this.state.siblings)
            data[index][field] = value;
            this.setState({
                siblings: data
            })
        } else if (arr === "prevAcademicDetails") {
            let data = cloneDeep(this.state.prevAcademicDetails)
            data[index][field] = value;
            this.setState({
                prevAcademicDetails: data
            })
        }

    }
    onCliclAddPrevAcadamicDet = (bool, index) => {
        if (bool) {
            let arrLength = this.state.prevAcademicDetails.length
            let lastObj = this.state.prevAcademicDetails[arrLength - 1]
            if (lastObj.name !== "") {
                this.setState((prevState) => {
                    return { prevAcademicDetails: [...prevState.prevAcademicDetails, { name: "", classStudied: "", schoolStudied: "", percentageOrGpa: "" }] }
                })
            } else {
                this.toast.show({ severity: 'error', summary: 'Please enter the Previous academic details', detail: '', life: 3000 });
            }
        }
        else if (bool === false) {
            const { prevAcademicDetails } = this.state
            const newArr = [...prevAcademicDetails]
            newArr.splice(index, 1)
            this.setState({ prevAcademicDetails: newArr })
        }
    }

    onHandleCancelPayFee = () => {
        this.setState({
            isOpenPayFeeDailog: false,
            payFeeFormData: {},
            transctionId: null
        })
    }

    handleShowReceipt = (isContinue) => {
        const { receiptInfo } = this.state
        if (!isContinue) {
            this.setState({
                isRegistrationFeePaid: receiptInfo?.isRegistrationFeePaid || false
            })
        }
        else {
            let receiptData = {
                receiptName: this.state.feePayInfo?.label,
                receiptNo: receiptInfo?.registration?.receiptNo,
                receiptDate: receiptInfo?.registration?.date,
                studentName: receiptInfo?.studentName,
                academicYear: receiptInfo?.academicYearCode,
                className: receiptInfo?.registration?.gradeName,
                paymentMode: receiptInfo?.registration?.paymentMode,
                feePaidDetails: [
                    {
                        feeType: receiptInfo?.registration?.FeeTypeName,
                        amountPaying: receiptInfo?.registration?.feeAmount,
                    },
                ],
                amountPaid: receiptInfo?.registration?.feeAmount


            }
            this.setState({
                isShowReceipt: true,
                receiptInfo: receiptData,
                isRegistrationFeePaid: receiptInfo?.isRegistrationFeePaid || false
            })
        }
        if (!this.state.isShowPayFeeButton) {
            this.setState({
                isRegistrationFeePaid: true
            })
        }
    }

    onCheckFeePaidInfo = async (isContinue) => {
        const url = `${baseUrlAdmin}/admissions/feePaidInfo?_id=${this.state.registration?._id}`
        this.service.get(url, true).then(res => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    receiptInfo: res?.res?.data,
                }, () => this.handleShowReceipt(isContinue));
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })

    }
    handleParentDetailsChange = (value, field, index) => {
        const updatedParentDetails = this.state.parentDetails.map((parentDetail, idx) => {
            if (idx === index) {
                return { ...parentDetail, [field]: value };
            }
            return parentDetail;
        });

        this.setState({
            parentDetails: updatedParentDetails
        });
    };

    onClickParentDetailsPlusOrMinus = (bool, index) => {
        if (bool) {
            const { parentDetails } = this.state;
            const last = parentDetails[parentDetails.length - 1];
            if (!last || !last.relation || !last.name || !last.occupation || !last.qualification || !last.mobilenumber || !last.mailid) {
                return this.toast.show({
                    severity: 'error',
                    summary: 'Fill all the fields before adding new details',
                    life: 3000
                });
            }
            if (last.mobilenumber.length !== 10) return this.toast.show({
                severity: 'error',
                summary: 'Invalid Mobile Number',
                life: 3000
            });
            this.setState((prevState) => ({
                parentDetails: [
                    ...prevState.parentDetails,
                    { name: "", relation: "", occupation: "", qualification: "", mobilenumber: "", mailid: "" }
                ]
            }));
        }
        else if (bool === false) {
            const { parentDetails } = this.state
            const newArr = [...parentDetails]
            newArr.splice(index, 1)
            this.setState({ parentDetails: newArr })
        }
    }
    OnPayFee = () => {
        const { payFeeFormData } = this.state
        const formStatus = isFormValid(createFields, this.payformFields.formValidations, trimObj(payFeeFormData));
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                payFormValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        let data = payFeeFormData
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/admissions/payFee`
        let paymentMode = this.state.paymentModes.find(el => el.value == data?.paymentType)?.label
        data.paymentMode = paymentMode
        data.FeeTypeName = this.state.feePayInfo?.feeName
        data.feeAmount = Number(this.state.feePayInfo?.amount)
        data.transctionId = this.state?.transctionId

        if (data?.paymentType === '1b1a76b4-c538-4968-a20b-0e5a3b4a1c54' || data?.paymentType === 'b9e46415-1b11-4377-9105-58b98c230935' || data?.paymentType === '274a4416-2454-4f3a-b741-89c67361ae1f' || data?.paymentType === '32b180cf-7727-46ef-b2a8-e7b1b19fd489') {
            if (!data?.transctionId) {
                this.setState({
                    isLoading: false,
                });
                this.toast.show({ severity: 'error', summary: 'Please enter transaction id.', detail: "Please enter transaction id.", life: 3000 });
                return
            }
        } else {
            data.transctionId = null
        }
        let payload = { ...data }
        if (this.state?.registration?._id) {
            payload.academicYearCode = this.state.registration?.enquiry?.academicYearCode
            payload.admissionCategoryName = this.state.registration?.enquiry?.admissionCategoryName
            payload.studentName = `${this.state?.registration?.firstName} ${this.state?.registration?.lastName}`
            payload.applicationNo = this.state?.registration?.applicationId
            payload.admissionId = this.state?.registration?._id
            payload.academicYear = this.state?.registration?.academicSession
            payload.gradeName = this.state.registration?.gradeName
            payload.stage = "Registration"
        }
        if (isCompleteFormValid) {
            this.service.post(url, payload, true).then(res => {
                if (res && res.status && res.res.status) {
                    if (res.res.data) {
                        this.setState({
                            isOpenPayFeeDailog: false,
                            isLoading: false,
                            payFeeFormData: {},
                            transctionId: null
                        }, () => { this.onCheckFeePaidInfo(true) });
                        this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res?.errMessage, life: 3000 });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res?.errMessage, life: 3000 });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
            })
        } else {
            this.setState({
                isLoading: false,
            });
            this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: 'Some error occurred', life: 3000 });
        }

    }

    onCliclAddSibling = (bool, index) => {
        if (bool) {
            let arrLength = this.state.siblings.length
            let lastObj = this.state.siblings[arrLength - 1]
            if (lastObj.name !== "") {
                this.setState((prevState) => {
                    return { siblings: [...prevState.siblings, { name: "", gender: "", classStudying: "", schoolStudying: "" }] }
                })
            } else {
                this.toast.show({ severity: 'error', summary: 'Please enter the sibling details', detail: '', life: 3000 });
            }
        }
        else if (bool === false) {
            const { siblings } = this.state
            const newArr = [...siblings]
            newArr.splice(index, 1)
            this.setState({ siblings: newArr })
        }
    }

    onToggleChange = async (e, field) => {
        let data = JSON.parse(JSON.stringify(this.state.registration));
        let formValidations = this.state.formValidations;
        data[field] = e.value;
        let fieldInfo = createRegistrationFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (!data[field]) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }
        this.setState(
            {
                registration: data,
                formValidations,
                selectedFeeStructure: {},
                feeStructure: []
            },
        );
        if (e.value) {
            await this.getTransportInfo();
            await this.getFeeStructures()
        }

    }

    openDailogPayAppFee = () => {
        if (!this.state?.application?.applicationId) {
            let paymentMode = paymentModes.filter((e) => e.value !== '1c98862e-46dc-4454-a120-7d15e2e56e2f' && e.value !== '9d08ebab-2d3e-4a30-8460-4e4092fe30a1')
            this.setState({ isOpenPayFeeDailog: true, paymentModes: paymentMode })
        }
    }

    onHandleNavigate = (isContinue, isNext) => {
        if (isContinue && isNext) {
            localStorage.setItem('applicationId', this.state.registration?._id)
            this.props.navigate('/confirm-enrollment')
        } else {
            !this.state.isEdit ? this.props.navigate('/admission-registration') : this.setState({ isEdit: false }, () => this.props.onHandleClose())
        }
    }

    onSubmitApplication = (isContinue, isNext) => {

        if (!this.state.isRegistrationFeePaid) {
            this.toast.show({ severity: 'error', summary: 'Please Pay Registration Fee', detail: 'Please Pay Registration Fee', life: 3000 });
            return
        }
        if (this.state.registration?.transport && !this.state.selectedFeeStructure) {
            this.toast.show({ severity: 'error', summary: 'Please Select Fee Structure', detail: 'Please Select Fee Structure', life: 3000 });
            return
        }
        if (this.state.registration?.transport && this.state?.requestedStop == "") {
            this.toast.show({ severity: 'error', summary: 'Please Select Requested Stop', detail: 'Please Select Requested Stop', life: 3000 });
            return
        }
        const { registration} = this.state
        const newReg = { ...registration, parentDetails: this.state.parentDetails }
        console.log(newReg);
        registration.siblings = this.state.siblings
        registration.prevAcademicDetails = this.state.prevAcademicDetails
        registration.studentPhoto = registration?.studentPhoto || registration?.application?.studentPhoto
        registration.houseNum = registration?.houseNum || registration?.application?.houseNum;
        registration.pinCode = registration?.pinCode || registration?.application?.pinCode;
        registration.streetOrVillage = registration?.streetOrVillage || registration?.application?.streetOrVillage;
        registration.mandal = registration?.mandal || registration?.application?.mandal;
        registration.cityOrDist = registration?.cityOrDist || registration?.application?.cityOrDist;
        registration.state = registration?.state || registration?.application?.state;
        const formStatus = isFormValid(createRegistrationFields, this.formFields.formValidations, trimObj(registration));
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        let slectedTransportInfo = []
        if (registration?.transport) {
            const { routesList } = this.state
            slectedTransportInfo = routesList?.filter(el => el.stopId === this.state?.requestedStop)
        }
        const academicSession = localStorage.getItem('userAcademicYear')
        if (isCompleteFormValid) {
            let payload = {
                stage: "Registration",
                _id: registration?._id,
                board: registration?.board,
                academicSession: academicSession,
                grade: registration?.grade,
                applicationId: registration?.applicationId,
                identificationMarks: registration.identificationMarks,
                motherTongue: registration.motherTongue,
                nationality: registration.nationality,
                religion: registration.religion,
                caste: registration.caste,
                parentDetails: this.state.parentDetails,
                // fatherName: registration.fatherName,
                // fatherOccupation: registration.fatherOccupation,
                // fatherQualification: registration.fatherQualification,
                // fatherMobileNum: registration.fatherMobileNum,
                // fatherEmailId: registration.fatherEmailId,
                // motherEmailId: registration.motherEmailId,
                // guardianEmailId: registration.guardianEmailId,
                // motherMobileNum: registration.motherMobileNum,
                // guardianMobileNum: registration.guardianMobileNum,
                // motherQualification: registration.motherQualification,
                // guardianQualification: registration.guardianQualification,
                // motherOccupation: registration.motherOccupation,
                // guardianOccupation: registration.guardianOccupation,
                // guardianName: registration.guardianName,
                // motherName: registration.motherName,
                houseNum: registration.houseNum,
                pinCode: registration.pinCode,
                streetOrVillage: registration.streetOrVillage,
                mandal: registration.mandal,
                cityOrDist: registration.cityOrDist,
                state: registration.state,
                transport: registration.transport || false,
                siblings: registration.siblings,
                prevAcademicDetails: registration.prevAcademicDetails,
                studentPhoto: registration.studentPhoto,
                requestedStop: this.state.requestedStop || "",
                transportInfo: slectedTransportInfo?.length && slectedTransportInfo[0],
                feeStructure: this.state.selectedFeeStructure

            }
            this.setState({
                isLoading: true
            });
            if (isNext) {
                payload.stage = "Enrollment"
            }

            const url = `${baseUrlAdmin}/admissions/add-registration`
            this.service.post(url, payload, true).then(res => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        createdId: res?.res?.data,
                    }, () => { this.onHandleNavigate(isContinue, isNext) });
                    this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });

                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: "Error", detail: 'Some error occurred', life: 3000 });
                console.log(e);
            })

        } else {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: 'Some error occurred', life: 3000 });
        }

    }

    getFeeStructures = async () => {
        this.setState({
            isLoading: true
        });
        const { registration, } = this.state
        let academicYear = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/fee-structure/transportfees?academicYear=${academicYear}&isNew=${true}`;
        this.service.get(url, true).then(res => {
            if (res && res.status && res.res.status) {
                if (res.res.data && res.res?.data?.length > 0) {
                    this.setState({
                        isLoading: false,
                        feeStructure: res.res.data || []
                    });
                } else {
                    this.setState(prevState => ({
                        isLoading: false,
                        registration: { ...prevState.registration, transport: false }
                    }));
                    this.toast.show({ severity: 'error', summary: 'No transport fee structures found.', detail: res.errMessage, life: 3000 });
                }
            } else {
                this.setState(prevState => ({
                    isLoading: false,
                    registration: { ...prevState.registration, transport: false }
                }));
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });

        })
    }

    getTransportInfo = async () => {
        this.setState({
            isLoading: true
        });
        let academicYear = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/admissions/transportInfo?academicYear=${academicYear}`;
        let payload = {}
        this.service.post(url, payload, true).then(res => {
            if (res && res.status && res.res.status) {
                if (res.res.data && res.res?.data?.routesList?.length) {
                    this.setState({
                        isLoading: false,
                        routesList: res.res.data.routesList,
                    });
                }
            } else {
                this.setState(prevState => ({
                    isLoading: false,
                    registration: { ...prevState.registration, transport: false }
                }));
                this.toast.show({ severity: 'error', summary: 'Please create transport routes.', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState(prevState => ({
                isLoading: false,
                registration: { ...prevState.registration, transport: false }
            }));
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
            console.log(e);
        })
    }

    render() {
        const { registration, formValidations, payFeeFormData, payFormValidations } = this.state;
        return (
            <>
                <div className=''>
                    {/*  {this.state.isShowPayFeeButton && <div className="text-right mt-4 mb-2">
                        <Button
                            disabled={this.state.isRegistrationFeePaid ? true : false}
                            className='add-vehicle-btn' style={{ paddingLeft: 20, width: 200 }}
                            onClick={() => this.openDailogPayAppFee()}
                        >
                            Pay Registration Fee
                        </Button>
                    </div>} */}
                    <div className="grid md:col-12 lg:col-12 mx-5 column-gap-8 row-gap-5" >
                        {registration?.applicationId && <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Application ID<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    width={250}
                                    className='  add-vehicle-field-input w-full '
                                    value={registration.applicationId}
                                    onChange={(e) => onTextChange(e.target.value, 'applicationId', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Application ID"
                                    disabled={true} />
                            </div>
                            {formValidations && !formValidations.fields['applicationId'].isValid && <p className="p-error text-lg">{formValidations.fields['applicationId'].errorMsg}</p>}

                        </div>}
                        {/* <div className="col-span-1">
                            <p className='transport-fee-form-label'>Academic Session<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    width={250}
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={registration.academicSession}
                                    options={this.state.academicSession}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'academicSession', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                    placeholder='Select Academic Session'
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['academicSession'].isValid && <p className="p-error text-lg">{formValidations.fields['academicSession'].errorMsg}</p>}

                        </div> */}
                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Admission Category<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    width={250}
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={registration.academicCategory}
                                    options={this.state.academicCategory}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'academicCategory', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                    placeholder='Admission Category'
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['academicCategory'].isValid && <p className="p-error text-lg">{formValidations.fields['academicCategory'].errorMsg}</p>}
                        </div>
                        {registration?.applicationId && <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Admission Date<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetCalendar
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' border-round-md add-vehicle-field-input text-xl w-full'
                                    width={250}
                                    calendarMode={'single'}
                                    value={new Date(registration?.application?.date)}
                                    onChange={(e) => { onTextChange(e.target.value, 'admissionDate', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                    maxDate={new Date()}
                                    placeholder="Admission Date" disabled={true} />
                            </div>
                            {formValidations && !formValidations.fields['admissionDate'].isValid && <p className="p-error text-lg">{formValidations.fields['admissionDate'].errorMsg}</p>}

                        </div>}

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Student First Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='add-vehicle-field-input w-full '
                                    value={registration.firstName}
                                    width={250}
                                    onChange={(e) => onTextChange(e.target.value, 'firstName', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Student First Name"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['firstName'].isValid && <p className="p-error text-lg">{formValidations.fields['firstName'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Student Last Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='  add-vehicle-field-input w-full '
                                    width={250}
                                    value={registration.lastName}
                                    onChange={(e) => onTextChange(e.target.value, 'lastName', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Student Last Name" disabled={true} />
                            </div>
                            {formValidations && !formValidations.fields['lastName'].isValid && <p className="p-error text-lg">{formValidations.fields['lastName'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Contact no<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={registration.contactNo}
                                    width={250}
                                    keyfilter='int'
                                    onChange={(e) => onTextChange(e.target.value, 'contactNo', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Contact no" disabled={true} />
                            </div>
                            {formValidations && !formValidations.fields['contactNo'].isValid && <p className="p-error text-lg">{formValidations.fields['contactNo'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1  ">
                            <p className='transport-fee-form-label'>Email ID<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={registration.emailId}
                                    onChange={(e) => onTextChange(e.target.value, 'emailId', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    width={250}
                                    placeholder="Email ID" disabled={true} />
                            </div>
                            {formValidations && !formValidations.fields['emailId'].isValid && <p className="p-error text-lg">{formValidations.fields['emailId'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Gender<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    width={250}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={registration.gender}
                                    options={this.state.genderData}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'gender', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                    placeholder='Select Gender'
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['gender'].isValid && <p className="p-error text-lg">{formValidations.fields['gender'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Date of Birth<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetCalendar
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' border-round-md add-vehicle-field-input text-xl w-full'
                                    width={250}
                                    calendarMode={'single'}
                                    value={new Date(registration.dob)}
                                    onChange={(e) => { onTextChange(e.target.value, 'dob', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                    maxDate={new Date()}
                                    placeholder="DOB" disabled={true} />
                            </div>
                            {formValidations && !formValidations.fields['dob'].isValid && <p className="p-error text-lg">{formValidations.fields['dob'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Aadhar Card<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    width={250}
                                    keyfilter='int'

                                    value={registration.aadharNo}
                                    onChange={(e) => onTextChange(e.target.value, 'aadharNo', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Adhar Card No." disabled={true} />
                            </div>
                            {formValidations && !formValidations.fields['aadharNo'].isValid && <p className="p-error text-lg">{formValidations.fields['aadharNo'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Branch<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='name'
                                    optionValue='key'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={registration.branch}
                                    options={this.props.branchData}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'branch', this, formFields, registration, formValidations, 'registration', 'formValidations'); this.onChangeBranch(e.target.value) }}
                                    placeholder='Select Branch'
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['board'].isValid && <p className="p-error text-lg">{formValidations.fields['board'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Board<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='boardName'
                                    optionValue='boardId'
                                    className='text-xl add-vehicle-field-input w-full'
                                    width={250}
                                    value={registration.board}
                                    options={this.props.boards}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'board', this, formFields, registration, formValidations, 'registration', 'formValidations'); this.onChangeBoard(e.target.value) }}
                                    placeholder='Select Board'
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['board'].isValid && <p className="p-error text-lg">{formValidations.fields['board'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Grade<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='className'
                                    optionValue='classId'
                                    className='text-xl add-vehicle-field-input w-full'
                                    width={250}
                                    value={registration.grade}
                                    options={this.state.classes}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'grade', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                    placeholder='Select Grade'
                                    disabled={true}

                                />
                            </div>
                            {formValidations && !formValidations.fields['grade'].isValid && <p className="p-error text-lg">{formValidations.fields['grade'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Previous School<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    width={250}
                                    value={registration.previousSchool}
                                    onChange={(e) => onTextChange(e.target.value, 'previousSchool', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Previous School" disabled={true} />
                            </div>
                            {formValidations && !formValidations.fields['previousSchool'].isValid && <p className="p-error text-lg">{formValidations.fields['previousSchool'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Previous Board<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='boardName'
                                    optionValue='boardId'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={registration.previousBoard}
                                    options={this.props.boards}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'previousBoard', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                    placeholder='Select Previous Board'
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['previousBoard'].isValid && <p className="p-error text-lg">{formValidations.fields['previousBoard'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="grid md:col-12 lg:col-12 mx-5 column-gap-8 row-gap-5" >
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Mother Tongue<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    width={250}
                                    value={registration.motherTongue}
                                    options={this.state.motherTongueData || []}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'motherTongue', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                    placeholder='Select Mother Tongue'

                                />
                            </div>
                            {formValidations && !formValidations.fields['motherTongue'].isValid && <p className="p-error text-lg">{formValidations.fields['motherTongue'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Nationality<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className=' add-vehicle-field-input w-full '
                                    width={250}
                                    value={registration.nationality}
                                    onChange={(e) => onTextChange(e.target.value, 'nationality', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Select Nationality" />
                            </div>
                            {formValidations && !formValidations.fields['nationality'].isValid && <p className="p-error text-lg">{formValidations.fields['previousSchool'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Religion<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={registration.religion}
                                    options={this.state.religionData}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'religion', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                    placeholder='Select Religion'

                                />
                            </div>
                            {formValidations && !formValidations.fields['religion'].isValid && <p className="p-error text-lg">{formValidations.fields['religion'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Caste<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={registration.caste}
                                    options={this.state.casteData}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'caste', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                    placeholder='Select caste'

                                />
                            </div>
                            {formValidations && !formValidations.fields['caste'].isValid && <p className="p-error text-lg">{formValidations.fields['caste'].errorMsg}</p>}
                        </div>
                        <div className="" >
                            <p className="transport-fee-form-label">Identification Marks<span className="ma-required">*</span></p>
                            <div className="flex">
                                <ClassetTextArea
                                    placeholder='Identification Marks'
                                    className=""
                                    width={550}
                                    value={registration.identificationMarks}
                                    onChange={(e) => onTextChange(e.target.value, 'identificationMarks', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                />
                            </div>
                            {formValidations && !formValidations.fields['identificationMarks'].isValid && <p className="p-error text-lg">{formValidations.fields['identificationMarks'].errorMsg}</p>}
                        </div>
                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Upload Photo<span className='ma-required'>*</span></p>
                            <div className='flex'>

                                <FileUpload id={"student"}
                                    multiple={false}
                                    onProgress={this._onProgress}
                                    onSucess={this._onStudentPhotoSuccess}
                                    //  Reset={this.state.Reset}
                                    maxFileSize={307200}
                                    onError={this._onFilsUploadError}
                                    accept={'images'}
                                    title={'Images'}
                                    afterFilesUploaded={this._afterFilesUploaded}
                                    className="border-solid"
                                >  <ClassetInputText
                                        width={250}
                                        icon={<FileAttachmentIcon width={24} height={24} color={"black"} />}
                                        className='add-vehicle-field-input w-full '
                                        value={registration?.studentPhoto?.fileName || registration?.application?.studentPhoto?.fileName}
                                        onChange={(e) => onTextChange(e.target.value, 'studentPhoto', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                        placeholder="Upload Photo" disabled={true} />
                                </FileUpload>
                                {/*   */}
                            </div>
                            {formValidations && !formValidations.fields['studentPhoto'].isValid && <p className="p-error text-lg">{formValidations.fields['studentPhoto'].errorMsg}</p>}

                        </div>
                    </div>
                    <div className="flex justify-content-between align-items-center" style={{ maxWidth: '1250px' }}>
                        <h4 className="mx-6 formHeader mt-5">Parent's Details</h4>



                    </div>
                    {this.state.parentDetails?.map((obj, index) => {
                        return (
                            <div className="flex justify-content-between align-items-start" style={{ maxWidth: '1250px' }}>
                                <div key={index} className="grid md:col-12 lg:col-12 mx-5 column-gap-8 row-gap-5" >
                                    <div className="col-span-1 ">
                                        <p className='transport-fee-form-label'>Relation<span className='ma-required'>*</span></p>
                                        <div className='flex'>
                                            <ClassetDropdown
                                                width={250}
                                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                optionLabel='label'
                                                optionValue='value'
                                                className='text-xl add-vehicle-field-input w-full'
                                                value={obj.relation}
                                                options={this.state.relationData}
                                                onChange={(e) => this.handleParentDetailsChange(e.target.value, "relation", index)}
                                                // onChange={(e) => { onDropDownChange(e.target.value, 'fatherQualification', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                                placeholder='Select Relation'

                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['fatherQualification'].isValid && <p className="p-error text-lg">{formValidations.fields['fatherQualification'].errorMsg}</p>} */}

                                    </div>
                                    <div className="col-span-1 ">
                                        <p className='transport-fee-form-label'>Parent's Name<span className='ma-required'>*</span></p>
                                        <div className='flex'>
                                            <ClassetInputText
                                                icon={<MailIcon width={24} height={24} color={"black"} />}
                                                // id="title"
                                                className='add-vehicle-field-input w-full '
                                                value={obj.name}
                                                width={250}
                                                onChange={(e) => this.handleParentDetailsChange(e.target.value, "name", index)}

                                                // onChange={(e) => onTextChange(e.target.value, 'fatherName', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                                placeholder="Enter Parent Name"

                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['fatherName'].isValid && <p className="p-error text-lg">{formValidations.fields['fatherName'].errorMsg}</p>} */}

                                    </div>
                                    <div className="col-span-1 ">
                                        <p className='transport-fee-form-label'>Occupation<span className='ma-required'>*</span></p>
                                        <div className='flex'>
                                            <ClassetInputText
                                                icon={<MailIcon width={24} height={24} color={"black"} />}
                                                // id="title"
                                                className='  add-vehicle-field-input w-full '
                                                width={250}
                                                value={obj.occupation}
                                                onChange={(e) => this.handleParentDetailsChange(e.target.value, "occupation", index)}
                                                // onChange={(e) => onTextChange(e.target.value, 'fatherOccupation', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                                placeholder="Enter Occupation" />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['fatherOccupation'].isValid && <p className="p-error text-lg">{formValidations.fields['fatherOccupation'].errorMsg}</p>} */}

                                    </div>
                                    <div className="col-span-1 ">
                                        <p className='transport-fee-form-label'>Qualification<span className='ma-required'>*</span></p>
                                        <div className='flex'>
                                            <ClassetDropdown
                                                width={250}
                                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                                optionLabel='label'
                                                optionValue='value'
                                                className='text-xl add-vehicle-field-input w-full'
                                                value={obj.qualification}
                                                options={this.state.qualificationData}
                                                onChange={(e) => this.handleParentDetailsChange(e.target.value, "qualification", index)}
                                                // onChange={(e) => { onDropDownChange(e.target.value, 'fatherQualification', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                                placeholder='Select Qualification'

                                            />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['fatherQualification'].isValid && <p className="p-error text-lg">{formValidations.fields['fatherQualification'].errorMsg}</p>} */}

                                    </div>
                                    <div className="col-span-1 ">
                                        <p className='transport-fee-form-label'>Mobile Number<span className='ma-required'>*</span></p>
                                        <div className='flex'>
                                            <ClassetInputText
                                                icon={<MailIcon width={24} height={24} color={"black"} />}
                                                className='  add-vehicle-field-input w-full '
                                                value={obj.mobilenumber}
                                                width={250}
                                                keyfilter='int'
                                                onChange={(e) => this.handleParentDetailsChange(e.target.value, "mobilenumber", index)}
                                                // onChange={(e) => onTextChange(e.target.value, 'fatherMobileNum', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                                placeholder="Contact no" />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['fatherMobileNum'].isValid && <p className="p-error text-lg">{formValidations.fields['fatherMobileNum'].errorMsg}</p>} */}

                                    </div>

                                    <div className="col-span-1  ">
                                        <p className='transport-fee-form-label'>Email ID<span className='ma-required'>*</span></p>
                                        <div className='flex'>
                                            <ClassetInputText
                                                icon={<MailIcon width={24} height={24} color={"black"} />}
                                                className='  add-vehicle-field-input w-full '
                                                value={obj.mailid}
                                                onChange={(e) => this.handleParentDetailsChange(e.target.value, "mailid", index)}
                                                // onChange={(e) => onEmailChange(e.target.value, 'fatherEmailId', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                                width={250}
                                                placeholder="Email ID" />
                                        </div>
                                        {/* {formValidations && !formValidations.fields['fatherEmailId'].isValid && <p className="p-error text-lg">{formValidations.fields['fatherEmailId'].errorMsg}</p>} */}

                                    </div>
                                </div>
                                <div className="flex flex-column justify-content-center align-items-center gap-2">
                                    <Button icon="pi pi-plus" aria-label="Increase"
                                        onClick={() => this.onClickParentDetailsPlusOrMinus(true, index)}
                                    />
                                    {this.state.parentDetails.length > 1 && <Button icon="pi pi-minus" aria-label="Decrease" onClick={() => this.onClickParentDetailsPlusOrMinus(false, index)} />}
                                </div>
                            </div>
                        )
                    })}


                    {/* <h4 className="mx-6 formHeader mt-5">Mother's Details</h4> */}
                    {/* <div className="grid md:col-12 lg:col-12 mx-5 column-gap-8 row-gap-5" >
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Mother's Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='add-vehicle-field-input w-full '
                                    value={registration.motherName}
                                    width={250}
                                    onChange={(e) => onTextChange(e.target.value, 'motherName', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Enter mother Name"

                                />
                            </div>
                            {formValidations && !formValidations.fields['motherName'].isValid && <p className="p-error text-lg">{formValidations.fields['motherName'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Occupation<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='  add-vehicle-field-input w-full '
                                    width={250}
                                    value={registration.motherOccupation}
                                    onChange={(e) => onTextChange(e.target.value, 'motherOccupation', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Enter Occupation" />
                            </div>
                            {formValidations && !formValidations.fields['motherOccupation'].isValid && <p className="p-error text-lg">{formValidations.fields['motherOccupation'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Qualification<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={registration.motherQualification}
                                    options={this.state.qualificationData}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'motherQualification', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                    placeholder='Select Qualification'

                                />
                            </div>
                            {formValidations && !formValidations.fields['motherQualification'].isValid && <p className="p-error text-lg">{formValidations.fields['motherQualification'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Mobile Number<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={registration.motherMobileNum}
                                    width={250}
                                    keyfilter='int'
                                    onChange={(e) => onTextChange(e.target.value, 'motherMobileNum', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Contact no" />
                            </div>
                            {formValidations && !formValidations.fields['motherMobileNum'].isValid && <p className="p-error text-lg">{formValidations.fields['motherMobileNum'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1  ">
                            <p className='transport-fee-form-label'>Email ID<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={registration.motherEmailId}
                                    onChange={(e) => onEmailChange(e.target.value, 'motherEmailId', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    width={250}
                                    placeholder="Email ID" />
                            </div>
                            {formValidations && !formValidations.fields['motherEmailId'].isValid && <p className="p-error text-lg">{formValidations.fields['motherEmailId'].errorMsg}</p>}

                        </div>
                    </div> */}


                    {/* <h4 className="mx-6 formHeader mt-5">Guardian's Details</h4> */}
                    {/* <div className="grid md:col-12 lg:col-12 mx-5 column-gap-8 row-gap-5" >
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Guardian Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='add-vehicle-field-input w-full '
                                    value={registration.guardianName}
                                    width={250}
                                    onChange={(e) => onTextChange(e.target.value, 'guardianName', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Enter Guardian Name"

                                />
                            </div>
                            {formValidations && !formValidations.fields['guardianName'].isValid && <p className="p-error text-lg">{formValidations.fields['guardianName'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Occupation<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='  add-vehicle-field-input w-full '
                                    width={250}
                                    value={registration.guardianOccupation}
                                    onChange={(e) => onTextChange(e.target.value, 'guardianOccupation', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Enter Occupation" />
                            </div>
                            {formValidations && !formValidations.fields['guardianOccupation'].isValid && <p className="p-error text-lg">{formValidations.fields['guardianOccupation'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Qualification<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    width={250}
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={registration.guardianQualification}
                                    options={this.state.qualificationData}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'guardianQualification', this, formFields, registration, formValidations, 'registration', 'formValidations') }}
                                    placeholder='Select Qualification'

                                />
                            </div>
                            {formValidations && !formValidations.fields['guardianQualification'].isValid && <p className="p-error text-lg">{formValidations.fields['guardianQualification'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Mobile Number<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={registration.guardianMobileNum}
                                    width={250}
                                    keyfilter='int'
                                    onChange={(e) => onTextChange(e.target.value, 'guardianMobileNum', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Contact no" />
                            </div>
                            {formValidations && !formValidations.fields['guardianMobileNum'].isValid && <p className="p-error text-lg">{formValidations.fields['guardianMobileNum'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1  ">
                            <p className='transport-fee-form-label'>Email ID<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={registration.guardianEmailId}
                                    onChange={(e) => onEmailChange(e.target.value, 'guardianEmailId', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    width={250}
                                    placeholder="Email ID" />
                            </div>
                            {formValidations && !formValidations.fields['guardianEmailId'].isValid && <p className="p-error text-lg">{formValidations.fields['guardianEmailId'].errorMsg}</p>}

                        </div>
                    </div> */}

                    <h4 className="mx-6 mt-5 formHeader">Siblings</h4>

                    <div className='p-grid ma-w100p mx-5 ma-p20 mb-5'>
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.siblings}>
                            <Column header='Sl.No' className='ma-w100 ma-h100' body={(rowData, index) => {
                                return (<span>{index.rowIndex + 1}</span>)
                            }} />
                            <Column header="Name" body={(rowData, index) => {
                                return (
                                    <ClassetInputText
                                        value={this.state.siblings[index.rowIndex].name}
                                        width={250}
                                        placeholder="Enter Name"
                                        onChange={(e) => this.onChangeInput(e.target.value, index.rowIndex, "name", "siblings")}
                                    />
                                )
                            }} />
                            <Column header="Gender" body={(rowData, index) => {
                                return (
                                    <ClassetDropdown disabled={this.state.hideUpdateButton}
                                        options={this.state.genderData} optionLabel='label' optionValue='value'
                                        width={250}
                                        value={this.state.siblings[index.rowIndex].gender}
                                        onChange={(e) => this.onChangeInput(e.target.value, index.rowIndex, "gender", "siblings")}
                                        placeholder="Select Gender" />
                                )
                            }} />
                            <Column header="Class Studying" body={(rowData, index) => {
                                return (
                                    <ClassetDropdown disabled={this.state.hideUpdateButton}
                                        options={this.state.classStudyingDrp} optionLabel='label' optionValue='value'
                                        width={250}
                                        value={this.state.siblings[index.rowIndex].classStudying}
                                        onChange={(e) => this.onChangeInput(e.target.value, index.rowIndex, "classStudying", "siblings")}
                                        placeholder="Select Class" />
                                )
                            }} />
                            <Column header="School Studying" body={(rowData, index) => {
                                return (
                                    <ClassetInputText
                                        width={250}
                                        value={this.state.siblings[index.rowIndex].schoolStudying}
                                        placeholder="Enter School Studying"
                                        onChange={(e) => this.onChangeInput(e.target.value, index.rowIndex, "schoolStudying", "siblings")}
                                    />
                                )
                            }} />
                            <Column header="" body={(rowData, index) => {
                                return (
                                    <div className="flex flex-column align-items-center justify-content-center gap-2">
                                        <Button icon="pi pi-plus" aria-label="Increase" onClick={() => this.onCliclAddSibling(true, index.rowIndex)} />
                                        {this.state.siblings.length > 1 && <Button icon="pi pi-minus" aria-label="Decrease" onClick={() => this.onCliclAddSibling(false, index.rowIndex)} />}
                                    </div>
                                )
                            }} />
                        </DataTable>
                    </div>


                    <h4 className="mx-6 mt-5 formHeader">Previous Academic Details</h4>
                    <div className='p-grid ma-w100p mx-5 ma-p20 mb-5'>
                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.prevAcademicDetails}>
                            <Column header='Sl.No' className='ma-w100 ma-h100' body={(rowData, index) => {
                                return (<span>{index.rowIndex + 1}</span>)
                            }} />
                            <Column header="Name" body={(rowData, index) => {
                                return (
                                    <ClassetInputText
                                        value={this.state.prevAcademicDetails[index.rowIndex].name}
                                        onChange={(e) => this.onChangeInput(e.target.value, index.rowIndex, "name", "prevAcademicDetails")}
                                        width={250}
                                        placeholder="Enter Name"
                                    />
                                )
                            }} />
                            <Column header="Class Studied" body={(rowData, index) => {
                                return (
                                    <ClassetDropdown width={250} disabled={this.state.hideUpdateButton}
                                        options={this.state.classStudyingDrp} optionLabel='label' optionValue='value' placeholder="Select Class"
                                        value={this.state.prevAcademicDetails[index.rowIndex].classStudied}
                                        onChange={(e) => this.onChangeInput(e.target.value, index.rowIndex, "classStudied", "prevAcademicDetails")} />
                                )
                            }} />
                            <Column header="School Studied" body={(rowData, index) => {
                                return (
                                    <ClassetInputText width={250}
                                        value={this.state.prevAcademicDetails[index.rowIndex].schoolStudied}
                                        placeholder="Enter School Studied"
                                        onChange={(e) => this.onChangeInput(e.target.value, index.rowIndex, "schoolStudied", "prevAcademicDetails")} />
                                )
                            }} />
                            <Column header="Percentage/GPA" body={(rowData, index) => {
                                return (
                                    <ClassetInputText width={250}
                                        placeholder="Enter percentage/Gpa"
                                        value={this.state.prevAcademicDetails[index.rowIndex].percentageOrGpa}
                                        onChange={(e) => this.onChangeInput(e.target.value, index.rowIndex, "percentageOrGpa", "prevAcademicDetails")} />
                                )
                            }} />
                            <Column header="" body={(rowData, index) => {
                                return (
                                    <div className="flex flex-column align-items-center justify-content-center gap-2">
                                        <Button icon="pi pi-plus" aria-label="Increase" onClick={() => this.onCliclAddPrevAcadamicDet(true, index.rowIndex)} />
                                        {this.state.prevAcademicDetails.length > 1 && <Button icon="pi pi-minus" aria-label="Decrease" onClick={() => this.onCliclAddPrevAcadamicDet(false, index.rowIndex)} />}
                                    </div>
                                )
                            }} />
                        </DataTable>
                    </div>

                    <h4 className="mx-6 mt-5 formHeader">Address</h4>
                    <div className="grid md:col-12 lg:col-12 mx-5 column-gap-8 row-gap-5" >
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>H.No./D.No<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='add-vehicle-field-input w-full '
                                    value={registration?.application?.houseNum ?? registration.houseNum}
                                    width={250}
                                    onChange={(e) => onTextChange(e.target.value, 'houseNum', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Enter H.No./D.No"

                                />
                            </div>
                            {formValidations && !formValidations.fields['houseNum'].isValid && <p className="p-error text-lg">{formValidations.fields['houseNum'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Street/Village<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='  add-vehicle-field-input w-full '
                                    width={250}
                                    value={registration?.application?.streetOrVillage || registration.streetOrVillage}
                                    onChange={(e) => onTextChange(e.target.value, 'streetOrVillage', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Enter Street/Village"
                                />
                            </div>
                            {formValidations && !formValidations.fields['streetOrVillage'].isValid && <p className="p-error text-lg">{formValidations.fields['streetOrVillage'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Mandal<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='  add-vehicle-field-input w-full '
                                    width={250}
                                    value={registration?.application?.mandal || registration.mandal}
                                    onChange={(e) => onTextChange(e.target.value, 'mandal', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Enter Mandal"
                                />
                            </div>
                            {formValidations && !formValidations.fields['mandal'].isValid && <p className="p-error text-lg">{formValidations.fields['mandal'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>City/District<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={registration?.application?.cityOrDist || registration.cityOrDist}
                                    width={250}
                                    onChange={(e) => onTextChange(e.target.value, 'cityOrDist', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Enter City/District"
                                />
                            </div>
                            {formValidations && !formValidations.fields['cityOrDist'].isValid && <p className="p-error text-lg">{formValidations.fields['cityOrDist'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1  ">
                            <p className='transport-fee-form-label'>State<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={registration?.application?.state || registration.state}
                                    onChange={(e) => onTextChange(e.target.value, 'state', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    width={250}
                                    placeholder="Enter State"
                                />
                            </div>
                            {formValidations && !formValidations.fields['state'].isValid && <p className="p-error text-lg">{formValidations.fields['state'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Pincode<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={registration?.application?.pinCode || registration.pinCode}
                                    width={250}
                                    keyfilter='int'
                                    onChange={(e) => onTextChange(e.target.value, 'pinCode', this, formFields, registration, formValidations, 'registration', 'formValidations')}
                                    placeholder="Enter Pincode"
                                />
                            </div>
                            {formValidations && !formValidations.fields['pinCode'].isValid && <p className="p-error text-lg">{formValidations.fields['pinCode'].errorMsg}</p>}

                        </div>
                    </div>



                    <div className="grid md:col-10 lg:col-10 ">

                        <div className="flex ml-5">
                            <div className="ml-3 mr-5">
                                <div className="flex  column-gap-4 mb-2">
                                    <h5>Transport</h5>
                                    <InputSwitch checked={registration?.transport} onChange={(e) => this.onToggleChange(e, 'transport')} />
                                </div>
                                <div className="col-span-1 ">
                                    <p className='transport-fee-form-label'>Requested Stop<span className='ma-required'>*</span></p>
                                    <div className='flex'>
                                        <ClassetDropdown
                                            filter showClear filterBy="label"
                                            options={this.state.routesList} optionLabel='label' optionValue='stopId'
                                            width={250}
                                            value={this.state.requestedStop}
                                            onChange={(e) => this.setState({ requestedStop: e.target.value },)}
                                            placeholder="Enter Requested Stop"
                                            disabled={!registration?.transport ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ml-5 mt-5 grid md:col-8 lg:col-8 column-gap-4">
                                <div className="col-span-1 ">
                                    <p className='transport-fee-form-label'>Transport Fee Structure<span className='ma-required'>*</span></p>
                                    <div className='flex'>
                                        <ClassetDropdown
                                            options={this.state.feeStructure} optionLabel='scheduleName' optionValue='_id'
                                            width={250}
                                            value={this.state.selectedFeeStructure}
                                            onChange={(e) => { this.setState({ selectedFeeStructure: e.target.value }) }}
                                            placeholder="Enter Fee Structure"
                                            disabled={!registration?.transport ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="text-center mt-8">

                        {this.state.isShowPayFeeButton && !this.state?.isRegistrationFeePaid &&
                            (<Button
                                disabled={this.state.isRegistrationFeePaid ? true : false}
                                className='confirmDialogAcceptBtn mr-5 w-18rem p-5' style={{ paddingLeft: 20, width: 200 }}
                                onClick={() => this.openDailogPayAppFee()}
                            >
                                Pay Registration Fee
                            </Button>)
                        }
                        {this.state?.isRegistrationFeePaid && (
                            <Button icon={'pi pi-plus-circle text-lg '} label={!this.state.isEdit ? 'Save Registration' : 'Update Registration'} onClick={() => !this.state.isEdit ? this.onSubmitApplication(true) : this.onSubmitApplication(false)} className='confirmDialogAcceptBtn  mr-5 w-18rem p-5' />
                        )}

                        {!this.state.isEdit && this.state?.isRegistrationFeePaid && <Button icon={'pi pi-plus-circle text-lg '} label='Save and Proceed to Next Step' onClick={() => this.onSubmitApplication(true, true)} className='confirmDialogAcceptBtn  mr-5 w-24rem p-5' />}

                        <Button label='Cancel' className='confirmDialogAcceptBtn p-5' onClick={() => !this.state.isEdit ? this.props.navigate('/admission-registration') : this.setState({ isEdit: false }, () => this.props.onHandleClose())} />

                    </div>
                </div>

                <Dialog
                    isopen={this.state.isOpenPayFeeDailog}
                    visible={this.state.isOpenPayFeeDailog}
                    className="pay-fee"
                    style={{ position: 'fixed', right: '0' }}
                    draggable={false}
                    onHide={this.onHandleCancelPayFee}
                    closable={false}
                    header={() => {
                        return (<div className='mt-2 mb-4'>
                            <h3 className="formhead text-center ">Pay Fee</h3>

                        </div>)
                    }}
                >
                    <div className="mb-5 ml-5 mr-5">
                        <div>
                            <div className="col-12">
                                <div className="mt-2">
                                    <ClassetInputText
                                        id='term'
                                        className=""
                                        height="48px"
                                        width="368px"
                                        placeholder="Fee Type name"
                                        value={`${this.state.feePayInfo?.feeName || ""}`}
                                        disabled={true}
                                        onChange={(e) => onTextChange(e.target.value, 'FeeTypeName', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations)}
                                    />
                                </div>
                                {payFormValidations && !payFormValidations.fields['FeeTypeName'].isValid && <p className="p-error">{payFormValidations.fields['FeeTypeName'].errorMsg}</p>}
                            </div>

                            <div className="col-12">
                                <div className="mt-2">
                                    <ClassetInputText
                                        id='feeAmount'
                                        className=""
                                        height="48px"
                                        width="368px"
                                        placeholder="Fee Amount"
                                        disabled={true}
                                        value={`${Number(this.state.feePayInfo?.amount) || 0}`}
                                        onChange={(e) => onTextChange(e.target.value, 'feeAmount', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations)}

                                    />
                                </div>
                                {payFormValidations && !payFormValidations.fields['feeAmount'].isValid && <p className="p-error">{payFormValidations.fields['feeAmount'].errorMsg}</p>}
                            </div>
                            <div className="col-12">
                                <div className="mt-2">
                                    <ClassetDropdown
                                        id='paymentType'
                                        className=""
                                        height="48px"
                                        width="368px"
                                        options={this.state.paymentModes}
                                        value={payFeeFormData.paymentType}
                                        onChange={(e) => { onDropDownChange(e.target.value, 'paymentType', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations) }}
                                        placeholder={(
                                            <div>
                                                <span className=''>Payment Type</span>
                                            </div>
                                        )}
                                    />
                                </div>
                                {payFormValidations && !payFormValidations.fields['paymentType'].isValid && <p className="p-error">{payFormValidations.fields['paymentType'].errorMsg}</p>}
                            </div>
                            {(payFeeFormData.paymentType === '1b1a76b4-c538-4968-a20b-0e5a3b4a1c54' || payFeeFormData.paymentType === 'b9e46415-1b11-4377-9105-58b98c230935' || payFeeFormData.paymentType === '274a4416-2454-4f3a-b741-89c67361ae1f' || payFeeFormData.paymentType === '32b180cf-7727-46ef-b2a8-e7b1b19fd489') &&
                                <div className="col-12">
                                    <label className='label mb-2 '>Transaction Id<span className='ma-required'>*</span></label>
                                    <div className="mt-2">
                                        <ClassetInputText
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Enter Transaction Id"
                                            value={this.state.transctionId}
                                            onChange={(e) => this.setState({ transctionId: e.target.value })}

                                        />
                                    </div>

                                </div>
                            }
                            {payFeeFormData.paymentType &&
                                <div className="col-12">
                                    <label className='label mb-2 '>Remarks<span className='ma-required'></span></label>
                                    <div className="mt-2">
                                        <ClassetTextArea
                                            id='feeType'
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Enter Remarks"
                                            value={payFeeFormData.remarks}
                                            onChange={(e) => onTextChange(e.target.value, 'remarks', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations)}

                                        />
                                    </div>

                                </div>
                            }
                            <div className='flex justify-content-center mb-4 mt-4'>

                                <Button className='formSubmitButtonn mr-2' style={{ borderRadius: '12px', fontSize: '16px', backgroundColor: '#076EFF' }}
                                    onClick={this.OnPayFee}
                                >
                                    <AddIcon2 />
                                    <p className='formSubmitButtonnText'>Pay Now</p>
                                </Button>
                                <Button className='cancelButtonn w-4'
                                    onClick={this.onHandleCancelPayFee} >

                                    <p className='cancelButtonnText'>Cancel</p>
                                </Button>
                            </div>
                        </div>
                    </div>


                </Dialog>
                {
                    this.state.isShowReceipt && <FeeReciept receiptData={this.state?.receiptInfo} hideFeeReciept={() => {
                        this.setState({
                            isShowReceipt: false,
                            receiptInfo: {}
                        })

                    }} />
                }
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }

                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}
const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(Authentication(withRouter(CreateRegistration)));