import { cloneDeep } from 'lodash';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

import React, { Component } from 'react'
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { trimObj } from '../../../utile';
import { getFormFields, isFormValid, onDropDownChange, onNumberChange, onTextChange } from '../../../utile/formHelper';
import routeFieldsJson from './routeFields.json'

export default class AddRoute extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(routeFieldsJson, {})
        this.state = {
            routeField: this.props.editRouteDetails ? this.props.editRouteDetails : this.formFields.data,
            formValidations: this.formFields.formValidations,
            isLoading: false,
        }

        this.service = new Service()
    }

    componentDidMount() {
        this.getMetadata()
    }

    getMetadata = () => {
        const academicYear = localStorage.getItem("userAcademicYear")
        this.setState({
            isLoading: true
        })

        let url = `${baseUrlAdmin}/transport-route/meta-data?academicYear=${academicYear}`

        this.service.get(url, true).then(res => {
            if (res?.status && res?.res?.status) {
                this.setState({
                    isLoading: false,
                    vehicleNumberOptions: res?.res?.data?.vehicles,
                    inchargeOptions: res?.res?.data?.inchargeMembers,
                    driverOptions: res?.res?.data?.drivers,
                    cleanerOptions: res?.res?.data?.attender,
                })
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: err.errMessage, life: 3000 });

        })
    }


    onSubmitClick = () => {

        let body = cloneDeep(this.state.routeField)
        const formStatus = isFormValid(routeFieldsJson, this.formFields.formValidations, trimObj(body));
        let isCompleteFormValid = true;

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            })
            isCompleteFormValid = false
        }

        if (isCompleteFormValid) {

            let body = this.state.routeField

            this.setState({
                isLoading: true
            })

            if (this.props.editRouteDetails) {


                let url = `${baseUrlAdmin}/transport-route`

                this.service.put(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, this.props.onSaveOrUpdate(true))
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

                })

            } else {

                let url = `${baseUrlAdmin}/transport-route`

                this.service.post(url, body, true).then(res => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isLoading: false,
                        }, this.props.onSaveOrUpdate())
                    } else {
                        this.setState({
                            isLoading: false
                        })
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                    }
                }).catch(err => {
                    this.setState({
                        isLoading: false
                    })
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.errMessage, life: 3000 });

                })

            }
        }
    }

    onDropDownChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationsName,) => {

        let selectedDriver = this.state.driverOptions.find(e => e._id === value);
        let selectedCleaner = this.state.cleanerOptions.find(e => e._id === value);
        let selectedIncharge = this.state.inchargeOptions.find(e => e._id === value);

        let data = JSON.parse(JSON.stringify(formData));
        data[fieldName] = value;

        if (fieldName === 'driver') {
            data['drivingLicence'] = selectedDriver.drivingLicence;
            data['driverMobileNumber'] = selectedDriver.phoneNumber;
            data['rtoExpiryDate'] = selectedDriver.rtoExpiryDate;
        }

        if (fieldName === 'cleaner') {
            data['cleanerMobileNumber'] = selectedCleaner.phoneNumber;
        }

        if (fieldName === 'incharge') {
            data['inchargeMobileNumber'] = selectedIncharge.phoneNumber;
        }

        let fieldInfo = formFields.filter((f) => f.FieldName === fieldName)
        fieldInfo = fieldInfo[0];
        if (formValidations.fields[fieldName].isRequired) {
            if (data[fieldName] === '' || data[fieldName] == null) {
                formValidations.fields[fieldName] = {
                    ...formValidations.fields[fieldName],
                    isValid: false,
                    errorMsg: fieldInfo.RequiredMsg ? fieldInfo.RequiredMsg : `${fieldInfo.Label} is required.`
                }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[fieldName] = {
                    ...formValidations.fields[fieldName],
                    isValid: true,
                    errorMsg: ``
                }
            }
        }

        thisObj.setState({
            [formName]: data,
            [formValidationsName]: formValidations
        });
    };

    footer = () => {
        return (<div>
            <Button label={`${this.props.editRouteDetails ? 'Update' : 'Save'}`} className='p-button-raised ma-m-lr10' onClick={this.onSubmitClick} />
            <Button label="Cancel" className='p-button-outlined' onClick={this.props.onClose} />
        </div>)
    }

    render() {
        const { formValidations, routeField } = this.state
        console.log(routeField)

        return (
            <>
                <Dialog
                    visible={this.props.visible}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    dismissableMask={true}
                    closable={false}
                    style={{ maxWidth: '50%', minWidth: '50%' }}
                    footer={this.footer}
                    header={`${this.props.editRouteDetails ? 'Update' : 'Add'} Route`}
                >
                    <div className="grid subject-add-dialog">
                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Route Name<span className='ma-required'>*</span></p>
                        </div>
                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w80p'
                                value={routeField.routeName}
                                onChange={(e) => onTextChange(e.target.value, 'routeName', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['routeName'].isValid && <p className="p-error">{formValidations.fields['routeName'].errorMsg}</p>}
                        </div>

                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Vehicle Number<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <Dropdown className='w-12 ma-w80p'
                                options={this.state.vehicleNumberOptions} optionLabel='vehicleRegistrationNo' optionValue='_id'
                                value={routeField.vehicle}
                                onChange={(e) => onDropDownChange(e.target.value, 'vehicle', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['vehicle'].isValid && <p className="p-error">{formValidations.fields['vehicle'].errorMsg}</p>}
                        </div>

                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Transport Incharge<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <Dropdown className='w-11 ma-w80p'
                                options={this.state.inchargeOptions} optionLabel='name' optionValue='_id'
                                value={routeField.incharge}
                                onChange={(e) => this.onDropDownChange(e.target.value, 'incharge', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['incharge'].isValid && <p className="p-error">{formValidations.fields['incharge'].errorMsg}</p>}
                        </div>

                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Incharge Mobile Number<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w80p' disabled
                                value={routeField.inchargeMobileNumber}
                                onChange={(e) => onNumberChange(e.target.value, 'inchargeMobileNumber', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['inchargeMobileNumber'].isValid && <p className="p-error">{formValidations.fields['inchargeMobileNumber'].errorMsg}</p>}

                        </div>

                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Driver Name<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <Dropdown className='w-11 ma-w80p'
                                options={this.state.driverOptions} optionLabel='name' optionValue='_id'
                                value={routeField.driver}
                                onChange={(e) => this.onDropDownChange(e.target.value, 'driver', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['driver'].isValid && <p className="p-error">{formValidations.fields['driver'].errorMsg}</p>}

                        </div>

                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Driver Licence No<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w80p' disabled
                                value={routeField.drivingLicence}
                                onChange={(e) => onTextChange(e.target.value, 'drivingLicence', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['drivingLicence'].isValid && <p className="p-error">{formValidations.fields['drivingLicence'].errorMsg}</p>}

                        </div>

                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Driver Mobile Number<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w80p' disabled
                                value={routeField.driverMobileNumber}
                                onChange={(e) => onNumberChange(e.target.value, 'driverMobileNumber', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['driverMobileNumber'].isValid && <p className="p-error">{formValidations.fields['driverMobileNumber'].errorMsg}</p>}

                        </div>

                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>RTO Expiry Date<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <Calendar className='w-11 ma-w80p' disabled showIcon
                                value={new Date(routeField.rtoExpiryDate)}
                                onChange={(e) => this.onNumberChange(e.target.value, 'rtoExpiryDate', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['rtoExpiryDate'].isValid && <p className="p-error">{formValidations.fields['rtoExpiryDate'].errorMsg}</p>}

                        </div>

                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Cleaner Name<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <Dropdown className='w-11 ma-w80p'
                                options={this.state.cleanerOptions} optionLabel='name' optionValue='_id'
                                value={routeField.cleaner}
                                onChange={(e) => this.onDropDownChange(e.target.value, 'cleaner', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['cleaner'].isValid && <p className="p-error">{formValidations.fields['cleaner'].errorMsg}</p>}

                        </div>

                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Cleaner Mobile Number<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w80p' disabled
                                value={routeField.cleanerMobileNumber}
                                onChange={(e) => onNumberChange(e.target.value, 'cleanerMobileNumber', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['cleanerMobileNumber'].isValid && <p className="p-error">{formValidations.fields['cleanerMobileNumber'].errorMsg}</p>}

                        </div>

                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Start Location<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w80p'
                                value={routeField.startLocation}
                                onChange={(e) => onTextChange(e.target.value, 'startLocation', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['startLocation'].isValid && <p className="p-error">{formValidations.fields['startLocation'].errorMsg}</p>}

                        </div>

                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Start Time<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <Calendar className='w-11 ma-w80p'
                                value={routeField.startTime ? new Date(routeField.startTime) : null}
                                timeOnly
                                onChange={(e) => onTextChange(e.value, 'startTime', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['startTime'].isValid && <p className="p-error">{formValidations.fields['startTime'].errorMsg}</p>}

                        </div>

                        <div className="col-2 p-md-11 p-lg-2">
                            <p className='text-label p-mt-3'>Number Of Stops<span className='ma-required'>*</span></p>
                        </div>

                        <div className="col-4 p-md-11 p-lg-4 ma-mt2">
                            <InputText className='w-12 p-inputtext-style1 ma-w80p'
                                value={routeField.numberOfStops}
                                onChange={(e) => onNumberChange(e.target.value, 'numberOfStops', this, routeFieldsJson, routeField, formValidations, 'routeField', 'formValidations')}
                            />
                            {formValidations && !formValidations.fields['numberOfStops'].isValid && <p className="p-error">{formValidations.fields['numberOfStops'].errorMsg}</p>}

                        </div>


                    </div>
                </Dialog>
            </>
        )
    }
}
