import React, { Component } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { AddIconInButtonFull, AddUserIcon, ClassetEditIcon } from '../../svgIcons';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { BasicLazyParams } from '../../../utile';
import Service from '../../services';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';

export default class TimeTables extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timetables: this.props.timetables,
            globalSearch: '',
            lazyParams: BasicLazyParams,
            isStatusChangeDialog: false,
            statusobj: null
        };
        this.menuRefs = {};
        this.service = new Service();
    }

    toggleChange = (e, index) => {
        const updatedTimetables = [...this.state.timetables];
        updatedTimetables[index].status = e.value;
        this.setState({ timetables: updatedTimetables });
    };

    handleEditTimeTable = (table) => {
        // console.log(table);
        if (!table.canEdit) return this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: 'You can not edit this template as it is already in use', life: 3000 });
        this.props.sendEditData(table);
        this.props.onTabChange(1);
    };

    render() {
        return (
            <div className="ml-5 grid h-4rem poppins24">
                <div className="mt-3 md:col-12 lg:col-12 xl:col-12">
                    <div className="grid mb-6">
                        <div className="col-11 flex justify-content-end align-items-center">
                            <Button
                                style={{ borderRadius: 10, backgroundColor: '#076EFF' }}
                                onClick={() => {
                                    this.props.sendEditData();
                                    this.props.onTabChange(1);
                                }}
                            >
                                <p className="text-xl">Add New Template</p>
                                <AddIconInButtonFull width={20} height={20} color={'#E7E3E3'} />
                            </Button>
                        </div>
                    </div>
                    <div className="grid doubts-tabs">
                        {this.state.timetables.map((table, index) => {
                            return (
                                <div key={index} className="mx-6 card cursor-pointer col-3 doubts-tab-w" style={{ height: '170px', width: '320px' }}>
                                    <div className="flex flex-column justify-content-center align-items-center ml-3">
                                        <div className="mt-1 w-full flex justify-content-end align-items-center">
                                            <div className="-mt-1 mr-3 w-full gap-3 flex justify-content-end align-items-center">
                                                <div>
                                                    <InputSwitch className="mt-1" onChange={(e) => this.toggleChange(e, index)} checked={table.status} />
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        this.handleEditTimeTable(table);
                                                    }}
                                                >
                                                    {/* <Authorizer permId={PERMISSIONS.UPDATE_FEE_STRUCTURE}> */}
                                                    <ClassetEditIcon height={32} width={32} />
                                                    {/* </Authorizer> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex mt-4 ml-3">
                                            <h3 className="Montserrat24 surface-overlay white-space-nowrap overflow-hidden text-overflow-ellipsis">{table.title}</h3>
                                        </div>
                                        <div className="flex justify-content-center mr-5">
                                            <p className="poppins24" style={{ fontSize: '24px' }}>
                                                {table.studentsCount || 0} Students
                                            </p>
                                        </div>
                                        <div style={{ marginLeft: 'auto' }} className="-mt-5 d-flex mr-3 justify-content-end align-items-center">
                                            <AddUserIcon width={32} height={32} color={'#000000'} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {this.state.isLoading && <LoadingComponent />}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </div>
        );
    }
}
