import React, { Component } from 'react'
import { getBoardsData, getBranchesLatest } from '../../../store/actions';
import Service from '../../services';
import Authentication from '../../session';
import withRouter from '../../lib/withRouter';
import { connect } from 'react-redux';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { AddIcon2, BrowseIcon, CalenderCheckIcon, ClockIcon, MailIcon } from '../../svgIcons';
import { getFormFields, isFormValid, onChoiceChange, onDropDownChange, onTextChange } from '../../../utile/formHelper';
import ClassetInputText from '../../../classetComponents/classetInputText';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../loadingComponent';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import moment from 'moment';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import { paymentModes, StaticConstants } from '../../../constants';
import { Dialog } from 'primereact/dialog';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import { convertUtc, trimObj } from '../../../utile';
import FeeReciept from '../receipt'
import FileUpload from '../../discussionBoard/common/fileUpload';
const createExamFields = require('./examCreate.json');
const createFields = require('./collectFee.json');
const formFields = createExamFields;

let selectedExamType = [
    {
        "label": "Offline",
        "value": "offline",
        "isActive": true,
        "isSelected": false
    },
    {
        "label": "Online",
        "value": "online",
        "isActive": true,
        "isSelected": false
    }]

class ExamCreate extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createExamFields);
        this.payformFields = getFormFields(createFields);
        this.state = {
            isLoading: false,
            exam: this.props.editExam?._id ? this.props.editExam : this.formFields.data,
            formValidations: this.formFields.formValidations,
            payFeeFormData: this.payformFields.data,
            payFee: this.payformFields.data,
            payFormValidations: this.payformFields.formValidations,
            allotExam: "",
            selectPaper: "",
            isExamFeePaid: false,
            isEdit: this.props?.editExam?._id ? true : false,
            date: null,
            isShowReceipt: false,
            receiptInfo: {}

        }
        this.service = new Service();
    }

    async componentDidMount() {
        if (this.props.editExam?._id) {
            this.setState((prevState) => ({
                exam: { ...prevState.exam, ...prevState.exam?.exam },
                date: prevState.exam?.exam?.date,
                time: prevState.exam?.exam?.time,
                examType: prevState.exam?.exam?.examType,
                allotExam: prevState.exam?.exam?.allotExam,
                selectPaper: prevState.exam?.exam?.selectPaper,
                score: prevState.exam?.exam?.score,
                remarks: prevState.exam?.exam?.remarks,
            }), async () => {
                await this.handlePayFee(false)
            })
        }
        const applicationId = await localStorage.getItem('applicationId')
        this.props.getBranchesLatest()
        this.props.getBoardsData(true);
        await this.getAdmissionTypeDropdown()
        await this.getAcademicSessions()
        if (applicationId) {
            await this.getAdmissionInfo(applicationId)
        }
        await this.getAdmissionSettingsInfo()
    }


    handlePayFee = async () => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/application-fee/filter`;
        try {
            let payload = {
                "isActive": true,
                "academicYear": this.state.exam?.academicSession,
                "orgId": this.state.exam?.orgId,
                "board": this.state.exam?.board,
                "grade": this.state.exam?.grade,
                "admissionStep": "Exam"
            }
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({ feePayInfo: res?.res?.data, isShowPayFeeButton: (res?.res?.data && Object.keys(res?.res?.data).length) ? true : false, isLoading: false }, () => this.onCheckFeePaidInfo(false));
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }

    };

    getAdmissionInfo = async (applicationId) => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/admissions/form?id=${applicationId}`;
        this.service.get(url, true).then(res => {
            if (res && res.status && res.res.status) {
                if (res.res.data && res.res.data.admissionRecords.length) {
                    this.setState({
                        isLoading: false,
                        exam: res.res.data.admissionRecords[0],
                    }, async () => {
                        await this.handlePayFee(false)

                        localStorage.removeItem('applicationId')
                    });
                }
            } else {
                this.setState({
                    isLoading: true
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
            console.log(e);
        })
    }


    getAcademicSessions = async () => {
        this.setState({
            isLoading: true
        });
        let url = `${baseUrlAdmin}/academic-years/filter`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const result = res?.res?.data?.map(admision => ({
                    label: admision.academicCode,
                    value: admision._id
                }));
                this.setState({ academicSession: result, isLoading: false });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }

    };


    getAdmissionTypeDropdown = async () => {
        this.setState({
            isLoading: true
        });
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/admission-types/active?academicYear=${academicYear}`
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                const result = res?.res?.data?.map(admision => ({
                    label: admision.categoryName,
                    value: admision._id
                }));

                this.setState({ academicCategory: result, isLoading: false });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }

    };

    openDailogPayAppFee = () => {
        if (!this.state?.application?.applicationId) {
            let paymentMode = paymentModes.filter((e) => e.value !== '1c98862e-46dc-4454-a120-7d15e2e56e2f' && e.value !== '9d08ebab-2d3e-4a30-8460-4e4092fe30a1')
            this.setState({ isOpenPayFeeDailog: true, paymentModes: paymentMode })
        }
    }

    onHandleCancelPayFee = () => {
        this.setState({
            isOpenPayFeeDailog: false,
            payFeeFormData: {}
        })
    }
    _afterFilesUploaded = (files) => {

    }
    addAttachment = (fieldName, fileName, data) => {
        let allotExam = {
            [fieldName]: {
                fileName,
                storageBucket: data.Bucket,
                storageKey: data.Key,
                storageLocation: data.Location,
            }

        }
        this.setState((prevState) => {
            return { allotExam, uploadedFileName: fileName }
        });
    }

    _onUploadPdf = (fileName, data) => {
        this.addAttachment('allotExam', fileName, data);
    }

    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    getAdmissionSettingsInfo = async () => {
        this.setState({
            isLoading: true
        });
        let academicYear = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/admissions/process?academicYear=${academicYear}`;
        try {
            const res = await this.service.get(url, true);
            if (res?.res?.status && res.status) {
                this.setState({ admissionSettingsInfo: res?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.toString(), life: 3000 });
        }
    }


    OnPayFee = () => {
        const formStatus = isFormValid(createFields, this.payformFields.formValidations, trimObj(this.state.payFeeFormData));
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                payFormValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        let academicYear = localStorage.getItem('userAcademicYear')
        let data = this.state.payFeeFormData
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/admissions/payFee`
        let paymentMode = this.state.paymentModes.find(el => el.value == data?.paymentType)?.label
        data.paymentMode = paymentMode
        data.FeeTypeName = this.state.feePayInfo?.feeName
        data.feeAmount = Number(this.state.feePayInfo?.amount)
        data.transctionId = this.state?.transctionId

        if (data?.paymentType === '1b1a76b4-c538-4968-a20b-0e5a3b4a1c54' || data?.paymentType === 'b9e46415-1b11-4377-9105-58b98c230935' || data?.paymentType === '274a4416-2454-4f3a-b741-89c67361ae1f' || data?.paymentType === '32b180cf-7727-46ef-b2a8-e7b1b19fd489') {
            if (!data?.transctionId) {
                this.setState({
                    isLoading: false,
                });
                this.toast.show({ severity: 'error', summary: 'Please enter transaction id.', detail: "Please enter transaction id.", life: 3000 });
                return
            }
        } else {
            data.transctionId = null
        }
        let payload = { ...data }
        if (this.state?.exam?._id) {
            payload.academicYearCode = this.state.exam?.enquiry?.academicYearCode
            payload.admissionCategoryName = this.state.exam?.enquiry?.admissionCategoryName
            payload.studentName = `${this.state?.exam?.firstName} ${this.state?.exam?.lastName}`
            payload.applicationNo = this.state?.exam?.applicationId
            payload.admissionId = this.state?.exam?._id
            payload.academicYear = this.state?.exam?.academicSession
            payload.gradeName = this.state.exam?.gradeName
            payload.stage = "Exam"
        }
        if (isCompleteFormValid) {
            this.service.post(url, payload, true).then(res => {
                if (res && res.status && res.res.status) {
                    if (res.res.data) {
                        this.setState({
                            isOpenPayFeeDailog: false,
                            isLoading: false,
                            payFeeFormData: {},
                            transctionId: null
                        }, () => { this.onCheckFeePaidInfo(true) });
                        this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res?.errMessage, life: 3000 });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
            })
        } else {
            this.setState({
                isLoading: false,
            });
            this.toast.show({ severity: 'error', summary: 'Please fill all the required fields.', detail: "Please fill all the required fields.", life: 3000 });
        }

    }

    onHandleNavigate = (isContinue) => {
        isContinue ? this.props.navigate('/admission-exam') : this.setState({ isEdit: false }, () => this.props.onHandleClose())

    }

    onSubmitExam = async (isContinue) => {
        const { registration, enrollment } = this.state?.admissionSettingsInfo

        if (!this.state.isExamFeePaid) {
            this.toast.show({ severity: 'error', summary: 'Please Pay Exam Fee', detail: 'Please Pay Exam Fee', life: 3000 });
            return
        }
        const formStatus = isFormValid(createExamFields, this.formFields.formValidations, trimObj(this.state.exam));
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        if (!this.state.examType && !this.state.date && !this.state.time && (!this.state.allotExam || !this.state.selectPaper)) {
            isCompleteFormValid = false;
            this.toast.show({ severity: 'error', summary: 'Please Enter all the required Fields', detail: 'Please Enter all the required Fields', life: 3000 });
        }
        this.setState({
            isLoading: true
        });
        const academicSession = localStorage.getItem('userAcademicYear')
        if (isCompleteFormValid) {
            let payload = {}
            payload._id = this.state.exam?._id
            payload.examType = this.state.examType
            payload.orgId = this.state.exam?.orgId
            payload.academicSession = academicSession
            payload.applicationId = this.state.exam?.applicationId
            payload.date = this.state.date ? convertUtc(this.state.date) : convertUtc(new Date())
            payload.time = this.state.time ? this.state.time : new Date()
            payload.board = this.state.exam?.board
            payload.grade = this.state.exam?.grade
            payload.score = this.state?.score || null
            payload.remarks = this.state?.remarks || null
            payload.stage = "Exam"
            if (payload.examType === "offline") {
                payload.allotExam = this.state.allotExam?.allotExam
            } else {
                payload.selectPaper = this.state.selectPaper
            }
            const url = `${baseUrlAdmin}/admissions/add-exam`
            this.service.post(url, payload, true).then(res => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        createdId: res?.res?.data,
                        isEdit: false
                    }, () => { this.onHandleNavigate(isContinue) });
                    this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });

                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }
            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
                this.toast.show({ severity: 'error', summary: "Error", detail: 'Some error occured', life: 3000 });
            })
        }
    }

    onCheckFeePaidInfo = async (isContinue) => {
        const url = `${baseUrlAdmin}/admissions/feePaidInfo?_id=${this.state.exam?._id}`
        this.service.get(url, true).then(res => {

            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    receiptInfo: res?.res?.data,
                }, () => this.handleShowReceipt(isContinue));
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }

    handleShowReceipt = (isContinue) => {
        const { receiptInfo } = this.state

        if (!isContinue) {
            this.setState({
                isExamFeePaid: receiptInfo?.isExamFeePaid || false
            })
        }
        else {
            let receiptData = {
                receiptName: this.state.feePayInfo?.label,
                receiptNo: receiptInfo?.exam?.receiptNo,
                receiptDate: receiptInfo?.exam?.date,
                studentName: receiptInfo?.studentName,
                academicYear: receiptInfo?.academicYearCode,
                className: receiptInfo?.exam?.gradeName,
                paymentMode: receiptInfo?.exam?.paymentMode,
                feePaidDetails: [
                    {
                        feeType: receiptInfo?.exam?.FeeTypeName,
                        amountPaying: receiptInfo?.exam?.feeAmount,
                    },
                ],
                amountPaid: receiptInfo?.exam?.feeAmount


            }
            this.setState({
                isShowReceipt: true,
                receiptInfo: receiptData,
                isExamFeePaid: receiptInfo?.isExamFeePaid || false
            })
        }
        if (!this.state.isShowPayFeeButton) {
            this.setState({
                isExamFeePaid: true
            })
        }
    }

    render() {
        const { exam, formValidations, payFeeFormData, payFormValidations } = this.state;
        return (
            <>
                <div>
                    <div className="grid  md:col-12 lg:rid-col-12 mx-5 column-gap-8 " >
                        {exam?.applicationId && <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Application ID<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='  add-vehicle-field-input w-full '
                                    value={exam.applicationId}
                                    onChange={(e) => onTextChange(e.target.value, 'applicationId', this, formFields, exam, formValidations, 'exam', 'formValidations')}
                                    placeholder="Application ID"
                                    disabled={true} />
                            </div>
                            {formValidations && !formValidations.fields['applicationId'].isValid && <p className="p-error text-lg">{formValidations.fields['applicationId'].errorMsg}</p>}

                        </div>}
                        {/* <div className="col-span-1">
                            <p className='transport-fee-form-label'>Academic Session<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={exam.academicSession}
                                    options={this.state.academicSession}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'academicSession', this, formFields, exam, formValidations, 'exam', 'formValidations') }}
                                    placeholder='Select Academic Session'
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['academicSession'].isValid && <p className="p-error text-lg">{formValidations.fields['academicSession'].errorMsg}</p>}

                        </div> */}
                        <div className="col-span-1">
                            <p className='transport-fee-form-label'>Admission Category<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel='label'
                                    optionValue='value'
                                    className='text-xl add-vehicle-field-input w-full'
                                    value={exam.academicCategory}
                                    options={this.state.academicCategory}
                                    onChange={(e) => { onDropDownChange(e.target.value, 'academicCategory', this, formFields, exam, formValidations, 'application', 'formValidations') }}
                                    placeholder='Admission Category'
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['academicCategory'].isValid && <p className="p-error text-lg">{formValidations.fields['academicCategory'].errorMsg}</p>}

                        </div>
                    </div>
                    <div className="grid mt-2 md:col-12 lg:col-12 mx-5 column-gap-8 row-gap-5" >

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Student First Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='  add-vehicle-field-input w-full '
                                    value={exam.firstName}
                                    width={250}
                                    onChange={(e) => onTextChange(e.target.value, 'firstName', this, formFields, exam, formValidations, 'exam', 'formValidations')}
                                    placeholder="Student First Name"
                                    disabled={true}
                                />
                            </div>
                            {formValidations && !formValidations.fields['firstName'].isValid && <p className="p-error text-lg">{formValidations.fields['firstName'].errorMsg}</p>}

                        </div>
                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Student Last Name<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    // id="title"
                                    className='  add-vehicle-field-input w-full '
                                    width={250}
                                    value={exam.lastName}
                                    onChange={(e) => onTextChange(e.target.value, 'lastName', this, formFields, exam, formValidations, 'exam', 'formValidations')}
                                    placeholder="Student Last Name" disabled={true} />
                            </div>
                            {formValidations && !formValidations.fields['lastName'].isValid && <p className="p-error text-lg">{formValidations.fields['lastName'].errorMsg}</p>}
                        </div>

                        <div className="col-span-1 ">
                            <p className='transport-fee-form-label'>Contact no<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={exam.contactNo}
                                    width={250}
                                    keyfilter='int'
                                    onChange={(e) => onTextChange(e.target.value, 'contactNo', this, formFields, exam, formValidations, 'exam', 'formValidations')}
                                    placeholder="Contact no" disabled={true} />
                            </div>
                            {formValidations && !formValidations.fields['contactNo'].isValid && <p className="p-error text-lg">{formValidations.fields['contactNo'].errorMsg}</p>}

                        </div>

                        <div className="col-span-1  ">
                            <p className='transport-fee-form-label'>Email ID<span className='ma-required'>*</span></p>
                            <div className='flex'>
                                <ClassetInputText
                                    icon={<MailIcon width={24} height={24} color={"black"} />}
                                    className='  add-vehicle-field-input w-full '
                                    value={exam.emailId}
                                    onChange={(e) => onTextChange(e.target.value, 'emailId', this, formFields, exam, formValidations, 'exam', 'formValidations')}
                                    width={250}
                                    placeholder="Email ID" disabled={true} />
                            </div>
                            {formValidations && !formValidations.fields['emailId'].isValid && <p className="p-error text-lg">{formValidations.fields['emailId'].errorMsg}</p>}

                        </div>
                    </div>
                    <div className='flex justify-content-between mx-5 mt-2 '>

                        <div className='flex align-items-center' >
                            {selectedExamType.map((tab, index) => (
                                <div key={index} >
                                    <p className='px-3 py-3 inter500semibold' style={{ margin: 0, }}>{tab.label}
                                        <RadioButton inputId="examType"
                                            name="examType"
                                            id='examType'
                                            value={tab.value}
                                            style={{ marginLeft: "150px", marginRight: "80px" }}
                                            onChange={(e) => this.setState({ examType: e.value })} checked={this.state.examType === tab.value}
                                        />
                                    </p>
                                </div>
                            ))}
                        </div>
                        {this.state.isShowPayFeeButton && <div className='col-1  sm:col-1  flex justify-content-end align-items-center  custom-fee'>
                            <Button className='add-vehicle-btn' style={{ padding: "20px", }} disabled={this.state.isExamFeePaid ? true : false} onClick={() => this.openDailogPayAppFee()}>
                                Pay Fee
                            </Button>
                        </div>}

                    </div>

                    {this.state.examType === "offline" &&
                        <div className="grid mt-2 md:col-12 lg:col-12 mx-5 column-gap-8 row-gap-5" >

                            <div className="col-span-1 ">
                                <p className='transport-fee-form-label'>Exam Date<span className='ma-required'>*</span></p>
                                <div className='flex'>
                                    <ClassetCalendar
                                        width={250}
                                        icon={<CalenderCheckIcon width={24} height={24} color={"black"} />}
                                        className=' border-round-md add-vehicle-field-input text-xl w-full'
                                        calendarMode={'single'}
                                        value={this.state.date ? new Date(this.state.date) : new Date()}
                                        onChange={(e) => { this.setState({ date: e.value }) }}
                                        placeholder="DD/MM/YYYY" />
                                </div>
                            </div>

                            <div className="col-span-1 ">
                                <p className='transport-fee-form-label'>Exam Time<span className='ma-required'>*</span></p>
                                <div className='flex'>
                                    <ClassetCalendar
                                        width={250}
                                        icon={<ClockIcon height={20} />}
                                        className=""
                                        calendarMode="single"
                                        value={this.state.time ? new Date(this.state.time) : new Date()}
                                        onChange={(e) => this.setState({ time: e.target.value })}
                                        minDate={new Date()}
                                        timeOnly
                                        placeholder="HH:MM"
                                    />
                                </div>

                            </div>

                            <div className="col-span-1 ">
                                <p className='transport-fee-form-label'>Allot Question Paper<span className='ma-required'>*</span></p>
                                <div className='flex'>
                                    <FileUpload
                                        id={"allotExam"}
                                        //multiple={false}
                                        onProgress={this._onProgress}
                                        onSucess={this._onUploadPdf}
                                        // Reset={this.state.Reset}
                                        //maxFileSize={307200}
                                        //onError={this._onFilsUploadError}
                                        maxFileSize={StaticConstants.MAX_FILE_SIZE}
                                        onError={this._onFilsUploadError}
                                        accept={'pdfs'}
                                        title={'Pdfs and Docs'}
                                        afterFilesUploaded={this._afterFilesUploaded} >
                                        <span className="button-style">Uplaod</span>

                                    </FileUpload>

                                    {(this.state.allotExam?.allotExam || true) && (
                                        <div className="uploaded-file-name " style={{ marginTop: "-1px" }}>
                                            Uploaded File: {this.state?.uploadedFileName || this.state.allotExam?.fileName}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {this.props.editExam?._id &&
                                <div className='flex flex-row column-gap-8'>
                                    <div className="col-span-1 ">
                                        <p className='transport-fee-form-label'>Score<span className='ma-required'>*</span></p>
                                        <div className='flex'>
                                            <ClassetInputText
                                                icon={<BrowseIcon width={24} height={24} color={"black"} />}
                                                className='  add-vehicle-field-input w-full '
                                                value={this.state.score}
                                                width={250}
                                                keyfilter="int"
                                                onChange={(e) => this.setState({ score: e.target.value })}
                                                placeholder="Enter Score" />
                                        </div>
                                    </div>


                                    <div className="col-span-1 ">
                                        <p className='transport-fee-form-label'>Remarks<span className='ma-required'>*</span></p>
                                        <div className="flex">
                                            <ClassetTextArea
                                                placeholder='Enter Remarks'
                                                className=""
                                                width={550}
                                                value={this.state.remarks}
                                                onChange={(e) => this.setState({ remarks: e.target.value })}
                                            />
                                        </div>
                                    </div>

                                </div>

                            }
                        </div>

                    }
                    {this.state.examType === "online" &&
                        <div className="grid mt-2 md:col-12 lg:col-12 mx-5 column-gap-8 row-gap-5" >

                            <div className="col-span-1 ">
                                <p className='transport-fee-form-label'>Exam Date<span className='ma-required'>*</span></p>
                                <div className='flex'>
                                    <ClassetCalendar
                                        width={250}
                                        icon={<CalenderCheckIcon width={24} height={24} color={"black"} />}
                                        className=' border-round-md add-vehicle-field-input text-xl w-full'
                                        calendarMode={'single'}
                                        value={this.state.date ? new Date(this.state.date) : new Date()}
                                        onChange={(e) => { this.setState({ date: e.value }) }}
                                        placeholder="DD/MM/YYYY" />
                                </div>
                            </div>

                            <div className="col-span-1 ">
                                <p className='transport-fee-form-label'>Exam Time<span className='ma-required'>*</span></p>
                                <div className='flex'>
                                    <ClassetCalendar
                                        width={250}
                                        icon={<ClockIcon height={20} />}
                                        className=""
                                        calendarMode="single"
                                        value={this.state.time ? new Date(this.state.time) : new Date()}
                                        onChange={(e) => this.setState({ time: e.target.value })}
                                        minDate={new Date()} timeOnly
                                        placeholder="HH:MM"
                                    />
                                </div>
                            </div>

                            <div className="col-span-1 ">
                                <p className='transport-fee-form-label'>Select Paper<span className='ma-required'>*</span></p>
                                <div className='flex'>
                                    <ClassetInputText
                                        icon={<BrowseIcon width={24} height={24} color={"black"} />}
                                        className='  add-vehicle-field-input w-full '
                                        value={this.state.selectPaper}
                                        width={250}
                                        onChange={(e) => this.setState({ selectPaper: e.target.value })}
                                        placeholder="Select Paper" />
                                </div>


                            </div>
                        </div>

                    }

                    <div className="text-center mt-8 ">
                        {/* <Button icon={'pi pi-plus-circle text-lg '} label='Save and Proceed to Next Step' onClick={this.onSubmitApplication} className='confirmDialogAcceptBtn  mr-5 w-24rem ' /> */}
                        <Button icon={'pi pi-plus-circle text-lg '} label={!this.state.isEdit ? 'Save' : 'Update'}
                            onClick={() => !this.state.isEdit ? this.onSubmitExam(true) : this.onSubmitExam(false)}
                            className='confirmDialogAcceptBtn  mr-5 w-18rem p-5' />
                        <Button label='Cancel' className='confirmDialogAcceptBtn p-5' onClick={() => !this.state.isEdit ? this.props.navigate('/admission-exam') : this.setState({ isEdit: false, exam: this.formFields.data }, () => this.props.onHandleClose())} />

                    </div>
                </div>

                <Dialog
                    isopen={this.state.isOpenPayFeeDailog}
                    visible={this.state.isOpenPayFeeDailog}
                    className="pay-fee"
                    style={{ position: 'fixed', right: '0' }}
                    draggable={false}
                    onHide={this.onHandleCancelPayFee}
                    closable={false}
                    header={() => {
                        return (<div className='mt-2 mb-4'>
                            <h3 className="formhead text-center ">Pay Fee</h3>

                        </div>)
                    }}
                >
                    <div className="mb-5 ml-5 mr-5">
                        <div>
                            <div className="col-12">
                                <div className="mt-2">
                                    <ClassetInputText
                                        id='term'
                                        className=""
                                        height="48px"
                                        width="368px"
                                        placeholder="Fee Type name"
                                        value={`${this.state.feePayInfo?.feeName || ""}`}
                                        disabled={true}
                                        onChange={(e) => onTextChange(e.target.value, 'FeeTypeName', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations)}
                                    />
                                </div>
                                {payFormValidations && !payFormValidations.fields['FeeTypeName'].isValid && <p className="p-error">{payFormValidations.fields['FeeTypeName'].errorMsg}</p>}
                            </div>

                            <div className="col-12">
                                <div className="mt-2">
                                    <ClassetInputText
                                        id='feeAmount'
                                        className=""
                                        height="48px"
                                        width="368px"
                                        placeholder="Fee Amount"
                                        disabled={true}
                                        value={`${Number(this.state.feePayInfo?.amount) || 0}`}
                                        onChange={(e) => onTextChange(e.target.value, 'feeAmount', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations)}

                                    />
                                </div>
                                {payFormValidations && !payFormValidations.fields['feeAmount'].isValid && <p className="p-error">{payFormValidations.fields['feeAmount'].errorMsg}</p>}
                            </div>
                            <div className="col-12">
                                <div className="mt-2">
                                    <ClassetDropdown
                                        id='paymentType'
                                        className=""
                                        height="48px"
                                        width="368px"
                                        options={this.state.paymentModes}
                                        value={payFeeFormData.paymentType}
                                        onChange={(e) => { onDropDownChange(e.target.value, 'paymentType', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations) }}
                                        placeholder={(
                                            <div>
                                                <span className=''>Payment Type</span>
                                            </div>
                                        )}
                                    />
                                </div>
                                {payFormValidations && !payFormValidations.fields['paymentType'].isValid && <p className="p-error">{payFormValidations.fields['paymentType'].errorMsg}</p>}
                            </div>
                            {(payFeeFormData.paymentType === '1b1a76b4-c538-4968-a20b-0e5a3b4a1c54' || payFeeFormData.paymentType === 'b9e46415-1b11-4377-9105-58b98c230935' || payFeeFormData.paymentType === '274a4416-2454-4f3a-b741-89c67361ae1f' || payFeeFormData.paymentType === '32b180cf-7727-46ef-b2a8-e7b1b19fd489') &&
                                <div className="col-12">
                                    <label className='label mb-2 '>Transaction Id<span className='ma-required'>*</span></label>
                                    <div className="mt-2">
                                        <ClassetInputText
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Enter Transaction Id"
                                            value={this.state.transctionId}
                                            onChange={(e) => this.setState({ transctionId: e.target.value })}

                                        />
                                    </div>

                                </div>
                            }
                            {payFeeFormData.paymentType &&
                                <div className="col-12">
                                    <label className='label mb-2 '>Remarks<span className='ma-required'></span></label>
                                    <div className="mt-2">
                                        <ClassetTextArea
                                            id='feeType'
                                            className=""
                                            height="48px"
                                            width="368px"
                                            placeholder="Enter Remarks"
                                            value={payFeeFormData.remarks}
                                            onChange={(e) => onTextChange(e.target.value, 'remarks', this, createFields, payFeeFormData, payFormValidations, 'payFeeFormData', payFormValidations)}

                                        />
                                    </div>

                                </div>
                            }
                            <div className='flex justify-content-center mb-4 mt-4'>

                                <Button className='formSubmitButtonn mr-2' style={{ borderRadius: '12px', fontSize: '16px', backgroundColor: '#076EFF' }}
                                    onClick={this.OnPayFee}
                                >
                                    <AddIcon2 />
                                    <p className='formSubmitButtonnText'>Pay Now</p>
                                </Button>
                                <Button className='cancelButtonn w-4'
                                    onClick={this.onHandleCancelPayFee} >

                                    <p className='cancelButtonnText'>Cancel</p>
                                </Button>
                            </div>
                        </div>
                    </div>


                </Dialog>
                {
                    this.state.isShowReceipt && <FeeReciept receiptData={this.state?.receiptInfo} hideFeeReciept={() => {
                        this.setState({
                            isShowReceipt: false,
                            receiptInfo: {}
                        })

                    }} />
                }
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>

        )
    }
}
const mapStatesToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(Authentication(withRouter(ExamCreate)));