import React, { Component } from 'react'

import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
//import Navbar from './../navbar';
import { Button } from 'primereact/button';

import SvgViewer from '../customComponents/svgViewer';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
// import SearchBar from './searchBar';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import './styles.scss'
// import UserInfo from './userInfo';
// import CreateUser from './createUser';
import Authentication from '../session';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { BasicLazyParams, convertUtc, warningDailogInit } from '../../utile';
import IndividualUsageAnalytics from '../analytics/usageAnalytics/individualUsageAnalytics';
import { AnalyticsIcon2, BoardsIconInActive, CalenderCheckIcon, MailIcon, WarningIcon } from '../svgIcons';
import ReactApexChart from 'react-apexcharts';
import { I18Next } from '../../internationalization';
import withRouter from '../lib/withRouter';
import InputTextB from '../customComponents/inputTextB';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { getBoardsData, getBranchesLatest } from '../../store/actions';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';


class AgeCriteriaTable extends Component {

    constructor(props) {

        super(props);
        this.state = {
            isLoading: false,
            ageCriteriaTableData: [],
            selectedUser: null,
            isCalenderDialogOpen: false,
            updateDateType: "",
            updatedToRow: {},
            isDatesChanged: false,
            branchId: "",
            boards: [],
            boardId: "",
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams,


        }

        this.service = new Service();
    }


    componentDidMount() {
        this.onChangeBranch(this.props.branchData[0]?.key)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.branchId !== this.state.branchId) {
            this.setState({ boardId: this.state.boards[0]?.boardId, boardId: "" }, () => this.onChangeBoard(this.state.boards[0]?.boardId))
        }
    }

    onSelectDateChangeForBornAfter = (rowData, rowIndex, type) => {
        this.setState({
            isCalenderDialogOpen: !this.state.isCalenderDialogOpen,
            updatedToRow: { ...rowData, currentIndex: rowIndex, datetype: type },
            depositeDate: type == "Born After" ? new Date(rowData?.bornAfter) : new Date(rowData?.bornBefore),
            
        });
    }


   /*  onAssignDatesToParticularGrade = () => {
        const { updatedToRow, depositeDate } = this.state

        

        const updatedAgeCriteriaData = this.state.ageCriteriaTableData.map((each, index) => {
            if (index == updatedToRow?.currentIndex) {
                if (updatedToRow?.datetype == "Born Before") {
                    this.setState({ isDatesChanged: true })
                    return { ...each, bornBefore: convertUtc(depositeDate) }
                }
                else if (updatedToRow?.datetype == "Born After") {
                    this.setState({ isDatesChanged: true })
                    return { ...each, bornAfter:  convertUtc(depositeDate) }
                }
            }
            else {
                return each;
            }
        })

        this.setState({ ageCriteriaTableData: updatedAgeCriteriaData, isCalenderDialogOpen: false })


    } */
        onAssignDatesToParticularGrade = () => {
            const { updatedToRow, depositeDate, ageCriteriaTableData } = this.state;

            const convertedDepositDate = depositeDate ? convertUtc(depositeDate) : convertUtc(new Date());
        
            const updatedAgeCriteriaData = this.state.ageCriteriaTableData.map((each, index) => {
                if (index === updatedToRow?.currentIndex) {
                    const currentBornBefore = each.bornBefore;
                    const currentBornAfter = each.bornAfter;
        
                    // Validation logic
                    if (updatedToRow?.datetype === "Born After" && currentBornBefore && convertedDepositDate > currentBornBefore) {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: "Born After date must be less than Born Before date.", life: 3000 });
                        console.error("Born After date must be less than Born Before date.");
                        return each; // Return the unchanged object
                    }
        
                    if (updatedToRow?.datetype === "Born Before" && currentBornAfter && convertedDepositDate < currentBornAfter) {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: "Born Before date must be greater than Born After date.", life: 3000 });
                        console.error("Born Before date must be greater than Born After date.");
                        return each; // Return the unchanged object
                    }
        
                    // Proceed with updating the date
                    this.setState({ isDatesChanged: true });
                    if (updatedToRow?.datetype === "Born Before") {
                        return { ...each, bornBefore: convertedDepositDate };
                    } else if (updatedToRow?.datetype === "Born After") {
                        return { ...each, bornAfter: convertedDepositDate };
                    }
                }
                return each; // Return unchanged objects for other rows
            });
        
            this.setState({ ageCriteriaTableData: updatedAgeCriteriaData, isCalenderDialogOpen: false });
        }
        
    getAgeCriteriaData = async (boardId) => {
        const { branchId } = this.state
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/admissions/agecriteria?boardId=${boardId}&branchId=${branchId}`;
        try {
            const res = await this.service.get(url, true);
            console.log(res, "res345")
            if (res?.res?.status && res.status) {
                this.setState({ ageCriteriaTableData: res?.res?.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }

    };




    onSaveAllAssigendDatesToGrades = async () => {
        const { branchId, boardId, ageCriteriaTableData } = this.state
        const payload = ageCriteriaTableData?.map(each => {
            return { boardId: boardId, branchId: branchId, gradeId: each.classId, bornAfter: each.bornAfter, bornBefore: each.bornBefore }
        })
        this.setState({ isLoading: true })
        let url = `${baseUrlAdmin}/admissions/agecriteria`;
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                console.log(res?.res?.data, "res345")
                this.setState({ isLoading: false, isDatesChanged: false });
                this.getAgeCriteriaData(boardId)
                this.toast.show({ severity: 'success', summary: "Assigned dates have been successfully saved.", detail: "", life: 3000 });
            } else {
                console.log(res, "res34");
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.errMessage, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }

    }



    onChangeBranch = (branchId) => {
        let selectedbranchName = this.props.branchData?.find((branchd) => branchd?.key == branchId)?.name;

        let filteredBoards = this.props.boards?.filter(board => board?.assignedBranches?.includes(branchId));


        this.setState({
            branchId: branchId,
            boardId: '',
            selectedbranchName: selectedbranchName,
            boards: filteredBoards
        });
    };
    onChangeBoard = (boardId) => {

        const selectedBoard = this.state.boards && this.props.boards?.find((board) => board.boardId === boardId);

        this.setState({
            classes: selectedBoard?.classes,
            boardId,
            selectedBoardName: selectedBoard?.boardName
        }, () => this.getAgeCriteriaData(boardId));
    };
    render() {
        return (
            <>
                <div className='flex justify-content-between'>
                    <div className="grid flex ml-2 mt-3 md:col-6 lg:col-12 xl:col-12">
                        <div className=" col-3">
                            <ClassetDropdown required={true} label={'Branch'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Branch'} options={this.props.branchData}
                                value={this.state.branchId} onChange={(e) => this.onChangeBranch(e.value)} optionLabel="name"
                                optionValue="key" />
                        </div>
                        <div className=" col-3">
                            <ClassetDropdown required={true} label={'Board'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Board'} options={this.state.boards} value={this.state.boardId} onChange={(e) => this.onChangeBoard(e.value)} optionLabel="boardName"
                                optionValue="boardId" />
                        </div>
                        <Authorizer permId={PERMISSIONS.UPDATE_AGE_CRITERIA} >
                            <div className="xl:-ml-3 col-5 mt-4 flex justify-content-end align-items-center">
                                {(this.state.boardId && this.state.branchId && this.state.isDatesChanged) &&
                                    <Button
                                        label="Save"
                                        icon={<i className='pi pi-download text-xl ml-3 -mr-3'></i>}
                                        className='ageCriteriaSaveBtn'
                                        onClick={() => this.onSaveAllAssigendDatesToGrades()}
                                    />}
                            </div>
                        </Authorizer>

                    </div>
                </div>
                <div className="  ">
                    {!this.state.isLoading && this.state.ageCriteriaTableData.length ?
                        <DataTable ref={(el) => this.dt = el}
                            //  lazy
                            className="dataTableValuesText"
                            columnResizeMode="expand"
                            value={this.state.ageCriteriaTableData}
                            dataKey="id"
                            paginator
                            responsiveLayout="scroll"
                            rows={10}
                            showGridlines
                            resizableColumns
                            // first={this.state.lazyParams.first}
                            // last={this.state.ageCriteriaTableData?.length}
                            // rows={this.state.lazyParams.rows}
                            totalRecords={this.state.ageCriteriaTableData.length}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                           globalFilter={this.state.globalFilter}
                        >
                            <Column style={{ width: "5rem", height: "6rem" }} sortable headerClassName="text-color-secondary" bodyClassName='text-center' alignHeader={"center"} className=' ' header="Sl No" body={(rowData, { rowIndex }) => {
                                return (
                                    <span>{rowIndex + 1}</span>
                                )
                            }} ></Column>
                            <Column style={{ width: "10rem", height: "6rem" }} sortable headerClassName="text-color-secondary" bodyClassName='text-center' alignHeader={"center"} filterField="className" field="className" header="Grades" />
                            <Column style={{ width: "18rem", height: "6rem" }} sortable headerClassName="text-color-secondary" bodyClassName='text-center' alignHeader={"center"} className=' ' header="Born After" body={(rowData, { rowIndex }) => {
                                return (
                                    <div className='flex justify-content-center align-items-center cursor-pointer' onClick={() => { this.onSelectDateChangeForBornAfter(rowData, rowIndex, "Born After"); }}>
                                        <CalenderCheckIcon width={16} height={16} color={'black'}  />
                                        <p className='ml-2'>{ !rowData.bornAfter ? '--/--/----' : new Date(rowData.bornAfter).toLocaleDateString('en-GB')}</p>
                                    </div>
                                )
                            }} ></Column>
                            <Column style={{ width: "18rem", height: "6rem" }} sortable headerClassName="text-color-secondary" bodyClassName='text-center' alignHeader={"center"} className=' ' header="Born Before" body={(rowData, { rowIndex }) => {
                                return (
                                    <div className='flex justify-content-center align-items-center cursor-pointer' onClick={() => { this.onSelectDateChangeForBornAfter(rowData, rowIndex, "Born Before"); }}>
                                        <CalenderCheckIcon width={16} height={16} color={'black'}  />
                                        <p className='ml-2'>{new Date(rowData.bornBefore).toLocaleDateString('en-GB') == "Invalid Date" ? '--/--/----' : new Date(rowData.bornBefore).toLocaleDateString('en-GB')}</p>
                                    </div>
                                )
                            }} ></Column>

                        </DataTable> : ""}
                </div>
                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                <Dialog
                    blockScroll={true}
                    visible={this.state.isCalenderDialogOpen}
                    draggable={false}
                    closeOnEscape={false}
                    className='calenderBoxSize'
                    dismissableMask={false}
                    closable={false}
                    footer={() => (
                        <div className='flex justify-content-center mb-3'>
                            <Button label="Cancel" className='calenderCancelBtn mr-5 px-7' onClick={() => { this.setState({ isCalenderDialogOpen: false, depositeDate: "" }) }} />
                            <Button label='Apply' className='calenderApplyBtn   px-7' onClick={() => { this.onAssignDatesToParticularGrade(); }} />
                        </div>
                    )}
                    onHide={() => {
                        this.setState({
                            isCalenderDialogOpen: false,
                        })
                    }}>
                    <div className="flex flex-column justify-content-center border-300 border-bottom-1 mt-1">
                        <Calendar
                            className="pb-5"
                            maxDate={new Date()}
                            value={this.state.depositeDate}
                            onChange={(e) => this.setState({ depositeDate: e.value })}
                            inline
                            showIcon={false}
                        />
                    </div>


                </Dialog>
            </>
        )
    }
}
const mapStatesToProps = (state) => ({
    // boards: state.boardsData.boards,
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(AgeCriteriaTable);
