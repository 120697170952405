import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
import Authentication from '../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { BasicLazyParams } from '../../../utile';
import { LocationMarker, MailIcon } from '../../svgIcons';
import withRouter from '../../lib/withRouter';
import CreateRoute from './createRoute';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { getBranchesLatest } from '../../../store/actions';

class RouteManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedUser: null,
            isCreateUser: false,
            dataForAddRoutes: null,
            isShowCreateForm: false,
            editUser: null,
            filterData: null,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams
        };

        this.service = new Service();
    }

    componentDidMount() {}

    onCancel = () => {
        this.setState({
            isShowCreateForm: false
        });
    };

    getRoutesData = async () => {
        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/transport-route/get-routes?academicYear=${academicYear}`;
        let payload = {
            branchId: this.state.branchId,
            academicYear : academicYear
        };
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({ users: res?.res?.data?.routeData, isLoading: false });
            } else {
                this.setState({ isLoading: false, branchId: null });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occurred', life: 3000 });
            }
        } catch (e) {
            this.setState({ isLoading: false, branchId: null });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e, life: 3000 });
        }
    };

    addRoute = (rowData) => {
        this.setState({
            isShowCreateForm: true,
            dataForAddRoutes: rowData
        });
    };
    render() {
        const { selectedUser, isShowAnalytics } = this.state;
        return (
            <>
                <div className="flex justify-content-between  align-items-center lg:mt-4 md:mt-6 mb-2 ">
                    <div className="col-span-1 ">
                        <p className="add-vehicle-field-label">
                            Branch<span className="ma-required">*</span>
                        </p>
                        <div className="flex justify-content-start  align-items-center">
                            <ClassetDropdown
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                optionLabel="name"
                                optionValue="key"
                                width={250}
                                value={this.state.branchId}
                                options={this.props.branchData}
                                onChange={(e) =>
                                    this.setState(
                                        {
                                            branchId: e.target.value
                                        },
                                        () => this.getRoutesData()
                                    )
                                }
                                className="w-full"
                                placeholder={
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className="">Select Branch</span>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <Authorizer permId={PERMISSIONS.CREATE_ROUTE}>
                        <Button
                            icon={'pi pi-plus-circle text-lg px-1 '}
                            className="add-vehicle-btn pl-4"
                            onClick={() => {
                                this.setState({ isShowCreateForm: true, dataForAddRoutes: null });
                            }}
                        >
                            Add Route
                        </Button>
                    </Authorizer>
                </div>

                <div className="card datatable-crud-demo ma-m30  ">
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        //  lazy
                        className="dataTableValuesText"
                        columnResizeMode="expand"
                        value={this.state.users}
                        dataKey="id"
                        paginator
                        // lazy
                        responsiveLayout="scroll"
                        // showGridlines
                        // onPage={this.onPage}
                        // first={this.state.lazyParams.first}
                        // last={this.state.totalRecords}
                        // rows={this.state.lazyParams.rows}
                        totalRecords={this.state?.totalRecords}
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                        header={''}
                    >
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            header="S.No."
                            className="datatable-cell"
                            body={(rowData, index) => {
                                return (
                                    <>
                                        <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                    </>
                                );
                            }}
                        />

                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="vehicleRegistrationNo" field="vehicleRegistrationNo" header="Registration No" />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="routeName" field="routeName" header="Route Name" />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="totalStops" field="totalStops" header="Total Stops" />

                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="startLocation"
                            className=""
                            field="startPoint"
                            header="Start Location"
                        />
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="endLocation"
                            className=""
                            field="endPoint"
                            header="End Location"
                        />
                        <Column
                            className=" "
                            alignHeader={'center'}
                            bodyClassName="text-center"
                            header="Add Stops"
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <>
                                        <div className="flex justify-content-center align-items-center">
                                            <Authorizer permId={PERMISSIONS.ADD_STOPS}>
                                                <span style={{ cursor: 'pointer' }} onClick={() => this.addRoute(rowData)} data-pr-tooltip="Add Stops" data-pr-position="bottom" className={`yh${rowIndex}`}>
                                                    <LocationMarker height={25} width={25} className="ma-icon-svg ma-pointer mr-1 " />
                                                </span>
                                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.yh${rowIndex}`} />
                                            </Authorizer>
                                        </div>
                                    </>
                                );
                            }}
                        ></Column>
                    </DataTable>
                </div>

                <Dialog
                    blockScroll={true}
                    visible={this.state.isShowCreateForm}
                    draggable={false}
                    closeOnEscape={false}
                    className="ma-alert-box"
                    dismissableMask={false}
                    closable={true}
                    style={{
                        width: '90%',
                        height: 'auto',
                        maxHeight: '90%',
                        margin: '0 auto'
                    }}
                    header={() => {
                        return <h2 className="transport-header py-2">{this.state.dataForAddRoutes?._id ?"Update":'Add'} Route</h2>;
                    }}
                    onHide={() => {
                        this.setState({
                            isShowCreateForm: false,
                            newPassword: null
                        });
                    }}
                >
                    <div className="">
                        <CreateRoute onCancel={this.onCancel} getRoutesData={this.getRoutesData} dataForAddRoutes={this.state.dataForAddRoutes} toastMessage={this.toast} />
                    </div>
                </Dialog>

                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        []
});
export default connect(mapStateToProps, {
    getBranchesLatest
})(Authentication(withRouter(RouteManagement)));
