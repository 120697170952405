export const menuItems = [
    {
        label: 'Admissions',
        icon: 'pi pi-building',
        items: [
            { label: 'Enquiry', icon: 'pi pi-id-card', to: '/enquiry' },
            { label: 'Application', icon: 'pi pi-check-square', to: '/admission-application' },
            { label: 'Exam/Test', icon: 'pi pi-bookmark', to: '/admission-exam' },
            { label: 'Registration', icon: 'pi pi-exclamation-circle', to: '/admission-registration' },
            { label: 'Enrollment', icon: 'pi pi-mobile', to: '/admission-enrollment' },
            {
                label: 'Reports',
                icon: 'pi pi-table',
                to: '',
                items: [
                    { label: '# Enquiry Report', to: '' },
                    { label: '# Application Report', to: '' },
                    { label: '# Exam Report', to: '' },
                    { label: '# Registration Report', to: '' },
                    { label: '# Enrollment Report', to: '' },
                    { label: '# Admission Fee Report', to: '' },
                    { label: '# Demographic Report', to: '' },
                    { label: '# Grade Wise Report', to: '' }
                ]
            }
        ]
    },
    { separator: true },
    {
        label: 'Students',
        icon: 'fa fa-graduation-cap',
        items: [
            { label: 'Home', icon: 'pi pi-home', to: '/' },

            { label: 'Student Information', icon: 'pi pi-id-card', to: '/studentInfo' },

            { label: 'Attendance', icon: 'pi pi-check-square', to: '/attendance' },
            { label: 'Student Transfer', icon: 'pi pi-exclamation-circle', to: '/studentTransfer' },
            { label: 'Student Status Change', icon: 'pi pi-exclamation-circle', to: '/studentStatusChange' },

            { separator: true }
        ]
    },
    { separator: true },
    {
        label: 'Finance',
        icon: 'fa fa-money',
        items: [
            { label: 'Collect Fee/Fee Challan', icon: 'pi pi-building', to: '/collectFee' },
            { label: 'Assign Fee', icon: 'pi pi-info-circle', to: '/assignFee' },
            { label: 'Fee Unassign', icon: 'pi pi-bookmark', to: '/UnAssignFee' },
            { label: 'Fee Concession', icon: 'pi pi-info-circle', to: '/feeConcession' },
            { label: 'Fee Refund', icon: 'pi pi-info-circle', to: '/feeRefund' },
            { label: 'Cheque/DD Status', icon: 'pi pi-circle', to: '/chequeOrDDstatus' }
        ]
    },
    { separator: true },
    {
        label: 'Academics',
        icon: 'fa fa-thin fa-book',
        items: [
            { label: 'Academic Calendar', icon: 'pi pi-calendar pi-calendar-clock', to: '/AcademicCalendar' },
            { label: 'Teacher Dairy Template', icon: 'pi pi-check-square', to: '/TeacherDiaryTemplate' },
            { label: 'Teacher Dairy', icon: 'pi pi-bookmark', to: '/TeacherDiary' },
            { label: 'Digital Library', icon: 'pi pi-exclamation-circle', to: '/DigitalLibrary' },
            {
                label: 'Examination',
                icon: 'pi pi-mobile',
                items: [
                    { label: '# Create Test', to: '/CreateTest' },
                    { label: '# Question Bank', to: '/QuestionBank' },
                    // { label: '# Create Test Type', to: '/CreateTestType' },
                    { label: '# Create Template ', to: '/CreateTemplate' },
                    { label: '# Tests & Exams', to: '/TestsandExams' }
                ]
            }
            // {
            //     label: 'Progress Report',
            //     icon: 'pi pi-table',
            //     items: [
            //         { label: '# Grading System ', to: '/GradingSystem ' },
            //         { label: '# Performance Analytics ', to: '/PerformanceAnalytics' },
            //         { label: '# Grade Book Template', to: '/GradeBookTemplate' },
            //         { label: '# Grade Book', to: '/GradeBook' }
            //     ]
            // },
            // { label: 'Approval Hierarchy', icon: 'pi pi-list', to: '/ApprovalHierarchy' },
            // { label: 'Reports', icon: 'pi pi-share-alt', to: '/Reports' }
        ]
    },
    { separator: true },
    {
        label: 'Timetable',
        icon: 'fa fa-clock-o',
        items: [
            { label: 'My Timetable', icon: 'pi pi-id-card', to: '/myTimeTable' },
            { label: 'Request Substitution', icon: 'pi pi-check-square', to: '/requestSubstitution' },
            { label: 'Lag Report', to: '/lagReport', icon: 'pi pi-bookmark' },
            { label: 'TimeTable Reports', icon: 'pi pi-exclamation-circle', to: 'timeTableReports' }
        ]
    },
    { separator: true },
    {
        label: 'Transport',
        icon: 'fa fa-clock-o',
        items: [
            { label: 'Assign Transport', icon: 'pi pi-id-card', to: '/assigntransport' },
            { label: 'Route  Management', icon: 'pi pi-check-square', to: '/routemanagement' },
            { label: 'Transport Fee Receivables', to: '/transportfeereceivables', icon: 'pi pi-bookmark' },
            {
                label: 'Reports',
                icon: 'pi pi-mobile',
                items: [
                    { label: '# Fee Due Reports', to: '/feeduereports' },
                    { label: '# Student Transport Attendance', to: '/studenttransportattendance' },
                    { label: '# Fuel Details', to: '/fueldetails' },
                    { label: '# Maintenance report ', to: '/maintenancereport' }
                ]
            }
        ]
    },
    { separator: true },
    {
        label: 'Reports',
        icon: 'fa fa-bar-chart'
    },
    { separator: true },
    {
        label: 'Communicate',
        icon: 'pi pi-fw pi-comment',
        to: '/communicate-notification'
    },
    { separator: true },
    {
        label: 'Approvals',
        icon: 'fa fa-star-o',
        items: [
            { label: 'Fee Concession', icon: 'pi pi-id-card', to: '/approvals-fee-concession' },
            { label: 'Fee Refund', icon: 'pi pi-check-square', to: '/approvals-fee-refund' },
            { label: 'Fee Unassign', icon: 'pi pi-bookmark', to: '/approvals-Fee-unassign' },
            { label: 'Student Transfer', icon: 'pi pi-exclamation-circle', to: '/transferPendingStudents' },
            { label: 'Student Status Change', icon: 'pi pi-check-square', to: '/approvals-studentstatuschange' },
            { label: 'Assign Transport', icon: 'pi pi-check-square', to: '/approvals-assigntransport' },
            { label: 'Transport Route', icon: 'pi pi-check-square', to: '/approvals-transportroute' },
            { label: 'Substitution Request', icon: 'pi pi-check-square', to: '/approvals-substitutionrequest' }
        ]
    },
    { separator: true },
    {
        label: 'Settings',
        icon: 'pi pi-fw pi-cog',
        items: [
            { label: 'School Setup', icon: 'pi pi-id-card', to: '/schoolSetup' },
            { label: 'Academics', icon: 'pi pi-check-square', to: '/acadamics' },
            { label: 'Fee Setup', icon: 'pi pi-bookmark', to: '/feesetup' },
            { label: 'Finance General Settings', icon: 'fa fa-money', to: '/financeGeneralSettings' },
            { label: 'Finance', icon: 'fa fa-money', to: '/finance' },

            { label: 'Admission', icon: 'pi pi-mobile', to: '/admissionManagement' },
            { label: 'Staff Allocation', icon: 'fa fa-money', to: '/staff' },
            { label: 'Transport', icon: 'pi pi-table', to: '/transportManagement' },
            { label: 'Roles & Users', icon: 'pi pi-list', to: '/rolesAndUsers' },
            { label: 'Timetable', icon: 'pi pi-table', to: '/settimetable' },

            {
                label: 'Approval Hierarchy',
                icon: 'pi pi-table',
                to: '',
                items: [
                    { label: '# Fee Concession', to: '/approval-hierarchy-fee-concession' },
                    { label: '# Fee Refund', to: '/approval-hierarchy-fee-refund' },
                    { label: '# Fee Unassign', to: '/approval-hierarchy-fee-unassign' },
                    { label: '# Student Status Change', to: '/approval-hierarchy-status-change' },
                    { label: '# Student Transfer', to: '/approval-hierarchy-student-transfer' },
                    { label: '# Assign Transport', to: '/approval-hierarchy-assign-transport' },
                    { label: '# Transport Route', to: '/approval-hierarchy-transport-route' },
                    { label: '# Substitution Request', to: '/approval-hierarchy-substitution-request' },

                ]
            },

            { separator: true }
        ]
    },
    { separator: true },
    {
        label: 'Org Setup',
        icon: 'pi pi-fw pi-share-alt',
        to: '/organisation-setup'
    }
];
