import React, { Component } from 'react'

import { InputSwitch } from 'primereact/inputswitch';
//import Navbar from './../navbar';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
// import SearchBar from './searchBar';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
import Authentication from '../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import { ClassetEditIcon, KeyIcon } from '../../svgIcons';
import withRouter from '../../lib/withRouter';
import '../styles.scss'


class UserManagement extends Component {

    constructor(props) {

        super(props);
        this.state = {
            isLoading: false,
            users: [],
            isShowUserInfo: false,
            isShowAnalytics: false,
            selectedUser: null,
            isCreateUser: false,
            //filterData: {},
            editUser: null,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            filterData: null,
            totalRecords: 0,
            globalSearch: '',

            lazyParams: BasicLazyParams,
            columns: [
                { columnName: 'Created At', isVisible: false, id: 'createDateTime' }],
            showingReports: false,
        

        }

        this.service = new Service();
    }


    componentDidMount() {
        this.getUsersApi();

    }


    getUsersApi = async () => {
        this.setState({ isLoading: true })
        const academicYear = localStorage.getItem("userAcademicYear")
        let lazyParams = this.state.lazyParams;
        let globalSearch = this.state.globalSearch;
        let url = `${baseUrlAdmin}/admin/getAllUserAdmin?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        try {
            const res = await this.service.get(url, true);
            if (res.status) {
                this.setState({
                    users: res?.res?.userData?.data.map((r, index) => {
                        r.slNo = (lazyParams.rows * (lazyParams.page)) + (index + 1);
                        return r;
                    }),
                    isLoading: false,
                    totalRecords: res.res?.totalRecords
                });
            } else {
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: res?.message, life: 3000 });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
        }

    };


    // filterUsersData = () => {
    //     this.setState({
    //         isLoading: true,
    //         users: []
    //     });


    //     let { lazyParams, globalSearch, filterData } = this.state;
    //     const url = `${baseUrlAdmin}/user/filter/allusers?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;

    //     this.service.post(url, filterData, true).then(res => {
    //         if (res && res.status && res.res.status) {
    //             let users1 = this.reFormUsersObj(res.res.Items);
    //             this.setState({
    //                 isLoading: false,
    //                 users: users1,
    //                 totalRecords: res.res.totalRecords
    //             });
    //         } else {
    //             this.setState({
    //                 isLoading: false
    //             });
    //             this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
    //         }
    //     }).catch(e => {
    //         this.setState({
    //             isLoading: false
    //         });
    //         console.log(e);
    //     })
    // }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, () => {
            if (e.target.value.length > 2) {
                this.startGlobalFilter()
            }

        });
    }

    startGlobalFilter = () => {

        let filterData = this.state.filterData
        if (filterData && Object.keys(filterData).length) {
            const { isUserNameSearch, username, boardId, classId, groupId } = filterData;

            if (isUserNameSearch) {
                if (username == '') {
                    this.toast.show({ severity: 'error', summary: 'Plese enter username.', detail: 'The username should not be empty. please enter the one registered ', life: 3000 });
                    return;
                }
                if (username.length < 5) {
                    this.toast.show({ severity: 'error', summary: 'Invalid username.', detail: 'Please enter the valid user name', life: 3000 });
                    return;
                }
            } else {
                if (!boardId) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Board , Class.', life: 3000 });
                    return;
                } else if (boardId && (!classId || !groupId)) {
                    this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                    return;
                }
                // else if (branchId && (courseId.length === 0 || sectionId.length === 0)) {
                //     this.toast.show({ severity: 'error', summary: 'Invalid selection', detail: 'Kindly select Class', life: 3000 });
                //     this.props.snackBarOpen('Kindly select Course and Section.');
                //     return;
                // }

            }



            this.filterUsersData();
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalids', detail: 'Please select search criteria..', life: 3000 });
        }
    }


    onPage = (event) => {

        console.log("CALLPAGE")
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getUsersApi);


    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getUsersApi);
    }



    onEditUserClick = (editUser) => {

        // this.props?.onEditUserData(editUser)
        this.props.onTabChange(3, editUser)
        // this.setState({
        //     editUser,
        //     isCreateUser: true
        // });
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id === c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    addUser = () => {
        this.props.onTabChange(3, {});
        this.setState({
            isCreateUser: true
        })
    }
    render() {
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    {/* <h4 className=''>User List</h4> */}
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>
                <div className="bg-blue-50 cursor-pointer mb-2 border-round">
                    <ul className="flex gap-5 mr-6">
                        <Authorizer permId={PERMISSIONS.USER_SEARCH} >
                            <i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i>
                            <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        </Authorizer>
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <Authorizer permId={PERMISSIONS.USER_VIEWCOLUMNS} >
                            <i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        </Authorizer>
                        <Authorizer permId={PERMISSIONS.USER_DOWNLOADCSV} >
                            <i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i>
                            <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        </Authorizer>
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='ml-2 p-2'>
                                <h4 className="">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <div onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="ml-2">{column.columnName}</label>
                                        </div>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );

        return (
            <>
                <div className="text-right mt-4 mb-2">

                    {/* <Authorizer permId={PERMISSIONS.USER_ADDNEWUSER} > */}
                    <Button icon={'pi pi-plus-circle text-lg mt-2 p-2'} style={{ borderRadius: 10, backgroundColor: '#076EFF' }} onClick={this.addUser}>
                        <p className=' text-xl ml-2 '>Add New User</p>
                    </Button>
                    {/* </Authorizer> */}
                </div>
                <div className="card datatable-crud-demo ma-m30">
                    <DataTable ref={(el) => this.dt = el}
                        //  lazy
                        value={this.state.users}
                        dataKey="id"
                        paginator
                        className="dataTableValuesText"
                        lazy
                        responsiveLayout="scroll"
                        showGridlines
                        onSort={this.onSort}
                        onPage={this.onPage}
                        first={this.state.lazyParams.first}
                        rows={this.state.lazyParams.rows}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                        header={''}>

                        {/* <Column style={{ width: "6rem", height: "6rem" }} alignHeader={"center"}  headerClassName="text-color-secondary" bodyClassName='text-center' header="S.No." className="datatable-cell" body={(rowData, { rowIndex }) => {
                                    return (
                                        <>
                                            <p>{(this.state.lazyParams.page * this.state.lazyParams.rows) +( rowIndex + 1)}</p>
                                        </>
                                    )
                                }} /> */}
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"}  headerClassName="text-color-secondary" bodyClassName='text-center' filterField="slNo" field="slNo" header="Sl No" />

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"}  headerClassName="text-color-secondary" bodyClassName='text-center' filterField="username" field="username" header=" Name of the User" />
                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"}  headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData) => {
                            return (
                                <span>{rowData?.user_info?.userType}</span>
                            )
                        }} field="" header="Name of the Role" />

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"}  headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData) => {
                            return (
                                <span>{rowData?.user_info?.phone_number}</span>
                            )
                        }} field="" header="Contact No" />


                        {/* <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"}  headerClassName="text-color-secondary" bodyClassName='text-center' filterField="modified_on" className='' field="modified_on" header="Last Login" /> */}

                        <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"}  headerClassName="text-color-secondary" bodyClassName='text-center' body={(rowData) => {
                            return ((!rowData?.user_info?.isAdmin && rowData?.user_info?.isAdmin !== true)? <>
                                <div className="">

                                    <InputSwitch
                                        className='mt-1'
                                        // onChange={(e) => { this.changeStatus(e, rowData) }}
                                        checked={rowData?.user_info?.status == "Active" ? true : false} />

                                    {/* <Authorizer permId={PERMISSIONS.USER_CHANGE_STATUS} > */}
                                    {/* <InputSwitch value={rowData?.status === "Active" ? true : false} onChange={(e) => { this.changeStatus(e, rowData) }} checked={rowData?.status === "Active"} /> */}
                                    {/* </Authorizer> */}
                                </div>
                                </> :<></>
                            )
                        }} field="gps" header="Status" />

                        <Column className=' ' header="Action" body={(rowData, { rowIndex }) => {
                            return ((!rowData?.user_info?.isAdmin && rowData?.user_info?.isAdmin !== true)? <>
                                <Authorizer permId={PERMISSIONS.UPDATE_USER} >
                                    <div className="flex">
                                        <span data-pr-tooltip="Edit User" data-pr-position="" className={`ma-ml10 ma-tbl-sort awl${rowIndex}`} onClick={() => { this.onEditUserClick(rowData) }}>
                                            <ClassetEditIcon height={32} width={32} />
                                        </span>
                                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                                    </div>
                                </Authorizer>

                            </> : <> </>
                            );
                        }} ></Column>

                        <Column className=' ' header="Resend Credintials" body={(rowData, { rowIndex }) => {
                            return (<>
                                <div className="flex">

                                    {/* <Authorizer permId={PERMISSIONS.USER_EDITUSER} > */}
                                    <span data-pr-tooltip="Reset Credintials" data-pr-position="" className={`ma-ml10 ma-tbl-lock`} >
                                        {/* <SvgViewer className='ma-icon-svg ma-pointer ml-1' src='new-assets/edit.svg' width="20" height="20" /> */}
                                        <KeyIcon height={25} width={25} />

                                    </span>
                                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.ma-tbl-lock`} />
                                    {/* </Authorizer> */}

                                </div>

                            </>
                            );
                        }} ></Column>

                    </DataTable>
                </div>
              

                {
                    this.state.isLoading && <>
                        <LoadingComponent />
                    </>
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}

export default connect(null, {

})(Authentication(withRouter(UserManagement)));