import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import Service from '../../../services';
import { baseUrlAdmin } from '../../../store/apiConstants';
import LoadingComponent from '../../loadingComponent';
import { connect } from 'react-redux';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import { ClassetEditIcon, MailIcon, WarningIcon } from '../../svgIcons';
import withRouter from '../../lib/withRouter';
import CreateStaff from './createStaff';
import { getBranchesLatest } from '../../../store/actions';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import EditStaff from './EditStaff';

class StaffManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isShowCreateForm: false,
            selectedUser: null,
            editUser: null,
            totalRecords: 0,
            lazyParams: BasicLazyParams,
            branchId: '',
        };

        this.service = new Service();
    }

    componentDidMount() {
        this.props.getBranchesLatest(true);
    }

    onEditUserClick = (editUser) => {
        this.setState({
            editUser: editUser,
            isShowEditForm: true
        });
    };
    onCancelEdit = () => {
        this.setState(
            {
                isShowEditForm:false,
                editUser: null,
            },
            () => this.getStaffData()
        );
    };
    onCancel = () => {
        this.setState(
            {
                isShowEditForm:false,
                editUser: null,
                isShowCreateForm: false
            },
            () => this.getStaffData()
        );
    };

    getStaffData = async () => {
        this.setState({
            isLoading: true
        });

        const academicYear = localStorage.getItem('userAcademicYear');
        this.setState({ isLoading: true });
        let url = `${baseUrlAdmin}/transport-staff/get-all-staff-info?academicYear=${academicYear}`;
        let payload = {
            branchId: this.state.branchId
        };
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                this.setState({ staffInfo: res?.res?.data?.data, isLoading: false });
            } else {
                this.setState({
                    isLoading: true,
                    branchId: ''
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: true
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: e, life: 3000 });
        }
    };

    render() {
        const { editUser } = this.state;
        return (
            <>
                <div className="flex justify-content-between  align-items-center lg:mt-4 md:mt-6 mb-2 ">
                    <div className="col-span-1 ">
                        <p className="add-vehicle-field-label">
                            Branch<span className="ma-required">*</span>
                        </p>
                        <div className="flex justify-content-start  align-items-center">
                            <ClassetDropdown
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                optionLabel="name"
                                optionValue="key"
                                width={250}
                                height={48}
                                value={this.state.branchId}
                                options={this.props.branchData}
                                onChange={(e) =>
                                    this.setState(
                                        {
                                            branchId: e.target.value
                                        },
                                        () => this.getStaffData()
                                    )
                                }
                                className="w-full"
                                placeholder={
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className="">Select Branch</span>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <Authorizer permId={PERMISSIONS.CREATE_MANAGEMENT_STAFF}>
                        <Button
                            icon={'pi pi-plus-circle text-lg px-1 mt-1'}
                            className="add-vehicle-btn pl-4"
                            onClick={() => {
                                this.setState({ isShowCreateForm: true });
                            }}
                        >
                            Add Staff
                        </Button>
                    </Authorizer>
                </div>
                <div className="card datatable-crud-demo ma-m30">
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        className="dataTableValuesText"
                        columnResizeMode="expand"
                        value={this.state.staffInfo}
                        dataKey="id"
                        paginator
                        responsiveLayout="scroll"
                        rows={10}
                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                    >
                        <Column
                            style={{ width: '1rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            header="S.No."
                            className="datatable-cell"
                            body={(rowData, index) => {
                                return (
                                    <>
                                        <p>{this.state.lazyParams.page * this.state.lazyParams.rows + index.rowIndex + 1}</p>
                                    </>
                                );
                            }}
                        />
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="name"
                            header="Name"
                            body={(rowData) => {
                                return (
                                    <p className="">
                                        {rowData?.firstName} {rowData?.lastName}
                                    </p>
                                );
                            }}
                        />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="employmentId" field="employmentId" header="Employment Id" />
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="emergencyContactNo"
                            field="emergencyContactNo"
                            header="Mobile Number"
                        />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="designation" field="designation" header="Designation" />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="licenceNo" field="licenceNo" header="Driving Licence" />
                        <Column
                            className=" "
                            alignHeader={'center'}
                            header="Edit"
                            body={(rowData, { rowIndex }) => {
                                return (
                                    <>
                                        <Authorizer permId={PERMISSIONS.UPDATE_MANAGEMENT_STAFF}>
                                            <div className="flex justify-content-center align-items-center">
                                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.awl${rowIndex}`} />
                                                <span
                                                    data-pr-tooltip="Edit"
                                                    data-pr-position=""
                                                    className={`ma-ml10 awl${rowIndex}`}
                                                    onClick={() => {
                                                        this.onEditUserClick(rowData);
                                                    }}
                                                >
                                                    <ClassetEditIcon height={25} width={25} />
                                                </span>
                                                <Tooltip className="table-li-tooltip2" autoHide={false} target={`.uy${rowIndex}`} />
                                            </div>
                                        </Authorizer>
                                    </>
                                );
                            }}
                        ></Column>
                    </DataTable>
                </div>

                <Dialog
                    blockScroll={true}
                    visible={this.state.isShowCreateForm}
                    draggable={false}
                    closeOnEscape={false}
                    className="ma-alert-box"
                    style={{
                        width: '88%',
                        height: 'auto',
                        maxHeight: '90%',
                        margin: '0 auto'
                    }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return <h2 className="transport-header py-2">{!this.state?.editUser ? 'Enroll ' : 'Edit '}Transport Staff Details</h2>;
                    }}
                    onHide={() => {
                        this.setState({
                            isShowCreateForm: false,
                            newPassword: null,
                            editUser: null
                        });
                    }}
                >
                    <div className="">
                        <CreateStaff onCancel={this.onCancel} editData={editUser} getVehicleData={this.getVehiclesData} toastMessage={this.toast} />
                    </div>
                </Dialog>

                <Dialog
                    blockScroll={true}
                    visible={this.state.isShowEditForm}
                    draggable={false}
                    closeOnEscape={false}
                    className="ma-alert-box"
                    style={{
                        width: '90%',
                        height: 'auto',
                        maxHeight: '90%',
                        margin: '0 auto'
                    }}
                    dismissableMask={false}
                    closable={true}
                    header={() => {
                        return <h2 className="transport-header py-2">{!this.state?.editUser ? 'Enroll ' : 'Edit '}Transport Staff Details</h2>;
                    }}
                    onHide={() => {
                        this.setState({
                            isShowEditForm: false,
                            editUser: null
                        });
                    }}
                >
                    <div className="">
                        <EditStaff onCancel={this.onCancelEdit} editData={editUser} toastMessage={this.toast} />
                    </div>
                </Dialog>
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        []
});

export default connect(mapStateToProps, {
    getBranchesLatest
})(withRouter(StaffManagement));
