import moment from 'moment';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react';
import Authentication from './../session';
import { connect } from 'react-redux';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

class BulkFeeReciepts extends Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            reciept: []
        }
    }

    render() {
        const { receiptData, logoRectangle, orgName, orgAddress, mobileOperational } = this.props;

        return (
            <>
                <Dialog
                    style={{ width: '80%' }}
                    header={receiptData?.receiptType === "acknowledgement" ? "Acknowledgement" : 'Term Fee Reciepts'}
                    modal
                    footer={this.renderFooter}
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={false}
                    dismissableMask={false}
                    visible={true}
                    closable={true}
                    onHide={this.props.hideFeeReciept}
                >
                    <div ref={this.componentRef}>
                        {receiptData?.map(receiptData => (
                            <div className='receipt-container' key={receiptData?._id}>
                                <div className='flex justify-between ma-m20 mb-8' key={receiptData?._id}>
                                    <div style={{ width: '48%', border: '1px dashed' }}>
                                        <div className='flex'>
                                            <div className='w-30 ma-mt10'>
                                                <img src={`${logoRectangle?.storageLocation}`} className='logo-img' style={{ marginLeft: '10%' }} />
                                            </div>
                                            <div className='flex justify-center w-70'>
                                                <div>
                                                    <div className='flex justify-center'>
                                                        <h2 className='p-mb-2 orgHeader'>{orgName}</h2>
                                                    </div>
                                                    <h6 className='p-0 m-0 text-900 font-bold'>{orgAddress}</h6>
                                                    <div>
                                                        <h6 className='p-0 m-0 text-900 font-bold'>Contact Number: {mobileOperational}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100'>
                                            <hr className='dashed' />
                                        </div>
                                        <div className='flex justify-end mr-3'>
                                            <h5>Office Copy</h5>
                                        </div>
                                        <div className='flex justify-center mb-3'>
                                            <h3 className='text-900 font-semibold'>{receiptData?.receiptType === "acknowledgement" ? "Acknowledgement" : 'Fee Reciept'}</h3>
                                        </div>
                                        <div className='ml-3'>
                                            <h6 className='payinfo'>RECEIPT NUMBER : {receiptData?.receiptNo}</h6>
                                            <h6 className='payinfo'>RECEIPT DATE : {moment(receiptData?.recieptDate).format('DD-MM-YYYY')}</h6>
                                            <h6 className='payinfo'>NAME OF THE STUDENT : {receiptData?.given_name}</h6>
                                            <h6 className='payinfo'>USER NAME : {receiptData?.username}</h6>
                                            <h6 className='payinfo'>ACADAMIC YEAR : {receiptData?.academicYear}</h6>
                                            <h6 className='payinfo'>CLASSNAME : {receiptData?.className}</h6>
                                            <h6 className='payinfo'>PAYMENT MODE : {receiptData?.paymentMode}</h6>
                                            {receiptData?.paymentMode !== 'cash' && receiptData?.paymentMode !== 'Cheque' && receiptData?.paymentMode !== 'DD' && <h6 className='payinfo'>TRANSACTION ID: {receiptData?.transctionId}</h6>}
                                            <div className='mr-3'>
                                                <table className='table-border1 w-100'>
                                                    <thead>
                                                        <tr>
                                                            <th className='table-border p-1 m-0 text-900 font-bold text-xs w-1'>S.no</th>
                                                            <th className='table-border p-1 m-0 text-900 font-bold text-xs'>Particulars</th>
                                                            <th className='table-border p-1 m-0 text-900 font-bold text-xs'>Amount</th>
                                                            <th className='table-border heading-font'>Fine</th>
                                                            <th className='table-border p-1 m-0 text-900 font-bold text-xs'>GST</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {receiptData?.feePaidDetails.map((e, i) => (
                                                            <tr key={i}>
                                                                <td className='table-border heading-font'>{i + 1}</td>
                                                                <td className='table-border heading-font'> {e.term ? `${e.feeType} - Term ${e.term}` : e.feeType}</td>
                                                                <td className='table-border heading-font'>{(e.amountPaying || 0).toFixed(2)}</td>
                                                                <td className='table-border heading-font'>{(e?.fineAmtPaying || 0).toFixed(2)}</td>
                                                                <td className='table-border heading-font'>{(e?.gstPaidNow || 0).toFixed(2)}</td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td className='table-border totalAmt' colSpan='5'>Total Amount Paid - {receiptData.amountPaid.toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div>
                                                    <h6>Note: <span></span></h6>
                                                </div>
                                                <div className='flex justify-end mt-7'>
                                                    <h6>Cashier/Manager</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '48%', border: '1px dashed' }}>
                                        <div className='flex'>
                                            <div className='w-30 ma-mt10'>
                                                <img src={`${logoRectangle?.storageLocation}`} className='logo-img' style={{ marginLeft: '10%' }} />
                                            </div>
                                            <div className='flex justify-center w-70'>
                                                <div>
                                                    <div className='flex justify-center'>
                                                        <h2 className='p-mb-2 orgHeader'>{orgName}</h2>
                                                    </div>
                                                    <h6 className='p-0 m-0 text-900 font-bold'>{orgAddress}</h6>
                                                    <div>
                                                        <h6 className='p-0 m-0 text-900 font-bold'>Contact Number: {mobileOperational}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100'>
                                            <hr className='dashed' />
                                        </div>
                                        <div className='flex justify-end mr-3'>
                                            <h5>Parent Copy</h5>
                                        </div>
                                        <div className='flex justify-center mb-3'>
                                            <h3 className='text-900 font-semibold'>{receiptData?.receiptType === "acknowledgement" ? "Acknowledgement" : 'Fee Receipt'}</h3>
                                        </div>
                                        <div className='ml-3'>
                                            <h6 className='payinfo'>RECEIPT NUMBER : {receiptData?.receiptNo}</h6>
                                            <h6 className='payinfo'>RECEIPT DATE : {moment(receiptData?.recieptDate).format('DD-MM-YYYY')}</h6>
                                            <h6 className='payinfo'>NAME OF THE STUDENT : {receiptData?.given_name}</h6>
                                            <h6 className='payinfo'>USER NAME : {receiptData?.username}</h6>
                                            <h6 className='payinfo'>ACADEMIC YEAR : {receiptData?.academicYear}</h6>
                                            <h6 className='payinfo'>CLASSNAME : {receiptData?.className}</h6>
                                            <h6 className='payinfo'>PAYMENT MODE : {receiptData?.paymentMode}</h6>
                                            {receiptData?.paymentMode !== 'cash' && receiptData?.paymentMode !== 'Cheque' && receiptData?.paymentMode !== 'DD' && <h6 className='payinfo'>TRANSACTION ID: {receiptData?.transctionId}</h6>}
                                            <div className='mr-3'>
                                                <table className='table-border1 w-100'>
                                                    <thead>
                                                        <tr>
                                                            <th className='table-border p-1 m-0 text-900 font-bold text-xs w-1'>S.no</th>
                                                            <th className='table-border p-1 m-0 text-900 font-bold text-xs'>Particulars</th>
                                                            <th className='table-border p-1 m-0 text-900 font-bold text-xs'>Amount</th>
                                                            <th className='table-border heading-font'>Fine</th>
                                                            <th className='table-border p-1 m-0 text-900 font-bold text-xs'>GST</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {receiptData?.feePaidDetails.map((e, i) => (
                                                            <tr key={i}>
                                                                <td className='table-border heading-font'>{i + 1}</td>
                                                                <td className='table-border heading-font'> {e.term ? `${e.feeType} - Term ${e.term}` : e.feeType}</td>
                                                                <td className='table-border heading-font'>{(e.amountPaying || 0).toFixed(2)}</td>
                                                                <td className='table-border heading-font'>{(e?.fineAmtPaying || 0).toFixed(2)}</td>
                                                                <td className='table-border heading-font'>{(e?.gstPaidNow || 0).toFixed(2)}</td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td className='table-border totalAmt' colSpan='5'>Total Amount Paid - {receiptData.amountPaid.toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div>
                                                    <h6>Note: <span></span></h6>
                                                </div>
                                                <div className='flex justify-end mt-7'>
                                                    <h6>Cashier/Manager</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Dialog>
            </>
        );
    }

    renderFooter = () => {
        return (
            <>
                <ReactToPrint
                    pageStyle="padding:10px"
                    content={() => this.componentRef.current}
                >
                    <PrintContextConsumer>
                        {({ handlePrint }) => (
                            <>
                                <Button
                                    data-pr-position="bottom"
                                    data-pr-tooltip="Download"
                                    onClick={handlePrint}
                                    className='print-button ma-mr20 p-ml-10 p-mb-4'
                                    label='Print'
                                />
                                <Tooltip className="table-li-tooltip" target=".egraph" />
                            </>
                        )}
                    </PrintContextConsumer>
                </ReactToPrint>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    logoRectangle: state.orgData.logoRectangle,
    orgName: state.orgData.name,
    orgAddress: state.orgData.address,
    mobileOperational: state.orgData.mobileOperational,
});

export default connect(mapStateToProps, {})(Authentication(BulkFeeReciepts));
