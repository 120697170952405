import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { primaryColor } from '../../../../store/apiConstants';
import { connect } from 'react-redux';
import withRouter from '../../../lib/withRouter';
import RegistrationCertificate from './registrationCertificate';
import RoutePermit from './routePermit';
import Insurance from './insurance';
import RoadTax from './roadTax';
import Pollution from './pollution';
import moment from 'moment';
class MoreInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
            vehicleInfo: [this.props.vehicleInfo] || []
        };
    }

    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        });
    };
    onCancelMoreInfo = () => {
        this.props.onCancelMoreInfo()
    }
    render() {
        return (
            <div>
                <div className="card datatable-crud-demo ">
                    <DataTable
                        className="dataTableValuesText"
                        columnResizeMode="expand"
                        dataKey="id"
                        responsiveLayout="scroll"
                        rows={10}
                        value={this.state.vehicleInfo}
                    // onPage={this.onPage}
                    // first={this.state.lazyParams.first}
                    // last={this.state.totalRecords}
                    // rows={this.state.lazyParams.rows}
                    // totalRecords={this.state.totalRecords}
                    >
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="vehicleRegistrationNo"
                            field="vehicleRegistrationNo"
                            header="Vehicle Number"
                        />
                        {/*     <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            body={(rowData) => {
                                return (
                                    <div className="">
                                        <InputSwitch
                                            onChange={(e) => {
                                                this.changeGpsStatus(e, rowData);
                                            }}
                                            checked={rowData.isGps}
                                        />
                                    </div>
                                );
                            }}
                            field="isGps"
                            header="Gps"
                        /> */}
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="vehicleType" field="vehicleType" header="Vehicle Type" />
                        <Column style={{ width: '15rem', height: '6rem' }} alignHeader={'center'} sortable headerClassName="text-color-secondary" bodyClassName="text-center" filterField="rcExpiryDate" field="rcExpiryDate" header="RC ExpirtyDate" body={(rowData) => {
                            return (<>
                                <p>{rowData?.certificates?.expiryDate ? moment(rowData?.certificates?.expiryDate).utc().format("DD-MM-YYYY") : ""}</p>
                            </>)
                        }} />

                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="insuranceExpiryDate"
                            className=""
                            field="insuranceExpiryDate"
                            header="Insurance Expiry Date"
                            body={(rowData) => {
                                return (<>
                                    <p>{rowData?.insurances?.expiryDate ? moment(rowData?.insurances?.expiryDate).utc().format("DD-MM-YYYY") : ""}</p>
                                </>)
                            }}

                        />
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="pollutionExpiryDate"
                            className=""
                            field="pollutionExpiryDate"
                            header="Pollution Expiry Date"
                            body={(rowData) => {
                                return (<>
                                    <p>{rowData?.pollutions?.expiryDate ? moment(rowData?.pollutions?.expiryDate).utc().format("DD-MM-YYYY") : ""}</p>
                                </>)
                            }}
                        />
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="roadTaxExpiryDate"
                            className=""
                            field="roadTaxExpiryDate"
                            header="Road Tax Expiry Date"
                            body={(rowData) => {
                                return (<>
                                    <p>{rowData?.taxes?.expiryDate ? moment(rowData?.taxes?.expiryDate).utc().format("DD-MM-YYYY") : ""}</p>
                                </>)
                            }}
                        />
                        <Column
                            style={{ width: '15rem', height: '6rem' }}
                            alignHeader={'center'}
                            sortable
                            headerClassName="text-color-secondary"
                            bodyClassName="text-center"
                            filterField="permitExpiryDate"
                            className=""
                            header="Permit Expiry Date"
                            body={(rowData) => {
                                return (<>
                                    <p>{rowData?.routePermits?.expiryDate ? moment(rowData?.routePermits?.expiryDate).utc().format("DD-MM-YYYY") : ""}</p>
                                </>)
                            }}
                        />
                    </DataTable>
                </div>

                <div className=" h-4rem">
                    <div className="grid md:col-12  xl:col-12 doubts-tabs mx-1">
                        <div className="cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                            <p
                                className="tabheadText"
                                onClick={() => {
                                    this.onTabChange(0);
                                }}
                            >
                                Registration Certificate
                            </p>
                        </div>
                        <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 1 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                            <p
                                className="tab-p"
                                onClick={() => {
                                    this.onTabChange(1);
                                }}
                            >
                                Route Permit
                            </p>
                        </div>
                        <div className=" cursor-pointer col-2 doubts-tab-w" style={this.state.currentTab === 2 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                            <p
                                className="tab-p"
                                onClick={() => {
                                    this.onTabChange(2);
                                }}
                            >
                                Insurance
                            </p>
                        </div>
                        <div className=" cursor-pointer col-2 doubts-tab-w" style={this.state.currentTab === 3 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                            <p
                                className="tab-p"
                                onClick={() => {
                                    this.onTabChange(3);
                                }}
                            >
                                Road Tax
                            </p>
                        </div>
                        <div className=" cursor-pointer col-2 doubts-tab-w" style={this.state.currentTab === 4 ? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                            <p
                                className="tab-p"
                                onClick={() => {
                                    this.onTabChange(4);
                                }}
                            >
                                Pollution
                            </p>
                        </div>
                    </div>
                </div>
                {this.state.currentTab == 0 && (
                    <>
                        {/* <Authorizer permId={PERMISSIONS.VEHICLE_MANAGEMENT_LIST}> */}
                        <RegistrationCertificate vehicleInfo={this.state?.vehicleInfo} onCancelMoreInfo={() => this.onCancelMoreInfo()} onSuccessToast={this.props.onSuccessToast} />
                        {/* </Authorizer> */}
                    </>
                )}
                {this.state.currentTab == 1 && (
                    <>
                        {/* <Authorizer permId={PERMISSIONS.ROUTE_MANAGEMENT_LIST}> */}
                        <RoutePermit vehicleInfo={this.state?.vehicleInfo} onCancelMoreInfo={() => this.onCancelMoreInfo()} onSuccessToast={this.props.onSuccessToast} />
                        {/* </Authorizer> */}
                    </>
                )}
                {this.state.currentTab == 2 && (
                    <>
                        {/* <Authorizer permId={PERMISSIONS.ASSIGN_TRANSPORT}> */}
                        <Insurance vehicleInfo={this.state?.vehicleInfo} onCancelMoreInfo={() => this.onCancelMoreInfo()} onSuccessToast={this.props.onSuccessToast} />

                        {/* </Authorizer> */}
                    </>
                )}
                {this.state.currentTab == 3 && (
                    <>
                        {/* <Authorizer permId={PERMISSIONS.TRANSPORT_FEE}> */}
                        <RoadTax vehicleInfo={this.state?.vehicleInfo} onCancelMoreInfo={() => this.onCancelMoreInfo()} onSuccessToast={this.props.onSuccessToast} />
                        {/* </Authorizer> */}
                    </>
                )}
                {this.state.currentTab == 4 && (
                    <>
                        {/* <Authorizer permId={PERMISSIONS.TRANSPORT_FEE}> */}
                        <Pollution vehicleInfo={this.state?.vehicleInfo} onCancelMoreInfo={() => this.onCancelMoreInfo()} onSuccessToast={this.props.onSuccessToast} />
                        {/* </Authorizer> */}
                    </>
                )}
            </div>
        );
    }
}

export default connect()(withRouter(MoreInfo));
