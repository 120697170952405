import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react'
import { RadioButton } from "primereact/radiobutton";
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { MultiSelect } from 'primereact/multiselect';
import { AddIconInButton, AssignFeestructureIcon, EnvelopeIcon, MailIcon } from '../svgIcons';
import './styles.scss'
import ClassetDropdown from '../../classetComponents/classetDropDown';
import ClassetInputText from '../../classetComponents/classetInputText';
import ClassetMultiSelect from '../../classetComponents/classetMultiSelect';
import BoardToSectionDD from '../BaseDropdownComponents/BoardToSectionDD';
import ClassetStudentSearch from '../../classetComponents/classetStudentSearch';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';
import { Toast } from 'primereact/toast';
const sampleOptions = [
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" },
    { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" }, { value: 1, label: "sample 1" },
]

// const searchOptions = [
//     { value: 1, label: "Student Name" },
//     { value: 2, label: "Enrollment Number" }
// ]


const options = ['Fee Type Based', 'Term Based'];
const searchTypeOptions = [
    { label: "Student Name", value: "studentName" },
    { label: "Enrollment Number", value: "enrollmentNumber" }
]
export class AssignFeeScreen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // searchType: 1,
            searchInput: "",
            isAssignBtnFeeCategory: true,
            isAssignSaveFeeCategory: false,
            isAssignViewFeeCategory: false,
            searchLevel: 1,
            searchValue: "",
            students: [],
            searchType: searchTypeOptions[0].value,
            studentId: '',
            feeStructure: [],
            feeStructureOptions: [],
            feeStructuresList: []

        }
        this.service = new Service()
    }
    componentDidMount() {
        this.getFeeStructures()
    }
    handleAssignFeeCatergory = () => {
        this.setState({ isAssignBtnFeeCategory: false, isAssignSaveFeeCategory: true, isAssignViewFeeCategory: false })
    }

    getStudentFeeDetails = (payload) => {
        let url = `${baseUrlAdmin}/fee-structure/studentFeedetails`
        this.service.post(url, payload, true).then(res => {
            if (res?.status && res?.res?.status) {
                this.setState({
                    isLoading: false,
                    studentsFeeDetails: res?.res?.data,
                    isAssignBtnFeeCategory: false, isAssignSaveFeeCategory: false, isAssignViewFeeCategory: true
                })
                this.toast.show({ severity: 'info', summary: 'Success', life: 3000 });
            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {

            this.setState({ isLoading: false })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
        })
    }

    handleClickAssignFeeSave = () => {

        let academicYear = localStorage.getItem('userAcademicYear')
        let payload = {
            "branchId": this.state.dropdownsData?.branchId,
            "boardId": this.state.dropdownsData?.boardId,
            "classId": this.state.dropdownsData?.classId,
            "sectionId": this.state.dropdownsData?.sectionId,
            "academicYear": academicYear,
            "feeCategoryId": "",
            "feeStructureId": this.state.feeStructure,
            "feeTypes": [
            ],
            "assignedInfo": [
                {
                    "user_id": this.state.studentId
                }
            ]
        }

        let selectedFeeStructureId = this.state.feeStructure;

        this.state.feeStructuresList?.map((e) => {
            if (e._id === selectedFeeStructureId) {
                payload.feeCategoryId = e.feeCategory;
                payload.feeTypes = e.stepOne?.map((fti) => fti.feeTypeId);

            }
        })

        let url = `${baseUrlAdmin}/fee-structure/assign`
        this.service.post(url, payload, true).then(res => {
            if (res?.status && res?.res?.status) {
                this.setState({
                    isLoading: false,
                })
                this.getStudentFeeDetails({ academicYear: academicYear, studentId: this.state.studentId })

            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(err => {
            this.setState({ isLoading: false })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
        })
        // 
    }

    getStudents = () => {
        let academicYear = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/fine/student`
        let payload = {
            "branchId": this.state.dropdownsData?.branchId,
            "boardId": this.state.dropdownsData?.boardId,
            "classId": this.state.dropdownsData?.classId,
            "sectionId": this.state.dropdownsData?.sectionId,
            "academicYear": academicYear
        }
        this.service.post(url, payload, true).then(data => {
            const studentsData = data?.res?.data.map(each => {
                return {
                    label: each?.name,
                    value: each?.user_id
                }
            })

            if (data?.status) {
                this.setState({
                    students: studentsData,
                    isLoading: false,
                    completeStudentData: data?.res?.data
                })

            } else {
                this.setState({ isLoading: false })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }

        }).catch(error => {
            this.setState({ isLoading: false })

        })

    }

    getFeeStructures = () => {
        let academicYear = localStorage.getItem('userAcademicYear')
        let url = `${baseUrlAdmin}/fee-structure?academicYear=${academicYear}`
        this.service.get(url, true).then(data => {
            if (data?.status) {
                this.setState({
                    feeStructuresList: data?.res?.data,
                    isLoading: false
                })

            } else {
                this.setState({ isLoading: false, feeStructuresList: [] })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }

        }).catch(error => {
            this.setState({ isLoading: false, feeStructuresList: [] })
        })

    }
    handleSearchLevel = (value) => {
        this.setState({ searchLevel: value }
        )
    }

    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData
        }, () => { this.getStudents() })
    }

    handleSetSearchTypeValue = (value) => {
        this.setState({ searchType: value }, () => {
            if (this.state.searchType == "studentName") {
                this.setState({ isUserNameSearch: true, })
            } else {
                this.setState({ isUserEnrollSearch: true, })
            }

        })
    }

    handleSetSearchValue = (value) => {
        this.setState({
            searchValue: value,
            dropdownsData: {
                boardId: '',
                branchId: "",
                classId: '',
                sectionId: '',
            }
        }, () => {
        })
    }
    onStudentChange = (e) => {
        const name = this.state?.students?.filter((each) => {
            return each.value === e.value;
        }).map(student => student.label);
        this.setState({ studentId: e.value, studentName: name, })
        this.setState({ isAssignBtnFeeCategory: true, isAssignSaveFeeCategory: false, isAssignViewFeeCategory: false })
    }

    onChangeFeeStructure = (e) => {
        this.setState({ feeStructure: e.target.value })
    }

    render() {
        return (
            <div className="ma-main">
                <div className=" datatable-crud-demo ma-m30 mx-2 mb-5">
                    <div className="flex mt-4">
                        <div className="flex flex-column justify-content-center align-items-center -mt-3">
                            <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                        </div>

                        <div className="field ml-4">
                            <ClassetStudentSearch selectedValue={this.state.searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                        </div>
                    </div>

                    <div style={{ marginLeft: "100px" }} className="flex justify-content-start align-items-center">
                        <span className="">OR</span>
                    </div>
                    <div className="flex">
                        <div className="flex flex-column justify-content-center align-items-center mt-3">
                            <RadioButton inputId="ingredient1" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                        </div>

                        <div>
                            <BoardToSectionDD selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} />
                        </div>


                    </div>
                    <div className="col-12 ml-5">
                        <label className='label'>Student<span className='ma-required'>*</span></label>
                        <div className='mt-2'>
                            <ClassetDropdown
                                icon={<MailIcon />}
                                id='paymentMode'
                                className=""
                                height="48px"
                                width="327px"
                                options={this.state?.students}
                                value={this.state.studentId}
                                disabled={this.state?.searchLevel === 2}
                                onChange={this.onStudentChange}
                                placeholder={(
                                    <div>
                                        <span className=''>Select Student</span>
                                    </div>
                                )}
                            />

                        </div>
                    </div>

                </div>


                <div className='card flex assignFeeCard ml-4 mt-5'>
                    <div className='cardProfile '>
                        <h4 className='cardSubProfileText pt-4 ml-5'>Student Details</h4>
                        <div className=' grid col-12 cardSubProfile align-items-center'>
                            <div className='flex pl-6 col-6 justify-content-center align-items-center'>
                                <Avatar className='imageSize' image="https://primefaces.org/cdn/primereact/images/galleria/galleria7.jpg" size="xlarge" shape="circle" />
                                <div className=' ml-4'>
                                    <p className='cardSubProfileBoldText'
                                    >{this.state.studentName} </p>
                                    <p className='cardSubProfileParaText'>{ }</p>
                                </div>
                            </div>

                            <div className='flex col-6 pl-8 flex-column justify-content-end align-items-center'>
                                <p className='boardText'>{this.state.dropdownsData?.selectedBoardName} </p>
                                <p className='cardSubProfileParaText'>{this.state.dropdownsData?.selectedClassName} - {this.state.dropdownsData?.selectedsectionName}</p>
                            </div>
                        </div>
                    </div>
                    <div className='feeCategory'>
                        {this.state.isAssignBtnFeeCategory &&
                            <div className='flex flex-column justify-content-center align-items-center ml-4 pt-2 mt-8'>
                                <Button className='assign-fee-category-btn' style={{ borderRadius: 13 }} onClick={() => this.handleAssignFeeCatergory()} label="Assign Fee Structure" type='button' />
                            </div>

                        }
                        {this.state.isAssignSaveFeeCategory &&
                            <div className='flex justify-content-around align-content-start mt-8 animation-ease-out'>
                                <div className='assignFeeDropdown ml-3'>
                                    <p className='ma-label-s2 font-serif font-semibold text-900 text-lg'>Fee Structure<span className='ma-required'>*</span></p>
                                    <ClassetDropdown
                                        icon={<MailIcon width={20} height={16} color={'#667A85'} />}
                                        options={this.state.feeStructuresList}
                                        width={"400px"}
                                        height={"50px"}
                                        optionLabel="scheduleName"
                                        optionValue="_id"
                                        value={this.state.feeStructure}
                                        onChange={this.onChangeFeeStructure}
                                        maxSelectedLabels={3}
                                        disabled={this.state.studentName ? false : true}
                                        placeholder={(
                                            <div className="flex justify-content-start  align-items-center mt-1">
                                                <span className='text-xl ml-4'>Select Fee Structure</span>
                                            </div>
                                        )} />

                                </div>
                                <div className='mt-4'>
                                    <Button disabled={this.state.studentName ? false : true} onClick={() => this.handleClickAssignFeeSave()} label="Save" type='button' className='assignFeeSaveBtn' />
                                </div>
                            </div>
                        }
                        {this.state.isAssignViewFeeCategory &&
                            <div className='mt-4 ml-4 flex flex-column justify-content-center align-content-center'>
                                <div className='grid col-12'>
                                    <div className=' col-6 flex justify-content-center align-content-center'>
                                        <div className='border-200 border-bottom-2'>
                                            <p className='cardSubProfileBoldText'>Total Fee</p>
                                            <p className='cardSubProfileParaText'>{this.state.studentsFeeDetails?.totalFeeSum || 0}</p>
                                        </div>
                                    </div>
                                    <div className=' col-6 flex justify-content-center align-content-center'>
                                        <div className='border-200 border-bottom-2'>
                                            <p className='cardSubProfileBoldText'>Concession</p>
                                            <p className='cardSubProfileParaText'>{this.state.studentsFeeDetails?.concessionSum || 0}</p>
                                        </div>
                                    </div>
                                    <div className=' col-6 flex justify-content-center align-content-center'>
                                        <div className='border-200 border-bottom-2'>
                                            <p className='cardSubProfileBoldText'>Paid Fee</p>
                                            <p className='cardSubProfileParaText'>{this.state.studentsFeeDetails?.amountPaidSum || 0}</p>
                                        </div>
                                    </div>
                                    <div className='col-6 flex justify-content-center align-content-center'>
                                        <div className='flex border-200 border-bottom-2 pl-4'>
                                            {/* <RadioButton inputId="ingredient1" name="pizza" value="Cheese" checked={this.state.studentsFeeDetails?.isTransport} />
                                            <p className='cardSubProfileBoldText -mt-1 ml-3'>Transport</p> */}
                                        </div>
                                    </div>

                                </div>
                            </div>

                        }

                    </div>

                </div>



                <Toast ref={(el) => this.toast = el} position="bottom-right" />


            </div>
        )
    }
}





export default AssignFeeScreen