import React, { Component } from "react"
import { connect } from 'react-redux';
import Service from '../../../services';
import { DataTable } from 'primereact/datatable';
import SvgViewer from './../../customComponents/svgViewer';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { BasicLazyParams, warningDailogInit } from '../../../utile';
import cloneDeep from 'lodash/cloneDeep';
import Authentication from './../../session';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import _ from 'lodash';
import { BoardsIconInActive, MailIcon, RightMarkInCircle, WarningIcon } from '../../svgIcons';
import LoadingComponent from '../../loadingComponent';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../store/apiConstants';
import { BreadCrumb } from 'primereact/breadcrumb';
import CreateFeeType from "../../organizationManage/feeTypes/createFeeType";
import { MultiSelect } from "primereact/multiselect";
import withRouter from '../../lib/withRouter';
import { AnalyticsIcon2 } from '../../svgIcons';
import moment from 'moment';
import InputTextB from '../../customComponents/inputTextB';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import SearchBar from "./searchBar";
import './styles.scss'
import BranchToSectionMultiDD from "../../BaseDropdownComponents/BranchToSectionMultiDD";
import { RadioButton } from "primereact/radiobutton";
import ClassetInputText from "../../../classetComponents/classetInputText";
import ClassetDropdown from "../../../classetComponents/classetDropDown";
import ClassetStudentSearch from "../../../classetComponents/classetStudentSearch";
import { Divider } from "primereact/divider";
import BranchToSectionDDCol from "../../BaseDropdownComponents/BranchToSectionDDCol";
import BranchToSectionMultiDDPreFill from "../../BaseDropdownComponents/BranchToSectionMultiDDPreFill";
import { debounce } from 'lodash';
import { getBranchesLatest } from "../../../store/actions";
import BranchToSectionDDColSEc from "../../BaseDropdownComponents/BranchToSectionDDColSEc";
const searchTypeOptions = [
    { label: "Student Name", value: "studentName" },
    { label: "Enrollment Number", value: "enrollmentNumber" }
]

class StudentTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            users: [
            ],
            filterData: null,

            columns: [
                { columnName: 'Created At', isVisible: false, id: 'createDateTime' }],

            isUserNameSearch: false,
            isUserEnrollSearch: false,
            username: '',
            boardId: null,
            classId: null,
            groupId: null,
            sectionIds: [],
            boards: [],
            classes: [],
            groups: [],
            sections: [],
            branchLevels: {},
            searchLevel: 1,
            searchType: searchTypeOptions[0].value,
            searchValue: "",
            lazyParams: BasicLazyParams,
            users: [],
            selectedUser: [],
            globalSearch: '',
            totalRecords: 0,
            isTransferDialogOpen: false,
            transferFee: '',
            selectAll: false,
            select: false,
            selectedTransferStudents: [],
            dropdownsDataDilogData: {},
            rowData: {},
            selectedRows: {},
            transfer: null,
            flag: false,
            dropdownsData: {},
            branchId: null,

            branchName: null,
            totalFee: 0
        };
        this.debouncedApiCall = debounce(this.filterUsersData, 500);
        this.service = new Service();

    }

    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id === id)[0].isVisible;
    }

    reFormUsersObj = (users) => {
        let allUsers = [];
        users.length &&
            users.map((user) => {
                let _user = {
                    ...user
                };
                if (user.user_info) {
                    for (let key in user.user_info) {
                        _user[key] = user.user_info[key];
                    }
                }
                allUsers.push({ ..._user });
                return null;
            });

        return allUsers;
    }



    filterUsersData = (dropdownsData) => {
        this.setState({
            isLoading: true,
            users: []
        });


        let { lazyParams, globalSearch, filterData } = this.state;
        const url = `${baseUrlAdmin}/user/filter/allusers?academicYear=${localStorage.getItem('userAcademicYear')}&limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}`;


        const payload = {
            branchId: this.state?.dropdownsData?.branchIds,
            boardId: this.state?.dropdownsData?.boardIds,
            classId: this.state?.dropdownsData?.classIds,
            sectionId: this.state.dropdownsData?.sectionId,
            isUserNameSearch: this.state.searchLevel == 2 && this.state.searchType == "studentName" ? true : this.state.isUserNameSearch,
            username: this.state.searchValue
        }

        this.service.post(url, payload, true).then(res => {
            if (res && res.status && res.res.status) {
                let users1 = this.reFormUsersObj(res.res.Items);
                this.setState({
                    isLoading: false,
                    users: users1,
                    totalRecords: res.res.totalRecords,
                    isUserNameSearch: false,
                    isUserEnrollSearch: false,
                    searchValue: ""
                });

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }
    componentDidMount() {
        if (this.state?.dropdownsData?.sectionId) {
            this.filterUsersData();

        }

        this.props.getBranchesLatest();
    }


    componentDidUpdate(prevProps, prevState) {



        if (prevState?.transfer !== this.state.transfer && this.state?.transfer != null) {


            if (Array.isArray(this.state.selectedRows) && this.state.selectedRows.length > 0) {
                this.formatData(this.state?.selectedRows, this.state?.transfer)

                this.getUsersFee()
            }


        }



    }



    onSearchClick = (filterData) => {

        this.setState({
            filterData
        }, this.filterUsersData)


    }
    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.filterUsersData);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.filterUsersData);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.filterUsersData);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }

    setDropdownValues = (dropdownsData) => {
        this.setState({
            dropdownsData: dropdownsData
        }, () => this.filterUsersData())
    }

    setTransfer = (value) => {
        console.log(this.state.selectedRows, "this.state.selectedRows")


        this.setState({ transfer: value })





    }

    handleSetSearchTypeValue = (value) => {
        this.setState({ searchType: value }, () => {
            if (this.state.searchType == "studentName") {
                this.setState({ isUserNameSearch: true })
            } else {
                this.setState({ isUserEnrollSearch: true })
            }

        })
    }

    handleSetSearchValue = (value) => {
        this.setState({ searchValue: value }, () => {

            this.debouncedApiCall(this.state.searchValue);

        })
    }



    handleSearchLevel = (value) => {
        this.setState({ searchLevel: value }, () => {
            // this.filterUsersData()

        })
    }


    setDropdownValuesDilog = (dropdownsData) => {

        this.setState({ dropdownsDataDilogData: dropdownsData })
    }



    gradeSectionBodyTemplate = (rowData) => {
        return `${rowData["custom:grade"]}-${rowData.sectionName}`;
    }


    formatData(value, transferType) {
        console.log(value, "valuevaluevaluevalue");

        const formattedData = value.map(item => ({
            fmbranchId: item?.branchId,
            fmboardId: item?.boardId,
            fmclassId: item?.classId,
            fmsectionId: item?.sectionId,
            fmbranchName: item?.branchName,
            fmboradName: item["custom:board"],
            fmclassName: item["custom:grade"],
            fmsectionName: item?.sectionName,
            user_id: item?.user_id,
            tobranchId: item?.branchId,
            toboardId: item?.boardId,
            toclassId: item?.classId,
            tosectionId: item?.sectionId,
            tobranchName: item?.branchName,
            toboradName: item["custom:board"],
            toclassName: item?.classId,
            tosectionName: item?.sectionName,
            name: item?.given_name,
            phoneNumber: item?.phone_number,
            academicYear: item?.academicYear,
            transferType: transferType,
            orgId: item?.orgId
        }));

        this.setState({ rowData: formattedData[0] }, () => {
            console.log(this.state?.rowData, "ROW");
        });
    }


    onSelectionChange = (event) => {


        const value = event.value;


        if (value.length) {
            console.log(event?.value, "event?.value")

            this.formatData(event?.value)
            this.setState({ selectedRows: event?.value })

        }

        let selectedAll;
        if (value.length > 0) {

            console.log(event?.value, "event?.valuealll")


            selectedAll = (value.length === this.state.users.length)

            this.setState({
                selectedTransferStudents: event.value,
                selectAll: selectedAll,
                select: true


            })

        } else {

            this.setState({
                selectedTransferStudents: [],
                selectAll: false,
                select: false


            })
        }


    }

    onSelectAllChange = (event) => {
        const selectAll = event.checked;
        if (selectAll) {
            this.setState({
                selectedTransferStudents: this.state.users,
                selectAll: true
            })
        } else {
            this.setState({
                selectedTransferStudents: [],
                selectAll: false
            })
        }
    };
    // onTransferSave = () => {

    //     this.setState({ isLoading: true })

    //     let payload;
    //     if (this.state.selectedTransferStudents?.length) {
    //         if (this.state.selectedTransferStudents?.length && this.state.transfer === 'Section') {
    //             payload = this.state.selectedTransferStudents.map(student => ({
    //                 fmbranchId: student?.user_info?.branchId,
    //                 fmboardId: student?.user_info?.boardId,
    //                 fmclassId: student?.user_info?.classId,
    //                 fmsectionId: student?.user_info?.sectionId,
    //                 fmbranchName: student?.user_info?.branchName,
    //                 fmboradName: student?.user_info["custom:board"],
    //                 fmclassName: student?.user_info["custom:grade"],
    //                 fmsectionName: student?.user_info?.sectionName,
    //                 user_id: student?.user_id,
    //                 status: "PENDING", // or "inactive" based on your requirement
    //                 tobranchId: this.state.dropdownsDataDilogData?.branchId !== null ? this.state.dropdownsDataDilogData?.branchId : student?.user_info?.branchId,
    //                 toboardId: this.state.dropdownsDataDilogData?.boardId !== null ? this.state.dropdownsDataDilogData?.boardId : student?.user_info?.boardId,
    //                 toclassId: this.state.dropdownsDataDilogData?.classId !== null ? this.state.dropdownsDataDilogData?.classId : student?.user_info?.classId,
    //                 tosectionId: this.state.dropdownsDataDilogData?.sectionId !== undefined ? this.state.dropdownsDataDilogData?.sectionId : student?.user_info?.sectionId,
    //                 tobranchName: this.state.dropdownsDataDilogData?.selectedbranchName !== undefined ? this.state.dropdownsDataDilogData?.selectedbranchName : student?.user_info?.branchName,
    //                 toboradName: this.state.dropdownsDataDilogData?.selectedBoardName !== undefined ? this.state.dropdownsDataDilogData?.selectedBoardName : student?.user_info["custom:board"],
    //                 toclassName: this.state.dropdownsDataDilogData?.selectedClassName !== undefined ? this.state.dropdownsDataDilogData?.selectedClassName : student?.user_info["custom:grade"],
    //                 tosectionName: this.state.dropdownsDataDilogData?.selectedsectionName !== undefined ? this.state.dropdownsDataDilogData?.selectedsectionName : student?.user_info?.sectionName,
    //                 name: student?.user_info?.given_name,
    //                 phoneNumber: student?.user_info?.phone_number,
    //                 academicYear: student?.user_info?.academicYear,
    //                 transferType: this.state.transfer,
    //                 requestRaisedBy: localStorage.getItem('userId'),
    //                 requestRaisedByName: localStorage.getItem('username')
    //             }));
    //         } else if (this.state.selectedTransferStudents?.length && this.state.transfer === 'Branch') {
    //             payload = this.state.selectedTransferStudents.map(student => {
    //                 const fmbranchId = student?.user_info?.branchId;
    //                 const tobranchId = this.state.dropdownsDataDilogData?.branchId;
    //                 if (fmbranchId === tobranchId) {
    //                     this.setState({ isLoading: false })
    //                     this.toast.show({ severity: 'error', summary: `Cannot transfer student ${student?.user_info?.given_name}: Source and destination branches are the same`, life: 3000 });
    //                     return
    //                 }
    //                 return {
    //                     fmbranchId: fmbranchId,
    //                     fmboardId: student?.user_info?.boardId,
    //                     fmclassId: student?.user_info?.classId,
    //                     fmsectionId: student?.user_info?.sectionId,
    //                     fmbranchName: student?.user_info?.branchName,
    //                     fmboradName: student?.user_info["custom:board"],
    //                     fmclassName: student?.user_info["custom:grade"],
    //                     fmsectionName: student?.user_info?.sectionName,
    //                     user_id: student?.user_id,
    //                     status: "PENDING", // or "inactive" based on your requirement
    //                     tobranchId: tobranchId,
    //                     toboardId: this.state.dropdownsDataDilogData?.boardId,
    //                     toclassId: this.state.dropdownsDataDilogData?.classId,
    //                     tosectionId: this.state.dropdownsDataDilogData?.sectionId,
    //                     tobranchName: tobranchId && this.props.branchData.find(e => e.key == tobranchId)?.name,
    //                     toboradName: this.state.dropdownsDataDilogData?.selectedBoardName,
    //                     toclassName: this.state.dropdownsDataDilogData?.selectedClassName,
    //                     tosectionName: this.state.dropdownsDataDilogData?.selectedsectionName,
    //                     name: student?.user_info?.given_name,
    //                     phoneNumber: student?.user_info?.phone_number,
    //                     academicYear: student?.user_info?.academicYear,
    //                     transferType: this.state.transfer,
    //                     requestRaisedBy: localStorage.getItem('userId'),
    //                     requestRaisedByName: localStorage.getItem('username')
    //                 };
    //             });

    //         }

    //         let url = `${baseUrlAdmin}/transfers/save`
    //         this.service.post(url, payload, true).then(res => {
    //             if (res?.status && res?.res?.status) {
    //                 this.setState({
    //                     isTransferDialogOpen: false,
    //                     selectedTransferStudents: '',
    //                     transfer: '',
    //                     selectAll: false,
    //                     select: false,
    //                     dropdownsDataDilogData: {},
    //                     isLoading: false

    //                 }, () => { this.filterUsersData() })

    //                 this.toast.show({ severity: 'success', summary: res?.res?.message, detail: res.errMessage, life: 3000 });

    //             } else {
    //                 this.setState({ isLoading: false })
    //                 this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
    //             }
    //         }).catch(err => {
    //             this.setState({ isLoading: false })
    //             this.toast.show({ severity: 'error', summary: 'Some error occured', detail: err.message, life: 3000 });
    //         })
    //     } else {
    //         this.setState({ isLoading: false })
    //         this.toast.show({ severity: 'error', summary: `Assign Records shouldn't not empty`, detail: "Please select Atleast one record", life: 3000 });

    //     }
    // }


    onTransferSave = () => {
        this.setState({ isLoading: true });
        if (!this.state.selectedTransferStudents?.length) {
            this.setState({ isLoading: false });
            this.toast.show({ severity: 'error', summary: `Assign Records shouldn't be empty`, detail: "Please select at least one record", life: 3000 });
            return;
        }
        const createPayload = (student) => {
            const { user_info } = student;
            const { branchId, boardId, classId, sectionId, branchName, phone_number, academicYear, given_name,username } = user_info;
            const toData = this.state.dropdownsDataDilogData;

            return {
                fmbranchId: branchId,
                fmboardId: boardId,
                fmclassId: classId,
                fmsectionId: sectionId,
                fmbranchName: branchName,
                fmboradName: user_info["custom:board"],
                fmclassName: user_info["custom:grade"],
                fmsectionName: user_info.sectionName,
                user_id: student?.user_id,
                status: "PENDING",
                tobranchId: toData?.branchId ?? branchId,
                toboardId: toData?.boardId ?? boardId,
                toclassId: toData?.classId ?? classId,
                tosectionId: toData?.sectionId ?? sectionId,
                tobranchName: toData?.selectedbranchName ?? branchName,
                toboradName: toData?.selectedBoardName ?? user_info["custom:board"],
                toclassName: toData?.selectedClassName ?? user_info["custom:grade"],
                tosectionName: toData?.selectedsectionName ?? user_info.sectionName,
                name: username,
                phoneNumber: phone_number,
                academicYear: academicYear,
                transferType: this.state.transfer,
                requestRaisedBy: localStorage.getItem('userId'),
                requestRaisedByName: localStorage.getItem('username')
            };
        };

        let payload;
        if (this.state.transfer === 'Section') {
            payload = this.state.selectedTransferStudents.map(student => {
                const fmsectionId = student?.user_info?.sectionId;
                const tosectionId = this.state.dropdownsDataDilogData?.sectionId || '';
                if (tosectionId.length) {
                    if (fmsectionId === tosectionId) {
                        this.setState({ isLoading: false });
                        this.toast.show({ severity: 'error', summary: `Cannot transfer student ${student?.user_info?.given_name}: Source and destination sections are the same`, life: 3000 });
                        return null;
                    }
                } else {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: `Please select proper section for transfer ${student?.user_info?.given_name}`, life: 3000 });
                    return null;
                }

                if (student?.user_info?.stage == 'INTRANSIT') {
                    this.setState({ isLoading: false });

                    this.toast.show({ severity: 'error', summary: `Already in transit ${student?.user_info?.given_name}`, life: 3000 });
                    return null;
                }

                return createPayload(student);
            }).filter(Boolean);
        }
        else if (this.state.transfer === 'Branch') {
            const branchId = this.state.dropdownsDataDilogData?.branchId;
            payload = this.state.selectedTransferStudents.map(student => {
                const fmbranchId = student?.user_info?.branchId;
                if (fmbranchId === branchId) {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: `Cannot transfer student ${student?.user_info?.given_name}: Source and destination branches are the same`, life: 3000 });
                    return null;
                }
                if (student?.user_info?.stage == 'INTRANSIT') {
                    this.setState({ isLoading: false });

                    this.toast.show({ severity: 'error', summary: `Already intransit  ${student?.user_info?.given_name}`, life: 3000 });
                    return null;
                }
                return createPayload(student);
            }).filter(Boolean);
        }
        if (payload?.length) {
            const url = `${baseUrlAdmin}/transfers/save`;
            this.service.post(url, payload, true)
                .then(res => {
                    if (res?.status && res?.res?.status) {
                        this.setState({
                            isTransferDialogOpen: false,
                            selectedTransferStudents: '',
                            transfer: '',
                            selectAll: false,
                            select: false,
                            dropdownsDataDilogData: {},
                            isLoading: false
                        }, this.filterUsersData);

                        this.toast.show({ severity: 'success', summary: res?.res?.message, detail: res.errMessage, life: 3000 });
                    } else {
                        this.setState({ isLoading: false });
                        this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: err.message, life: 3000 });
                });
        } else {
            this.setState({ isLoading: false });
        }
    };




    getUsersFee = () => {

        this.setState({
            isLoading: true,
            totalFee: 0
        });
        let payload;
        if (this.state.select) {
            payload = this.state.selectedTransferStudents.map(item => {
                return { user_id: item.user_id }
            })
        } else {
            payload = this.state.selectedRows.map(item => {
                return { user_id: item.user_id }
            })
        }



        const url = `${baseUrlAdmin}/transfers/branch/fee`;

        this.service.post(url, payload, true).then(res => {
            if (res && res.status && res.res.status) {

                this.setState({
                    isLoading: false,
                    totalFee: res.res?.totalFee,

                });

            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            console.log(e);
        })
    }


    render() {
        //console.log(this.state.searchValue, "f", this.state.searchType, "studentName", "enrollmentNumber")
        const header = (
            <div className="flex justify-content-between bg-white">
                <div className="flex">
                    {this.state.showSearch && <InputText placeholder="Search" className="ml-4  h-2rem w-15rem"
                        value={this.state.globalSearch}
                        onChange={this.onGlobalSearch} />}
                </div>


            </div>
        );
        // Filter Branch data in Branch transfer based on the selected student's branches
        const filteredBranchData = this.props?.branchData?.filter(branch => {
            for (let i = 0; i < this.state.selectedRows.length; i++) {
                if (this.state.selectedRows[i].branchId === branch.key) {
                    return false; // Exclude this branch
                }
            }
            return true; // Include this branch
        });


        return (
            <div>


                <div className='ma-main  '>

                    <div className=" datatable-crud-demo ma-m30 mx-2 mb-5">
                        <div className="flex">
                            <div className="flex flex-column justify-content-center align-items-center mt-3">
                                <RadioButton inputId="ingredient1" onChange={(e) => this.handleSearchLevel(1)} checked={this.state.searchLevel === 1} />
                            </div>

                            <div>
                                {/* <BranchToSectionMultiDDPreFill setValues={this.setDropdownValues} /> */}
                                <BranchToSectionMultiDDPreFill selectedValue={this.state.searchLevel} setValues={this.setDropdownValues} />
                            </div>
                        </div>
                        <div style={{ marginLeft: "100px" }} className="flex justify-content-start align-items-center">
                            <span className="">OR</span>
                        </div>
                        {/* <SearchBar filterData={this.state.filterData} filterUsersData={this.onSearchClick} /> */}
                        <div className="flex mt-4">
                            <div className="flex flex-column justify-content-center align-items-center -mt-3">
                                <RadioButton inputId="ingredient2" onChange={(e) => this.handleSearchLevel(2)} checked={this.state.searchLevel === 2} />
                            </div>

                            <div className="field ml-4">
                                <ClassetStudentSearch selectedValue={this.state.searchLevel} handleTypeValue={this.handleSetSearchTypeValue} handleSearchValue={this.handleSetSearchValue} />
                            </div>
                        </div>
                    </div>

                    <div className="card datatable-crud-demo ma-m30  m-2">
                        <div className="flex justify-content-end">
                            <Authorizer permId={PERMISSIONS.STUDENT_TRANSFER} >
                                <Button style={{ border: '1px solid #E6E7EA', marginLeft: "20px", backgroundColor: '#076eff', padding: '9px 18px', borderRadius: "15px", color: "white" }} label="Transfer" className='p-button-outlined text-xl button' onClick={() => this.setState({ isTransferDialogOpen: true, rowData: this.state?.rowData, branchId: this.state.rowData?.tobranchId })} disabled={!(this.state.selectAll || this.state.select)} />
                            </Authorizer>
                        </div>

                        <DataTable ref={(el) => this.dt = el}
                            value={this.state.users}
                            dataKey="_id"
                            paginator
                            lazy
                            className="dataTableValuesText"
                            responsiveLayout="scroll"
                            showGridlines
                            columnResizeMode="expand"
                            resizableColumns
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}
                            selection={this.state?.selectedTransferStudents}
                            onSelectionChange={this.onSelectionChange}
                            selectAll={this.state.selectAll}
                            onSelectAllChange={this.onSelectAllChange}
                            selectionMode="checkbox"
                        >

                            <Column selectionMode="multiple" style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' exportable={false}></Column>

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="given_name" field="given_name" header="Student Name" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="username" field="username" header="User Name" />

                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="branchId" field="branchName" header="Branch" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="custom:board" field="custom:board" header="Board" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="custom:grade" field="custom:grade" body={this.gradeSectionBodyTemplate} header="Grade-Section" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="phone_number" field="phone_number" header="Phone Number" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="email" field="email" header="Email" />
                            <Column style={{ width: "15rem", height: "6rem" }} alignHeader={"center"} headerClassName="text-color-secondary" bodyClassName='text-center' filterField="status" field="stage" header="Stage" />




                        </DataTable>

                        <Toast ref={(el) => this.toast = el} position="bottom-right" />
                        <Dialog
                            visible={this.state.isTransferDialogOpen}
                            draggable={false}
                            closeOnEscape={false}
                            className=''
                            style={{}}
                            dismissableMask={false}
                            closable={true}
                            header={() => {
                                return (<div className='mt-2 formHeadingInter'>
                                    <RightMarkInCircle />
                                    <p style={{ fontWeight: 'bold', fontSize: 20 }}>Transfer Student</p>
                                </div>)
                            }}
                            footer={() => (
                                <div className='flex justify-content-center mb-4'>
                                    <Button className='formSubmitButton px-4' style={{ borderRadius: '10px' }} onClick={() => { this.onTransferSave() }} >
                                        <p className='formSubmitButtonText'>Raise Transfer Request</p>
                                    </Button>
                                    <Button className='formCancelButton px-4' style={{ backgroundColor: '#F4F5F6', border: 0 }} onClick={() => this.setState({ isTransferDialogOpen: false, transfer: '' })} >
                                        <p className='formCancelButtonText'>Cancel</p>
                                    </Button>
                                </div>
                            )}
                            onHide={() => this.setState({ isTransferDialogOpen: false, transfer: '' })}
                        >

                            <div className=" m-3 pt-3" style={{ alignItems: 'center', justifyContent: 'center' }}>

                                <div className="">
                                    <div className="flex">
                                        <div className="flex">
                                            <div className="flex mr-4">
                                                <RadioButton
                                                    inputId="branchTransfer" name="transfer" value="Branch" onChange={(e) => this.setTransfer(e.value)} checked={this.state?.transfer === 'Branch'} />
                                                <label htmlFor="branchTransfer" className="ml-2 label">Branch Transfer</label>

                                            </div>
                                            <div className="flex">
                                                <RadioButton
                                                    inputId="sectionTransfer" name="transfer" value="Section" onChange={(e) => this.setTransfer(e.value)} checked={this.state.transfer === 'Section'} />
                                                <label htmlFor="sectionTransfer" className="ml-2 label">Section Transfer</label>

                                            </div>
                                        </div>
                                    </div>
                                    {(this.state.transfer === 'Branch') &&
                                        <div className="">
                                            <div className='mt-2'>
                                                {/* <ClassetDropdown required={true} label={'Branch'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Branch'} options={filteredBranchData}
                                                    value={this.state.branchId} onChange={(e) => this.setState({ branchId: e.value })} optionLabel="name"
                                                    optionValue="key" /> */}
                                                <BranchToSectionDDCol setValues={this.setDropdownValuesDilog} rowData={this.state?.rowData} disable={0} />

                                            </div>


                                        </div>}

                                    {this.state.transfer === 'Section' &&
                                        <>
                                            {/* <BranchToSectionDDCol setValues={this.setDropdownValuesDilog} rowData={this.state?.rowData} disable={3} /> */}
                                            <BranchToSectionDDColSEc setValues={this.setDropdownValuesDilog} rowData={this.state?.rowData} />


                                        </>
                                    }




                                    {/* {(this.state.transfer === 'Branch' || this.state.transfer === '') &&

                                        <div className="my-2">

                                            <ClassetInputText
                                                label={'Transfer Fee'}
                                                icon={<MailIcon />}
                                                value={this.state?.totalFee}
                                                disabled={this.state.transfer === 'Branch'}
                                                //onChange={(e) => this.setState({ transferFee: e.value })}

                                                placeholder='Transfer Fee' />

                                        </div>} */}
                                </div>
                            </div>

                        </Dialog>
                        {this.state.isLoading && <LoadingComponent />}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => ({

    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
});
export default connect(mapStatesToProps, {
    getBranchesLatest
})(Authentication(withRouter(StudentTransfer)));