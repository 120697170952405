import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormFields, isFormValid, onTextChange, onDropDownChange } from '../../../utile/formHelper';
import _, { cloneDeep } from 'lodash';
import { BreadCrumb } from 'primereact/breadcrumb';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import InputTextB from '../../customComponents/inputTextB';
import Service from './../../services';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { getBoardsData, getBranchesLatest } from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import withRouter from '../../lib/withRouter';
import LoadingComponent from '../../loadingComponent';
import { DeleteIconClasset, LocationMarker, MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import ClassetCalendar from '../../../classetComponents/classetCalender';
const createRouteFields = require('./createRoute.json');
let formFields = createRouteFields;

class CreateRoute extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createRouteFields);
        this.state = {
            route: this.props?.dataForAddRoutes?.id ? this.props?.dataForAddRoutes : this.formFields.data,
            formValidations: this.formFields.formValidations,
            stopsList: [],
            focused: false,
            inputFocused: false,
            boards: [],
            classes: [],
            academicYears: [],
            vehicleDropdowns: [],
            feeTypeDropDown: [],
            stops: [{ id: 1, name: '', lat: '', lng: '', pickUpTime: '', dropTime: '', transportFee: '' }],
            isShowCreateSuccess: false,
            hideUpdateButton: false
        };

        this.service = new Service();
    }

    componentDidMount() {
        if (this.props.dataForAddRoutes?._id) {
            const modifiedStops = this.props.dataForAddRoutes?.stops.map((stop) => {
                return {
                    ...stop,
                    dropTime: new Date(stop.dropTime),
                    pickUpTime: new Date(stop.pickUpTime)
                };
            });
            this.setState(
                {
                    route: {
                        branchId: this.props.dataForAddRoutes.branchId,
                        vehicleId: this.props.dataForAddRoutes.vehicleId,
                        routeName: this.props.dataForAddRoutes.routeName,
                        registration_number: this.props.dataForAddRoutes.vehicleId,
                        routeNo: this.props.dataForAddRoutes.routeNo
                    },
                    stops: modifiedStops
                },
                () => this.getDropdownsData(true)
            );
        }
    }

    getDropdownsData = async (editMode) => {
        this.setState({
            isLoading: true
        });
        const academicYear = localStorage.getItem('userAcademicYear');
        let url = `${baseUrlAdmin}/transport-route/meta-data?academicYear=${academicYear}`;
        let payload = {
            branchId: this.state.route.branchId
        };
        try {
            const res = await this.service.post(url, payload, true);
            if (res?.res?.status && res.status) {
                const vehicles = res?.res?.data?.vehicles?.map((vehicles) => ({
                    label: vehicles.vehicleName,
                    value: vehicles._id,
                    registrationNumber: vehicles.vehicleRegistrationNo
                }));
                const fee = res?.res?.data?.feePlans?.map((fee) => ({
                    label: fee.feeName,
                    value: fee._id
                }));
                this.setState({ vehicleDropdowns: vehicles, feeTypeDropDown: fee, isLoading: false }, () => {
                    if (editMode) {
                        this.setState((prevState) => {
                            return {
                                route: {
                                    ...prevState.route,
                                    vehicleId: this.props.dataForAddRoutes.vehicleId
                                },
                                isLoading: false
                            };
                        });
                    }
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occurred', life: 3000 });
            }
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'some Error', life: 3000 });
        }
    };

    isValid = (value) => value !== undefined && value !== null && value !== '';

    onCreateRoute = async () => {

        this.setState({
            isLoading: true
        });
        const academicYear = localStorage.getItem('userAcademicYear');
        const fieldData = cloneDeep(this.state.route);
        if (this.props?.dataForAddRoutes?._id) {
            const stopData = this.state?.stops || [];
            let timeCheckFlag = true
            const stopsForApi = stopData?.map((stop) => {
                const pickUpTime = new Date(stop.pickUpTime);
                const dropTime = new Date(stop.dropTime);

                if (pickUpTime >= dropTime) {
                    timeCheckFlag = false
                }
                let stopObject = {
                    areaName: stop.areaName,
                    stopName: stop.stopName,
                    pickUpTime: pickUpTime,
                    dropTime: dropTime,
                    locationLink: stop.locationLink,
                    transportFee: Number(stop.transportFee)
                };
                if (stop._id) {
                    stopObject._id = stop._id;
                }
                return stopObject
            }).filter(stop => stop !== null);
            if (!timeCheckFlag) {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'PickUp time must be earlier than Drop time.', life: 3000 });
                return;
            }
            stopsForApi.sort((a, b) => {
                return new Date(a.pickUpTime) - new Date(b.pickUpTime);
            });
            const allValid = stopsForApi?.every((stop) => Object.values(stop).every(this.isValid));
            if (!allValid) {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please enter all the required fields', life: 3000 });
                return;
            }
            const payloadData = {
                routeId: this.props?.dataForAddRoutes?._id,
                stop: stopsForApi || [],
                academicYear: academicYear,
                branchId: this.props?.dataForAddRoutes?.branchId,
            };
            let url = `${baseUrlAdmin}/transport-route/stops/add`;
            try {
                const { stops } = this.state;
                const allFieldsFilled = stops.every(stop =>
                    stop.areaName && stop.stopName && stop.locationLink && stop.pickUpTime && stop.dropTime && stop.transportFee
                );
                let isTimeValid = stops.every((stop) => {
                    return this.isPickUpTimeLessThanDropTime(stop);
                });
                if (allFieldsFilled && isTimeValid) {
                } else {
                    if (!allFieldsFilled) {

                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please fill all the fields in existing stops', life: 3000 });
                        throw new Error('Please fill all the fields in existing stops');

                    } else {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'PickUp time must be earlier than Drop time.', life: 3000 });
                        throw new Error('PickUp time must be earlier than Drop time');

                    }
                }
                const res = await this.service.post(url, payloadData, true);
                if (res?.res?.status && res.status) {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
                    this.props.onCancel();
                    this.props.getRoutesData();
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                }
            } catch (e) {
                this.setState({
                    isLoading: false
                });
                console.log(e);
                this.toast.show({ severity: 'error', summary: e.message || e, detail: 'Some error occured', life: 3000 });
            }
        } else {
            let body = cloneDeep(this.state.route);
            const stopData = this.state?.stops || [];
            const stopsForApi = stopData?.map((stop) => ({
                areaName: stop.areaName,
                stopName: stop.stopName,
                pickUpTime: stop.pickUpTime,
                dropTime: stop.dropTime,
                locationLink: stop.locationLink,
                transportFee: Number(stop.transportFee)
            }));
            stopsForApi.sort((a, b) => {
                return new Date(a.pickUpTime) - new Date(b.pickUpTime);
            });
            const formStatus = isFormValid(createRouteFields, this.formFields.formValidations, body);

            if (!formStatus.formValidations.isFormValid) {
                this.setState({ formValidations: formStatus.formValidations, isLoading: false });
            } else {
                const payload = {
                    vehicleId: body?.vehicleId,
                    routeName: body?.routeName,
                    stops: stopsForApi || [],
                    academicYear: academicYear,
                    branchId: body?.branchId,
                    routeNo: body?.routeNo,
                };

                let url = `${baseUrlAdmin}/transport-route?academicYear=${academicYear}`;
                try {
                    const { stops } = this.state;
                    const allFieldsFilled = stops.every(stop =>
                        stop.areaName && stop.stopName && stop.locationLink && stop.pickUpTime && stop.dropTime && stop.transportFee
                    );
                    let isTimeValid = stops.every((stop) => {
                        return this.isPickUpTimeLessThanDropTime(stop);
                    });
                    if (allFieldsFilled && isTimeValid) {
                    } else {
                        if (!allFieldsFilled) {
                            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please fill all the fields in existing stops', life: 3000 });
                            throw new Error('Please fill all the fields in existing stops');

                        } else {
                            this.toast.show({ severity: 'error', summary: 'Error', detail: 'PickUp time must be earlier than Drop time.', life: 3000 });
                            throw new Error('PickUp time must be earlier than Drop time');

                        }
                    }
                    const res = await this.service.post(url, payload, true);
                    if (res?.res?.status && res.status) {
                        this.setState({
                            isLoading: false
                        });
                        this.props.toastMessage.show({ severity: 'success', summary: 'Created successfully', detail: res?.res?.message, life: 3000 });
                        this.props.onCancel();
                        this.props.getRoutesData();
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.props.toastMessage.show({ severity: 'error', summary: 'Error', detail: 'Some error occured', life: 3000 });
                    }
                } catch (e) {
                    this.setState({
                        isLoading: false
                    });
                    console.log(e);
                    this.props.toastMessage.show({ severity: 'error', summary: e.message || e, detail: 'Some error occured', life: 3000 });
                }
            }
        }
    };
    isPickUpTimeLessThanDropTime = (stop) => {
        const pickUpDate = new Date(stop.pickUpTime);
        const dropDate = new Date(stop.dropTime);

        // Extract hours and minutes
        const pickUpTime = pickUpDate.getHours() * 60 + pickUpDate.getMinutes();
        const dropTime = dropDate.getHours() * 60 + dropDate.getMinutes();

        return pickUpTime < dropTime;
    };
    addStop = () => {
        const { stops } = this.state;
        const allFieldsFilled = stops.every(stop =>
            stop.areaName && stop.stopName && stop.locationLink && stop.pickUpTime && stop.dropTime && stop.transportFee
        );
        let isTimeValid = stops.every((stop) => {
            return this.isPickUpTimeLessThanDropTime(stop);
        });
        if (allFieldsFilled && isTimeValid) {
            const newStop = { id: this.state.stops.length + 1, areaName: '', stopName: "", locationLink: "", pickUpTime: '', dropTime: '', transportFee: '' };
            this.setState((prevState) => ({
                stops: [...prevState.stops, newStop]
            }));
        } else {
            if (!allFieldsFilled) {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Please fill all the fields in existing stops', life: 3000 });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'PickUp time must be earlier than Drop time.', life: 3000 });
            }
        }
    };

    removeStop = (index) => {
        if (this.state.stops.length > 1) {
            const stops = this.state.stops.filter((_, i) => i !== index);
            this.setState({ stops });
        }
    };

    handleInputChange = (index, field, value) => {
        const stops = [...this.state.stops];
        stops[index][field] = value;

        this.setState({ stops });
    };
    handleFocus = () => {
        this.setState({ focused: true });
    };

    handleBlur = () => {
        this.setState({ focused: false });
    };

    handleInputFocus = () => {
        this.setState({ inputFocused: true });
    };

    handleInputBlur = () => {
        this.setState({ inputFocused: false });
    };

    render() {
        const { route, formValidations } = this.state;
        const { onCancel } = this.props;
        return (
            <>
                <div className="mt-3">
                    <div className="grid" style={{ gap: '0.2rem', margin: '0' }}>
                        <div className="col" style={{ padding: '0.2rem' }}>
                            <p className="add-vehicle-field-label">
                                Branch<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                    optionLabel="name"
                                    optionValue="key"
                                    width="16vw"
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    value={route?.branchId}
                                    options={this.props.branchData}
                                    onChange={async (e) => {
                                        await onDropDownChange(e.target.value, 'branchId', this, formFields, route, formValidations, 'route', 'formValidations');
                                        await this.getDropdownsData(false);
                                    }}
                                    placeholder={
                                        <div className="flex justify-content-start align-items-center">
                                            <span className="">Select Branch</span>
                                        </div>
                                    }
                                />
                            </div>
                            {formValidations && !formValidations.fields['branchId'].isValid && <p className="p-error">{formValidations.fields['branchId'].errorMsg}</p>}
                        </div>
                        <div className="col" style={{ padding: '0.2rem' }}> {/* Adjust padding */}
                            <p className="add-vehicle-field-label">
                                Vehicle Name<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetDropdown
                                    onBlur={this.handleBlur}
                                    width="16vw"
                                    placeholder="Select Vehicle Name"
                                    value={route.vehicleId}
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    options={this.state.vehicleDropdowns}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        onDropDownChange(selectedValue, 'vehicleId', this, formFields, route, formValidations, 'route', 'formValidations');
                                        const selectedVehicle = this.state.vehicleDropdowns.find((vehicle) => vehicle.value === selectedValue);
                                        if (selectedVehicle) {
                                            route['registration_number'] = selectedVehicle.registrationNumber;
                                            this.setState({
                                                route: { ...route, vehicleId: selectedValue }
                                            });
                                        }
                                    }}
                                />
                            </div>
                            {!this.props?.dataForAddRoutes?._id && formValidations && !formValidations.fields['vehicleId'].isValid && <p className="p-error">{formValidations.fields['vehicleId'].errorMsg}</p>}
                        </div>

                        <div className="col" style={{ padding: '0.2rem' }}> {/* Adjust padding */}
                            <p className="add-vehicle-field-label">
                                Route Name<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    onClick={this.handleInputFocus}
                                    width="16vw"
                                    onBlur={this.handleInputBlur}
                                    style={{ border: 'none' }}
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    value={route.routeName}
                                    onChange={(e) => onTextChange(e.target.value, 'routeName', this, formFields, route, formValidations, 'route', 'formValidations')}
                                    placeholder="Route Name"
                                />
                            </div>
                            {!this.props?.dataForAddRoutes?._id && formValidations && !formValidations.fields['routeName'].isValid && <p className="p-error">{formValidations.fields['routeName'].errorMsg}</p>}
                        </div>
                        <div className="col" style={{ padding: '0.2rem' }}> {/* Adjust padding */}
                            <p className="add-vehicle-field-label">
                                Registered Vehicle Number<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    width="16vw"
                                    icon={<MailIcon width={24} height={24} color={'black'} />}
                                    value={this.props?.dataForAddRoutes?._id ? this.props?.dataForAddRoutes?.vehicleRegistrationNo : route.registration_number}
                                    disabled={true}
                                    onChange={(e) => onTextChange(e.target.value, 'registration_number', this, formFields, route, formValidations, ' route', 'formValidations')}
                                    placeholder="Registered Vehicle Number"
                                />
                            </div>
                        </div>
                        <div className="col" style={{ padding: '0.2rem' }}> {/* Adjust padding */}
                            <p className="add-vehicle-field-label">
                                Route No<span className="ma-required">*</span>
                            </p>
                            <div className="flex">
                                <ClassetInputText
                                    onClick={this.handleInputFocus}
                                    width="16vw"
                                    onBlur={this.handleInputBlur}
                                    style={{ border: 'none' }}
                                    disabled={this.props?.dataForAddRoutes?._id ? true : false}
                                    value={route.routeNo}
                                    onChange={(e) => onTextChange(e.target.value, 'routeNo', this, formFields, route, formValidations, 'route', 'formValidations')}
                                    placeholder="Route No"
                                />
                            </div>
                            {!this.props?.dataForAddRoutes?._id && formValidations && !formValidations.fields['routeNo'].isValid && <p className="p-error">{formValidations.fields['routeNo'].errorMsg}</p>}
                        </div>
                    </div>
                    <div style={{ height: '2px', marginTop: "5px", backgroundColor: '#B1B1B1' }}></div>

                    {this.state.stops.map((stop, index) => (
                        <div className='flex'>
                            <div key={index} className="grid lg:col-11 mx-0 mt-5 border-top-1 border-left-2 border-right-1 border-bottom-2 border-300 border-round-lg overflow-hidden">
                                <div className="col-12 md:col-3">
                                    <p className="add-vehicle-field-label">
                                        Area Name<span className="ma-required">*</span>
                                    </p>
                                    <ClassetInputText width="100%" value={stop.areaName} onChange={(e) => this.handleInputChange(index, 'areaName', e.target.value)} placeholder="Area Name" />
                                </div>

                                <div className="col-12 md:col-3">
                                    <p className="add-vehicle-field-label">
                                        Stop Name<span className="ma-required">*</span>
                                    </p>
                                    <ClassetInputText width="100%" value={stop.stopName} onChange={(e) => this.handleInputChange(index, 'stopName', e.target.value)} placeholder="Stop Name" />
                                </div>

                                <div className="col-12 md:col-3">
                                    <p className="add-vehicle-field-label">
                                        Location Link<span className="ma-required">*</span>
                                    </p>
                                    <ClassetInputText icon={<LocationMarker />} width="100%" value={stop.locationLink} onChange={(e) => this.handleInputChange(index, 'locationLink', e.target.value)} placeholder="Location Link" />
                                </div>

                                <div className="col-12 md:col-3">
                                    <p className="add-vehicle-field-label">
                                        Pick up time<span className="ma-required">*</span>
                                    </p>
                                    <ClassetCalendar width="100%" value={stop?.pickUpTime} calendarMode="single" onChange={(e) => this.handleInputChange(index, 'pickUpTime', e.value)} placeholder="Pick Up Time" timeOnly />
                                </div>

                                <div className="col-12 md:col-3">
                                    <p className="add-vehicle-field-label">
                                        Drop time<span className="ma-required">*</span>
                                    </p>
                                    <ClassetCalendar width="100%" calendarMode="single" value={stop?.dropTime} onChange={(e) => this.handleInputChange(index, 'dropTime', e.value)} placeholder="Drop Time" timeOnly hourFormat="24" />
                                </div>

                                <div className="col-12 md:col-3">
                                    <p className="add-vehicle-field-label">
                                        Transport Fee<span className="ma-required">*</span>
                                    </p>
                                    <ClassetInputText width="100%" value={stop.transportFee} onChange={(e) => this.handleInputChange(index, 'transportFee', e.target.value)} placeholder="Transport Fee" keyfilter="int" />
                                </div>
                            </div>
                            <div className='flex align-self-center align-items-center justify-content-center mx-4 md:mt-5 lg:mt-2 pt-1' >
                                {this.props?.dataForAddRoutes?._id ? <></> : <Button style={{ borderRadius: 50, backgroundColor: '#C6CED2', border: 'none', color: '#fff' }} className=" p-3 font-semibold ml-4 mt-4" onClick={() => this.removeStop(index)}>
                                    <DeleteIconClasset height={"22"} />
                                </Button>}

                            </div>
                        </div>
                    ))}
                    <Button label="Add Stop" icon={'pi pi-plus-circle text-black text-lg '} style={{ borderRadius: 12, backgroundColor: '#F4F5F6', border: 'none', color: 'black' }} className=" p-3 font-semibold ml-4 mt-4" onClick={this.addStop} />
                </div>

                <div className="text-center mt-8">
                    <Button label={this.props.dataForAddRoutes?._id ? 'Update Route' : 'Add Route'} className="confirmDialogAcceptBtn px-6 mr-5 w-19rem" onClick={this.onCreateRoute} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" onClick={onCancel} />
                </div>
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
                {(this.state.isLoading || this.props.isBranchLoading || this.props.isLoading) && (
                    <>
                        <LoadingComponent />
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        [],
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._id
});

export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(withRouter(CreateRoute));
