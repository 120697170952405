import React, { Component } from 'react';
import { connect } from 'react-redux';

export const PERMISSIONS_OLD = {

    // admin management--start
    SHOW_ROLES_LIST: '63540953-69ea-4d52-8aef-9cbbbed462ee',
    CREATE_ROLE: 'ca10d49e-837f-4641-abab-7743ec781532',
    EDIT_ROLE: '219c9f20-a2ac-486b-87f9-1e590880ec32',
    CREATE_ADMIN_USER: '2cfae971-bf22-48b7-be08-bf7e260c860f',
    EDIT_ADMIN_USER: 'b22b2a96-7a6e-4544-b9c7-dbe4eb0ec9fd',
    SHOW_USERS_LIST: 'a6ff97ce-587d-449a-a7e9-146ca1f6149c',
    ADMIN_MANAGEMENT_GENERAL_SEARCH: "48210bd9-7dd2-436c-8551-e5fe46d07c66",
    ADMIN_MANAGEMENT_GENERAL_DOWNLOADCSV: "70be0d22-9d61-46c8-b0fb-d8f10aed5e3d",
    ADMIN_MANAGEMENT_GENERAL_VIWECOLUMNS: "530c7477-ac3e-4559-b478-3946767f0150",
    ADMIN_SHOW_LOGS: "8c7e330e-fc50-4184-9d19-1276ee63a262",
    ADMIN_RESET_PASSWORD: "c5b6c71d-7ce4-4069-831a-7bb301abaf7e",
    // admin management--end


    //boards master--start
    ADD_BOARD: "e0399005-6458-4e0d-aff3-a115d851ed5e",
    EDIT_BOARD: "852bfd50-cf9d-4cb7-9fa7-c70f92c97cac",
    SHOW_CLASSES: "c09a0793-c29b-4d54-a53c-d95a35dd4e6a",
    BOARDS_SEARCH: "65240e85-1e0a-4c0a-8cf8-3dfbcd3a96db",
    BOARDS_DOWNLOADCSV: "7fddc63f-5a71-4af2-bcec-dfcbf76dc9ae",
    BOARDS_VIEWCOLUMNS: "526e46d2-5f14-4fd5-909b-65a7cf5dc9a9",
    ADD_CLASS: "ed872ea8-6f17-4802-bb8c-60871ccb647d",
    EDIT_CLASS: "9310031c-5e6f-4a63-9ffb-2c439fe512f1",
    //boards master--end


    //curriculum master--start
    CURRICULUM_SUBJECTS_LIST: "63d4f854-3f65-409c-b848-8e717799e5c6",
    CURRICULUM_ADD_SUBJECT: "20d906d5-f5ea-4530-80cd-86a44cc516ac",
    CURRICULUM_DOWNLOAD_DATA_FOR_BULKUPLOAD: "4b6efddd-3c6a-4f6d-882c-9802af7b7797",
    CURRICULUM_DOWNLOAD_DATA: "fe5bd867-484c-47da-a317-2a533361370d",
    CURRICULUM_EXPORTDATA_TO_OFFLINE: "ea58167d-dd71-48b2-b8ca-f445e032c539",
    CURRICULUM_EDIT_SUBJECT: "84f920b7-f5ca-4c8c-aa28-e7c866fd4499",
    CURRICULUM_GOTO_CHAPTERS: "3b930c5f-685c-45bf-8ec5-746cc3133773",
    CURRICULUM_SEARCH: "2b6d42fe-c45f-4376-a31c-92747fce5bae",
    CURRICULUM_DOWNLOADCSV: "038c5c5f-4e51-44ab-98cb-cd75926ca64d",
    CURRICULUM_VIEWCOLUMNS: "caba19cb-def3-4a0a-bb61-f75db1358e9d",
    CURRICULUM_CHAPTERS_ADD_CHAPTER: "22c80815-dd0d-4a57-9daf-4cb7caabfd0e",
    CURRICULUM_CHAPTERS_EDIT_CHAPTER: "41c13ad3-7789-4da3-b962-852cbcb265f9",
    CURRICULUM_CHAPTERS_GOTO_TOPICS: "919434ad-3529-4186-a8bb-c36f4cc4ea2d",
    CURRICULUM_TOPICS_ADD_TOPIC: "25c55c4c-cad3-469d-8506-3e78595e1bac",
    CURRICULUM_TOPICS_EDIT_TOPIC: "553b6035-05a6-43a7-a594-a14db078c6ad",
    CURRICULUM_TOPICS_VIEWRESOURCES: "698aa9b0-3dfe-4ef8-b70b-789ae7b1c3cc",
    CURRICULUM_ASSIGN_RESOURCE: "c4d6b296-bc42-4bc7-b5d3-b87e4d45a08d",
    CURRICULUM_SHOW_BOARDS: "65889596-4553-4c39-b51f-a1c812262738",
    CURRICULUM_ASSIGN_TO_SECTION: "506b207a-6023-40ba-8f9d-d77b188c225c",
    CURRICULUM_ASSIGN_TO_USERS: "e82392e3-31b5-4406-93cb-dbc5018a912a",
    CURRICULUM_ASSIGN_SUBJECTS: "57a6c7c3-2704-4ca8-bbc6-6b2264480721",
    //curriculum master--end



    //content master--start
    CONTENT_SELECTION_LIST: "440fd96a-e9b6-4735-badf-be965ee1da83",
    CONTENT_ADD: "a44968bb-95bf-4cc1-addc-bf289f83d6e6",
    CONTENT_SEARCH: "97b24ebf-009d-4300-9589-4a2418e52773",
    CONTENT_DOWNLOADCSV: "a167bd6e-da49-443a-a9b7-1f3a50ef8766",
    CONTENT_VIEWCOLUMNS: "1420e113-364e-4f50-ba90-54db77b700f5",
    CONTENT_PREVIEW: "6af29ec2-3d32-4b15-9494-172e21d192f5",
    CONTENT_CHANGE_PRIORITY: "f03343d9-3d34-43c9-9f3f-1b8c7fcd61aa",
    CONTENT_MORE_ACTIONS: "c1e8012b-ed2f-4363-a037-f752f331dc09",
    //content master--end

    //user management--start
    USER_ADDNEWUSER: "6acbffbf-cd65-4a96-a744-f3973537a582",
    USER_ASSIGNPACKAGE: "3ff0a818-a429-482b-b483-acf62655f320",
    USER_SHOWDETAILS: "030035b5-eaa2-429d-ae85-418eb6800a4d",
    USER_SEARCH: "9af201cb-4e63-47e7-8236-5e0e9d46ffef",
    USER_DOWNLOADCSV: "933d84db-d726-4fdb-9615-b90ba4abf296",
    USER_PRINT: "5fdb6caa-9d06-4e52-9c04-426227772212",
    USER_VIEWCOLUMNS: "fe577621-f03e-4f1f-8e0a-57e7408f3515",
    USER_FILTERS: "ceff3c51-666a-4ed3-b48a-0ba1e6eae309",
    USER_EDITUSER: "70b9cd57-7422-4c02-8bbe-5203dbcdedeb",
    USER_VIEW_ANALYTICS: "0370802c-88c0-4388-9487-420ff93768d1",
    USER_BULK_USERS_UPLOAD: "c5002f34-c14c-47f9-8c75-b505b66a435d",
    USER_SEARCH_BY_USERNAME: "1b1168d7-0375-405e-9674-d3a292a80956",
    USER_SEARCH_BY_DATE: "f06df642-830e-4aa0-b3df-aa47b01ab26a",
    USER_CHANGE_STATUS: "9af70fd3-9d34-4cd9-a375-c79e2fdb9449",
    //user management--end


    // organization master--start
    CREATE_ORGANIZATION: "0e4a49e9-4d5e-4a87-a279-4b8f8780beb1",
    EDIT_ORGANIZATION: "f4da10ff-7fe0-4517-b5aa-bb5a22d736a2",
    CHANGE_ORG_STATUS: "3389bd39-49f6-4542-8463-69e6226e1e03",
    VIEW_ORGANIZATION: "7386eaab-3ec3-4fda-836f-e7128b7b1a17",
    ORG_SEARCH: "5ae2446a-f501-47d2-b0cc-a5e095092874",
    ORG_VIEW_COLUMNS: "e2faa82b-6b47-40ba-96f8-4f38d364a510",
    ORG_DOWNLOAD: "d685d59d-fb42-434c-bbd5-7841019dbb6a",
    // organization master--end

    //branch master--start
    BRANCH_MASTER_LEVEL_ADD: "1bb9c056-2f79-4655-a3db-a6209158149d",
    BRANCH_MASTER_LEVEL_DELETE: "1b20cb83-c15b-4246-9ae8-3d8b9aec65ff",
    BRANCH_MASTER_DATA_ADD: "acebfff7-a7ca-4f8d-ad1b-5ea415c3a2d5",
    BRANCH_MASTER_DATA_DELETE: "9a2b169d-0d1c-4a17-9c60-4272102fa96f",
    //branch master--end


    // bulk content upload--start
    BULK_UPLOAD_GENERAL_CHOOSEFILE: "0cc004e1-e108-4930-92b3-b15a416308ec",
    BULK_UPLOAD_GENERAL_SHOW_MISSED_FILEDATA: "1594d5d4-ff0f-4141-ab3b-612d8d84ef0f",
    BULK_UPLOAD_GENERAL_SHOW_ALLDATA_WITH_MISSED_FILES: "48a40365-b4f3-4a13-b0c5-c8bac84cf43d",
    BULK_UPLOAD_GENERAL_UPLOAD_DATA: "189ea1ab-9a95-4131-88e6-64bc064bc431",
    // bulk content upload--end




    USAGEANALYTICS_SEARCH: "2b2a46c7-bdc6-4679-b323-20a1fcc01261",
    USAGEANALYTICS_FILTERBY: "a2449a8b-7bf3-49d4-a4e7-89767dc90fe3",
    USAGEANALYTICS_GRAPHICAL_VIEW: "5d373a63-d414-4480-bd33-c83357788382",
    USAGEANALYTICS_SEARCH_TABLE: "6c4c55d3-795d-4699-b6f0-11ac8285fdf3",
    USAGEANALYTICS_VIEWCOLUMNS: "70995b50-1614-4c4d-b194-a78f54f284ee",
    USAGEANALYTICS_DOWNLOAD: "55a38cf8-c130-4bb2-975e-86625ec2c268",
    USAGEANALYTICS_DETAILED_REPORT: "08550ce8-bf1b-49eb-a6b0-c798ddd2b764",
    USAGEANALYTICS_BRANCHWISE_SEARCH: "be3cb0c5-c36f-4925-9a6e-abcc140e3db9",
    USAGEANALYTICS_BRANCHWISE_FILTERBY: "0d06f730-2c12-4c91-b9de-e7281ef9cd63",
    USAGEANALYTICS_BRANCHWISE_GRAPHICAL_VIEW: "2292a105-4b0f-4388-9c12-8e9782ae8be4",
    EXAMANALYTICS_FETCHEXAMS: "fe1a82eb-2c4c-4b56-90f0-e303ba53c43c",
    EXAMANALYTICS_SHOW_RESULTS: "397e53a3-d6fe-4d43-8558-7875a227353e",
    EXAMANALYTICS_TESTWISE: "09efd307-7369-4479-be72-79369eb72da7",
    EXAMANALYTICS_QUESTIONWISE: "8e8ea49c-19b0-4c74-939e-844112b8b210",
    EXAMANALYTICS_GRAPHICALVIEW: "31920d6f-fea3-470e-b72a-18caf7a3e541",



    TOTAL_ANALYTICS_USAGEANALYTICS_DOWNLOADCSV: "6c4c55d3-795d-4699-b6f0-11ac8285fdf3",
    TOTAL_ANALYTICS_USAGEANALYTICS_PRINT: "aee577af-3f40-4117-ade8-267cb7edcd82",


    TOTAL_ANALYTICS_PERFORMANCE_TESTWISEANALYTICS: "fe1a82eb-2c4c-4b56-90f0-e303ba53c43c",
    TOTAL_ANALYTICS_PERFORMANCE_QUESTIONWISE_ANALYTICS: "31920d6f-fea3-470e-b72a-18caf7a3e541",
    TOTAL_ANALYTICS_ALLPROCESSES_RUN_USAGEANALYTICS: "7268a933-5b74-49bd-ad08-be64d1c690c8",



    //examination --start
    EXAM_FETCH_QUESTIONS: "2b489bee-433e-4d1b-af1c-de0e3558f15e",
    EXAM_ADD_QUESTION: "72716af1-1fd1-4724-b238-ec352b8c0c6b",
    EXAM_SEARCH_BY_QUESTIONID: "7cdd2dca-e1a0-4af3-811b-baa8ef594c2e",
    EXAM_EDIT_QUESTION: "cb2e7e82-fb3c-4667-92e3-356261ce1676",
    EXAM_PREVIEW_QUESTION: "a57e997d-c23f-4f98-b167-93a5e372212d",
    EXAM_DELETE_QUESTION: "b6f203d0-4f88-43d0-aa16-02fb1678264b",
    EXAM_QUESTION_ASSIGN: "1a8f8577-21d9-4575-a5c8-472a8cb1c55b",
    EXAM_CREATE_QUIZ: "2fdedd5c-2d22-4bf3-a3ae-22ebcf9e4a02",
    EXAM_CREATE_ASSESSMENT: "d4e6f104-2cb3-4df8-be19-d5d3a3ce58bf",
    EXAM_CREATE_ADHOC: "abb09a1e-528e-423a-991f-7f503787f1cd",
    EXAM_CREATE_ADHOCT2: "318be667-91c9-40c9-9eb3-fe5c38dda042",
    EXAM_CREATE_SUBJECTIVE: "435a3930-673c-4748-9d14-c2bfbb7c22fb",
    EXAM_EDIT_QUIZ: "58bbed60-f235-487e-85b5-be1500c61eff",
    EXAM_EDIT_ASSESSMENT: "f1b96cb2-ca53-4c94-8721-7b34aefa483c",
    EXAM_EDIT_ADHOC: "47bfd8b1-7492-4555-83e6-f073755c3ced",
    EXAM_EDIT_ADHOCT2: "b950adba-14cf-4fa0-97bd-e33047fd9343",
    EXAM_EDIT_SUBJECTIVE: "9c507c0e-eabb-4d6e-82eb-54be04eb3726",
    EXAM_LIST_QUIZ: "50788d9d-67f9-4ce2-ba68-b547b560d545",
    EXAM_LIST_ASSESSMENT: "29d75bac-95a8-4fd9-893e-48fe27a0533a",
    EXAM_LIST_ADHOC: "77755a10-af01-4cf7-92ed-e94301cb8d26",
    EXAM_LIST_ADHOCT2: "dc3b9be5-d5ef-4194-881f-f06d43a718c3",
    EXAM_LIST_SUBJECTIVE: "0334dd61-f12d-4b3a-bc3c-96048f5e2b67",
    EXAM_EVALUATE_SUBJECTIVE: "b0c4a67a-023c-475e-8da0-e5b13abfc7ba",
    EXAM_ASSIGN_QUIZ: "853959bd-6247-480e-8d4b-acac39941985",
    EXAM_ASSIGN_ASSESSMENT: "2774d35c-bc42-4320-8dde-63d72ef8dd74",
    EXAM_ASSIGN_SUBJECTIVE: "e14a2e8b-7cee-4efe-a18c-9e8e3aa96473",
    EXAM_PAPER_DOWNLOAD: "e48e36af-482e-434f-9565-dcfdb77b63c5",
    EXAM_ADHOC_EXPORT: "8d1f3bfb-fb04-42f9-92fe-5eb6c0e8b512",
    EXAM_DOWNLOAD_REPORTS: "913c8e1c-eef2-4f49-b801-93af8e4ce1e8",
    EXAM_UPLOAD_EXAM_RESULTS: "d8f5e600-39c9-4d78-8cd2-502c6b3cf7e2",
    EXAM_RECALCULATE: "eb29c34b-a527-43e9-b797-48f24630de09",
    EXAM_RELOAD_EXAM_QUESTIONS: "9d98f69c-ffb6-4fe3-a72f-6d397f6f8808",
    EXAM_SUBJECTIVE_EXPORT: "c896abfd-f0ce-4938-b9a8-706131b071db",
    EXAM_ASSIGN_ADHOC: "7be229b4-870b-43fe-bb0a-cae21a7fff9f",
    EXAM_ASSIGN_ADHOCT2: "5ee70c10-eee0-4572-9310-ee3e958a9773",
    EXAM_RESULT_ADHOC: "fcbc9d4c-ba86-44b7-a16b-92b4f1ca08c6",
    EXAM_RESULT_ADHOCT2: "728a0aed-25fa-4ef0-b8d1-8a14aec8b190",
    EXAM_RESULT_ADHOC_QUESTION_WISE: "8d5f57c7-5465-4a6a-a72b-e8595b4b5693",
    EXAM_RESULT_ADHOC_SUBJECT_WISE: "0584b4fc-d58d-4509-a66c-8cf7d3cd7145",
    EXAM_RESULT_ADHOC_SUBJECT_AND_Q_WISE: "dae5b7e9-bf4f-4867-8f9c-2e27f30e1d33",
    EXAM_QUESTIONS_LIST: "b83aad90-8a37-4890-a3e9-e64011c60cd2",
    EXAM_QUESTIONS_ASSIGN: "3785a84c-ed97-4434-a064-d6dac9d865d2",
    EXAM_QUESTIONS_PREVIEW: "dbedf6b4-78df-42ba-a2d4-9a00af4d830a",
    EXAM_QUESTIONS_DELETE: "125cccb1-c7a2-401b-8e52-d528a4f51ee1",
    EXAM_DOWNLOAD_ADHOC_REPORTS: "3e92072b-d062-40e2-b30f-c2592abc8432",



    QUESTION_DUPLICATE_CHECK: "b619ec80-f07e-4541-8a9f-cc08f3cfa652",
    QUESTION_VIEW_DUPLICATES: "e70d82ec-9348-4a59-9365-e99798ba5ccd",
    QUESTION_REPLACE_DUPLICATES: "9e14807e-fcc5-43a0-821f-4994ba0cb2f5",
    //examination --end

    //examination-adhoc-template --start
    ADHOC_TEMPLATE_CREATE: "a79d4d68-7c0a-40d8-82e1-a1231d77a341",
    ADHOC_TEMPLATE_EDIT: "21f16fa8-70bc-4e80-ac68-76d06975054f",
    ADHOC_TEMPLATE_DUPLICATE: "e2d30e42-e7ef-40e9-8b5f-9917653c676b",
    ADHOC_TEMPLATE_LIST: "112ceec6-8b32-4eb3-8bfa-8cfad60be80d",
    ADHOC_TEMPLATE_SEARCH: "e2d4e9b7-0834-4ec1-9a63-c3d5330bb9ba",
    ADHOC_TEMPLATE_VIEW_COLUMNS: "7bbc2afb-2018-4ef1-a8d9-22a021a99c0d",
    ADHOC_TEMPLATE_VIEW_DOWNLOAD: "cd811fec-593f-40a6-a60e-caafe511fca7",
    //examination-adhoc-template --end


    //examination-adhoc-template --start
    SUBJECTIVE_TEMPLATE_CREATE: "068067a2-a4f1-448d-854b-c0de59231388",
    SUBJECTIVE_TEMPLATE_EDIT: "656f2787-f54e-4b6e-96c5-b6580552ccd4",
    SUBJECTIVE_TEMPLATE_DUPLICATE: "3214ff5f-96e4-4558-92ff-9c57b2937066",
    SUBJECTIVE_TEMPLATE_LIST: "2331408e-c0ce-40e5-97d1-94a98559560a",
    SUBJECTIVE_TEMPLATE_SEARCH: "8a23a935-dabf-4672-a45a-43798409254f",
    SUBJECTIVE_TEMPLATE_VIEW_COLUMNS: "3f8a955b-460e-4857-82dc-e793e833ff7c",
    SUBJECTIVE_TEMPLATE_VIEW_DOWNLOAD: "a960d316-d863-47eb-9d73-05965b7dbcf6",
    //examination-adhoc-template --end

    ADHOCT2_TEMPLATE_CREATE: "825ff7f3-13c8-4008-837b-06fd1d230269",
    ADHOCT2_TEMPLATE_EDIT: "9b85670e-4361-4c0f-8855-1c31abc4cf58",
    ADHOCT2_TEMPLATE_DUPLICATE: "b95f02e5-85d8-45fb-91d2-811bc127de4f",
    ADHOCT2_TEMPLATE_LIST: "29e476e0-85b5-45c8-bf74-f6e9c11298e4",
    ADHOCT2_TEMPLATE_SEARCH: "1758fb54-0ed5-43f9-abd9-c659a810f19d",
    ADHOCT2_TEMPLATE_VIEW_COLUMNS: "621b0c6a-9058-4163-8fd4-48dae753ba6c",
    ADHOCT2_TEMPLATE_VIEW_DOWNLOAD: "b98d006f-c5cc-4685-a8d8-5e8744995125",

    //discussion board --start
    DISCUSSION_BOARD_GENERAL_FEED: "98a09ef3-93cb-4011-9338-bd2f6816205d",
    DISCUSSION_BOARD_GENERAL_QOD: "8947c5ba-aa2e-4da1-a049-9414503844de",
    DISCUSSION_BOARD_GENERAL_MY_DISCUSSION: "08d8a71b-bd5d-4401-a579-b7d52088688d",
    DISCUSSION_BOARD_GENERAL_MY_ANSWERS: "aba87ea-8f7c-4660-9de3-3158459270a3",
    DISCUSSION_BOARD_GENERAL_FLAGGED_DISCUSSIONS: "ea73ebf7-a46a-4c0a-a55d-7c8f6b03f034",
    DISCUSSION_BOARD_GENERAL_PINNED_DISCUSSIONS: "d2024054-54fa-47c0-af83-ffce282bd9ac",
    DISCUSSION_BOARD_CERATE_DISCUSSION: "e0347757-7604-4ddb-8828-9b09838a3d01",
    DISCUSSION_BOARD_SEARCH: "05eb9f80-178b-4587-9468-ae00ab826c48",
    DISCUSSION_BOARD_SORT_FILTER: "c2b38fc7-65b8-4e8f-9702-5678c99aa76c",
    DISCUSSION_BOARD_UN_REPORT: "11988643-afa8-4eaa-a036-b5f4dfbf6888",
    //discussion board --end


    //ask a doubt--start
    ASK_A_DOUBT_GENERAL_ATTEMPT_DOUBT: "50568527-4357-493d-b9fd-9aaf59199b49",
    ASK_A_DOUBT_GENERAL_UPLOAD_ATTACHMENT: "f87f4005-6c8e-4ef4-90b9-b809c13d024e",
    ASK_A_DOUBT_GENERAL_SEARCH: "2aff8ea3-754f-4bbd-9e88-beec67137924",
    ASK_A_DOUBT_GENERAL_SORT_FILTER: "6013c188-716e-4d82-9bc0-249122018086",
    ASK_A_DOUBT_GENERAL_REFRESH: "92f13614-f4d5-4692-8236-207a5854a4a5",
    //ask a doubt--end


    // virtual class --start
    VIRTUAL_FETCH_CLASSES: "52fb8a31-414a-46ff-9065-49cce85d77b1",
    VIRTUAL_ONGOING: "88d0abad-4fc3-4841-a381-f1500a7a50fe",
    VIRTUAL_UPCOMING: "539ad6cb-ccbf-4dbe-a4cc-969b238977c7",
    VIRTUAL_COMPLETED: "05cb9d52-6b40-4589-b267-1ad64b6589f5",
    VIRTUAL_SEARCH: "88a8d82e-3804-42d2-a83b-17da372e17e0",
    VIRTUAL_VIEW_COLUMNS: "8fbc612b-72ca-4d73-b89b-120f7694cf62",
    VIRTUAL_FILTERS: "add6d09e-bb22-4f48-acac-8bd0b68d9318",
    VIRTUAL_DOWNLOAD: "27152bf7-ca20-450a-9e35-70ce8b6f59b4",
    VIRTUAL_JOINANDEXIT: "04dae06a-b4dc-4075-95d6-fe69f064a16e",
    VIRTUAL_EDIT: "27d04f57-abbd-43a6-8518-90f831c36bd5",
    VIRTUAL_DELETE: "d2bb01f4-e627-49ac-98ae-4d983d04ce91",
    VIRTUAL_AUTO_REMINDER: "b3ab5869-8395-41db-83c8-c40e0965a144",
    VIRTUAL_WATCH_RECORDINGS: "126a7fdc-cada-416d-9b09-fde3e70a3502",
    VIRTUAL_UPLOAD_RESOURCES: "f242c629-71bb-4ad2-aad7-ba1fee1ab68c",
    VIRTUAL_NOTIFY: "b27d82ef-7e45-4e63-9a89-d0d595c87c19",
    VIRTUAL_SHOW_ATTENDEES: "a9316033-f3a3-4bc4-8c11-5d9c67dc348f",
    VIRTUAL_CREATE: "8a090a85-8e97-4852-98ae-6490bdd2d878",
    // virtual class --end


    // yearly calendar --start

    // yearly calendar --start
    YEARLY_ADD_PLANNER: "44b8da19-88b7-456f-8163-7f4b69722627",
    YEARLY_BULK_PLANNER_UPLOAD: "9cfbed54-6702-4a81-8067-42a7caf2dc47",
    YEARLY_SEARCH: "b917118c-2133-4f27-8544-c29d03cea9bf",
    YEARLY_FILTERS: "6de847c9-81bf-4111-9cb6-14b0aa87cdd3",
    YEARLY_VIEW_COLUMNS: "404ff15d-1816-49ef-baa9-a70a419acbac",
    YEARLY_DOWNLOADCSV: "bff36ece-3bc7-4add-9560-861160601643",
    // YEARLY_STATUS_CHANGE: "2f482b21-745a-4326-b992-08219115e622",
    YEARLY_EDIT: "904da4ab-c91f-490a-afcf-ae21f7003f2f",
    YEARLY_ASSIGN: "8bd74589-9749-4036-ad72-57fab4f6446c",
    YEARLY_DOWNLOAD_PLANNER: "f27b2de6-4b01-4638-b042-43931c990454",
    // YEARLY_DELETE: "cfac18f6-9496-4722-ab18-2653b77f5ca2",

    //yearly calendar --end



    // timetable --start
    TIMETABLE_ADD: "ccf80f6c-a56d-4c8a-b1c1-dc78128f46b8",
    TIMETABLE_SEARCH: "616e0289-c882-4948-b18c-519e11d59375",
    TIMETABLE_FILTERS: "bb04b04a-ec75-4dd7-a504-a0d23b8ff1b8",
    TIMETABLE_VIEW_COLUMNS: "724d341c-a3d9-4dc3-8042-decf5676e3b8",
    TIMETABLE_DOWNLOADCSV: "1844a63d-823b-4012-8f2d-a87c5016e57d",
    TIMETABLE_EDIT: "5f26cb61-fcc4-4e95-8216-217912706224",
    TIMETABLE_DELETE: "672bb450-d16f-4d4d-91da-f07e837c5b39",

    //timetable --end


    NOTIFICATION_ADD: '68fb0b39-d3a1-4666-bca5-64c4500a318c',
    NOTIFICATION_SEARCH: 'a3bad1e7-0cc8-4471-b1dd-a96151f75490',
    NOTIFICATION_FILTER: '2cfae971-bf22-48b7-be08-bf7e260c860f',
    NOTIFICATION_VIEW_COLUMNS: 'c79b068b-b002-438d-9eff-8bd8515fc5a8',
    NOTIFICATION_DOWNLOAD: '98399b29-0635-413e-9709-ffdfe6ebdf64',



    CLASSROOM_GENERAL_SEARCH: "e63ee9bc-bf1b-426a-9b09-f0448ccb7aeb",
    CLASSROOM_GENERAL_VIEW_COLUMNS: "1cd947d5-bcb4-433f-b440-b6eef4118c8c",
    CLASSROOM_GENERAL_DOWNLOAD: "6dc16d9e-72a0-4653-997e-15860bf55eba",
    CLASSROOM_SHOW_DETAILS: "baea2882-cf55-4b55-9ded-e1e0d3a6949e",
}


export const PERMISSIONS = {

    // SETTINGS
    "ACADEMIC_SESSION_LIST": "e92342df-d4a1-4524-af99-e956f359b0ac",
    "CREATE_ACADEMIC_SESSION": "5883e245-e69d-419b-96d3-569fe98f19af",
    "UPDATE_ACADEMIC_SESSION": "bada41ce-d652-4768-ad26-b1ccf9da6b88",
    "TERMS_LIST": "70ef434e-d625-4919-890c-841daa6ed87e",
    "CREATE_TERM": "3c41b9dc-cda3-4701-bb34-40941436a009",
    "UPDATE_TERM": "af1b6f44-296f-49eb-96af-5b9ed3d0299c",
    "BRANCH_MASTER_LIST": "a3191f07-a2eb-4c42-bcfd-9a08e56f3950",
    "CREATE_BRANCH": "2a29fa37-b0bc-4551-84bb-f8217175dbd2",
    "UPDATE_BRANCH": "1691cdaf-1891-4e16-8b03-cd0cd95a35cd",
    "CREATE_GROUP": "0f6b02eb-f983-4249-8f09-5129dfb2d085",
    "UPDATE_GROUP": "9ede2a89-7e84-4915-ac82-18e95145a0ea",
    //
    "BOARDS_MANAGEMENT_LIST": "5244fa33-c842-413f-9511-8886e44aad57",
    "CREATE_BOARD": "61b9af3b-8393-459e-b84a-99db6e5f05af",
    "UPDATE_BOARD": "7f6fc4f3-4e73-4974-a274-085eca7a5de9",
    //
    "GRADE_AND_SECTION_LIST": "000ae578-3597-4656-90d0-5b62888302ae",
    "CREATE_GRADE": "3498bb21-1e79-4eea-be16-a4d621124e29",
    "UPDATE_GRADE": "0c2a4c51-62e9-4ffb-903e-3602dc98e1c0",
    "CREATE_SECTION": "8aa1510b-3d89-40de-94e7-99689a9b2b11",
    "UPDATE_SECTION": "e1cf8afd-65d8-4b83-9a84-1462c2852d02",

    //
    "SUBJECT_MANAGEMENT_LIST": "063693e6-7c1f-4c97-bd75-f3fbf3f2dedb",
    "CREATE_SUBJECT": "1566b3e0-8724-469a-bc4d-b93340f28333",
    "UPDATE_SUBJECT": "05547f15-cd17-4f5f-b0ea-884895cc70ae",
    "CREATE_CHAPTER": "2f8106e4-421f-4715-9805-d66578eafa4d",
    "UPDATE_CHAPTER": "00e87a7f-1afe-4db8-bc33-be9cd0316ea6",
    "CREATE_TOPIC": "5dfd39c1-673d-43ed-a911-641abd7e2f0c",
    "UPDATE_TOPIC": "62002675-6f78-446d-9ab5-aae787701546",

    //
    "CONTENT_MANAGEMENT_LIST": "fd70ef34-40ab-40e1-bae4-b3a45a3696d0",
    "ADD_RESOURCES_VIDEOS_PDFS_PPPTS": "44873317-7965-45f9-b80f-f32da234c0e4",

    //
    "FEE_TYPES_LIST": "beb342b9-41ab-4b2a-a5c7-1167ba0668ae",
    "CREATE_FEE_TYPE": "889fd53e-f26b-422a-9393-b8ad63f1edbd",
    "UPDATE_FEE_TYPE": "07a5df8c-2f29-4585-99a7-1ba0452193db",

    "FEE_CATEGORY_LIST": "2d4e2916-b47e-489f-9198-b9b8fe37f498",
    "CREATE_FEE_CATEGORY": "2f7d91bc-2da2-412b-98dd-856ba6e44e79",
    "UPDATE_FEE_CATEGORY": "66fdf79a-732f-412f-8acb-8f9e492635c7",

    "FEE_STRUCTURE_LIST": "1f98022b-6c3a-42bc-be06-d73c9cb17a9a",
    "CREATE_FEE_STRUCTURE": "88680752-a7b6-4127-ac82-276307c9c23b",
    "UPDATE_FEE_STRUCTURE": "e6a9ae32-bf90-4169-a42b-3dcd9141b53d",
    "ASSIGN_FEE_STRUCTURE": "e28ff67e-7c39-4f4e-b4d8-80869281c05a",

    "GENERAL_SETTINGS": "47070ab2-b01a-4dbd-93b6-895bcd01a16c",
    "GENERAL_SETTINGS_UPDATE": "d567e685-284e-4217-9aec-c05d11713194",


    "FEE_CONCESSION_LIST": "8e3dad26-c96c-4098-92c3-1f3245de7db5",
    "CREATE_FEE_CONCESSION": "fb6b233e-7180-429b-b59c-4d9f1bee06a7",
    "UPDATE_FEE_CONCESSION": "ed45dfc2-1da9-452c-931b-44025a8ca50b",
    "ASSIGN_FEE_CONCESSION": "c1c9a832-a1f8-4436-8047-10643a727f4f",

    "BANKING_LIST": "b5730e1e-2076-435a-84dd-b6d05581ad6e",
    "CREATE_BANKING": "0902e3bb-b093-4741-a654-cdbf3189fef4",
    "UPDATE_BANKING": "396cbd42-da75-4054-b194-41f247a430f9",
    "ASSIGN_BANKING": "4ac7f92f-7c50-4a67-ae48-e5bee2a14833",

    "FINE_LIST": "236bc152-b30d-4c4b-aaa5-fffb58aa2464",
    "CREATE_FINE": "16291f55-c45c-4395-830d-33133adb4a22",
    "UPDATE_FINE": "bcbd2994-7b4b-4442-8b9c-2a2c55cc4254",
    "ASSIGN_FINE": "20af4b7b-a7e5-48c9-a977-0582cc736b49",

    "ADMISSION_PROCESS_LIST": "83ed219f-d86a-46a0-9441-de9611488326",
    "ADMISSION_PROCESS_UPDATE": "2d47dd82-9e19-4317-b53c-dee0df2cfd7d",

    "AGE_CRITERIA_LIST": "4d936058-14a6-40a3-80ce-05b48b26650c",
    "UPDATE_AGE_CRITERIA": "a0390acb-2e62-4af0-922c-fef0caf38e89",

    "ADMISSION_CATEGORY_LIST": "bbe32b0e-2646-4394-b530-69acc9fb502c",
    "CREATE_ADMISSION_CATEGORY": "519188f4-e0e1-41e9-8fba-a3393dc1d147",
    "UPDATE_ADMISSION_CATEGORY": "c1dc6190-5096-4455-a57e-a82c8697be75",

    "REGISTRATION_FEE_LIST": "db541e4a-a863-4da0-8dc8-2feba47037d7",
    "CREATE_REGISTRATION_FEE": "24e33161-8306-444b-8f1d-75dcdb5e9b74",
    "UPDATE_REGISTRATION_FEE": "e214bf24-1cfa-4ecc-b498-40ce3d12809d",

    "VEHICLE_MANAGEMENT_LIST": "84a01b96-6ff3-44f0-8237-f4ad2ee55717",
    "CREATE_VEHICLE_MANAGEMENT": "4ce0606c-8869-4aa4-8cd0-0e1e688ac549",
    "UPDATE_VEHICLE_MANAGEMENT": "cbb48b30-2b9b-4299-a565-3dba21af5513",

    "CREATE_TRANSPORT_FEE": "d43900dc-30f2-4e9b-94cb-fd7b7a131698",
    "CREATE_ROUTE": "ce98accc-a9b2-4449-9614-e4b97155fbd4",
    "ADD_STOPS": "d4cfb8f7-fecb-4e12-9f17-1d0e773e854c",

    "MANAGE_TRANSPORT_STAFF":"df31c0a5-0225-4732-a578-b8ab7ac720ce",
    "CREATE_MANAGEMENT_STAFF":"38987848-635c-4e6d-a17c-ed6ce41e62c3",
    "UPDATE_MANAGEMENT_STAFF":"8e6e5d48-4771-4a49-9904-8fdf636d4303",

    "ASSIGN_TRANSPORT": "10e644d8-ef56-47b5-b3b9-c58449cb15c1",

    "TRANSPORT_FEE": "0f8121be-4a0b-4d5b-88d2-cf355977e33e",
    "UPDATE_TRANSPORT_FEE": "7847b453-4a3c-4c38-9ff3-93bb7d66ad2d",


    "ROLE_MANAGEMENT_LIST": "399806f5-f7e9-409c-9a55-10d98fdac808",
    "CREATE_ROLE": "86dcfc71-aff9-4944-ac89-e07057494c2b",
    "UPDATE_ROLE_MANAGEMENT": "89bf6b97-0b95-442c-9552-9f4bac3c0316",

    "USER_MANAGEMENT_LIST": "eaaa8748-6bf7-466e-8039-c0c2b6a383e1",
    "CREATE_USER": "141357ca-7dac-4562-83a6-73bb29377dbc",
    "UPDATE_USER": "76aa88d1-b572-4edf-a04a-26c7ada721c4",

    "FEE_CONCESSION_LIST": "d4639b41-1198-4ad8-9b7c-05ecd627cd95",
    "CREATE_FEE_CONCESSION_APPROVAL": "73c92c55-bb20-4451-9cce-9f8071e66b1a",
    "UPDATE_FEE_CONCESSION_APPROVAL": "e1a434b5-1aeb-4603-b430-a8197a247b97",

    "FEE_REFUND_LIST": "0288d699-440a-4ea1-a207-1105bd16298e",
    "CREATE_FEE_REFUND_APPROVAL": "e65c5534-3072-4eb1-b6ae-a9094393057a",
    "UPDATE_FEE_REFUND_APPROVAL": "e4657cc0-9028-47f0-8abb-3add35c73e4c",

    "FEE_UNASSIGN_LIST": "a04cf232-23bd-4168-ac94-df8525dd1a99",
    "CREATE_FEE_UNASSIGN_APPROVAL": "7032f30d-0770-480e-90cd-6940bb3a743c",
    "UPDATE_FEE_UNASSIGN_APPROVAL": "878cc476-06dd-4b03-bbe7-5a39e9e951c3",

    "STATUS_CHANGE_LIST": "4b13ca6b-41bd-4184-ba64-7169344d56c6",
    "CREATE_STATUS_CHANGE_APPROVAL": "688f8c78-12a8-4fa3-ae16-9b44eb9b271f",
    "UPDATE_STATUS_CHANGE_APPROVAL": "187045f6-d2c1-494e-9c83-e2a65e795152",

    "STUDENT_TRANSFER_LIST_APPROVAL": "6973dc27-852a-4a89-b9c0-828a8272e5c1",
    "CREATE_STUDENT_TRANSFER_APPROVAL": "3df354cb-2031-44c0-ba33-212520efa2d7",
    "UPDATE_STUDENT_TRANSFER_APPROVAL": "70ec9e79-88fa-46f2-bb4e-090fd1918341",

    "TIMETABLE_TEMPLATES_LIST": "180e78c3-6d53-49a9-8a71-717d554950ad",
    "CREATE_TIMETABLE_TEMPLATE": "cf7fb8dc-79af-4664-afba-8d95e6e95e39",
    "UPDATE_TIMETABLE_TEMPLATE": "873239b5-ddc3-4f0f-915f-0169d5d1404c",
    "ASSIGN_TIMETABLE": "dcd4eed5-40c8-45fd-807d-ecd0a00bbe5c",
    "TIMETABLE_SETTINGS": "d870dd89-8466-4a22-aa37-82ad784ce779",
    "SAVE_TIMETABLE_SETTINGS": "e1be2841-bb75-4d2d-8cd5-d44530f3e9d9",


    "STAFF_MANAGEMENT_LIST": "d14cb98d-8eb9-4468-a2ea-8c908fa49191",
    "CREATE_STAFF": "245ca3fc-99a8-4279-b38f-21e074729716",
    "UPDATE_STAFF": "72c7fe8b-0b19-4db8-b795-2fcbc5548df0",
    

    
    
    
    // ADMISSION

    "ENQUIRY_LIST": "0f26e59f-351b-4f10-a02d-6e20391b0a42",
    "CREATE_ENQUIRY": "c3b5fb8d-4ddd-44b6-81bc-e27f9d5dfe8f",
    "UPDATE_ENQUIRY": "8a9e4c2c-2e5c-41f3-8178-4d985094830f",
    "APPLICATION_LIST": "e22f82ca-dc13-48a1-9511-fca9088cea14",
    "CREATE_APPLICATION": "3aff2e96-79a6-4342-bac1-c1a73f55351d",
    "UPDATE_APPLICATION": "f04f50c6-5f59-4141-a58b-b85a2dc0917f",
    "EXAM_TEST_LIST": "4438110d-e1ea-4c8b-b4d0-e6926773912f",
    "CREATE_EXAM_TEST": "72cc513e-2f92-43f5-b0cf-c53fe95e1168",
    "REGISTRATION_LIST": "5b1867ab-7633-4d30-b6ad-9af6c1b4dc8b",
    "CREATE_REGISTRATION": "94cb66bd-1e5c-4db6-a608-e68efc750022",
    "UPDATE_REGISTRATION": "122fd58d-d6b3-4cfd-a531-1e836e0ebb91",
    "ENROLLMENT_LIST": "0b4c2f29-83f6-41a3-a215-f77ed41501e5",
    "CREATE_ENROLLMENT": "9a3093ac-69fc-4dd1-9e72-c9e896f5a926",
    "UPDATE_ENROLLMENT": "0f344b52-6ce4-4c30-b4f6-2593798e4f7d",
    "UPDATE_EXAM_TEST": "742fcb0c-e939-4dcb-9a4a-bd1c39dde461",


    //STUDENTS

    "STUDENT_INFORMATION": "0f3f65e0-fb66-4132-aee8-2387a3f93dc0",
    "STUDENTS_BULK_UPLOAD": "0202e805-4279-4716-841b-499347612404",
    "ATTENDANCE": "6306ae45-b1c2-4821-918c-0a7d2329fc17",
    "STUDENT_TRANSFER": "bcaf1f26-232d-49c8-983d-7d9f50675cb8",
    "STATUS_CHANGE": "95b3b024-45b3-4672-a128-e839c78fb178",
    "ADMISSION_CATEGORY": "d81fb045-9b52-452e-ac54-7fc5df5c1196",
    "RAISE_STUDENT_STATUS_CHANGE": "bdd375dc-4921-4ca5-84f4-cca576d75829",
    "RAISE_STUDENT_ADMISSION_CHANGE": "4c837b24-0c06-4cb1-96a5-6319ad1a499d",
    //ACADEMICS

    "ACADEMIC_CALENDAR": "80e342ae-54cb-4545-a67b-ba91f2bbf667",
    "ACADEMIC_CALENDAR_-_ADD_EVENT": "1f9540fb-e356-49e9-bf10-f4df526e6301",
    "TEACHER_DIARY_TEMPLATE_LIST": "4c14afab-45f5-4e0e-9c14-a52b3cae9175",
    "CREATE_TEMPLATE": "f096802f-0dc6-49f0-a079-3997b878eb8a",
    "UPDATE_TEMPLATE": "0715c453-43fb-41b9-a517-0fb7756b7246",
    "TEACHER_DIARY": "b91caf70-cfb0-4fb4-a3dc-335d17be58b6",
    "CREATE_TEACHER_DIARY": "81b21485-de96-469e-9f73-35a95a9494a1",
    "DIGITAL_LIBRARY": "4ea46c85-b0a1-423e-a0d0-a5c3eda0f72a",
    "CREATE_TEST": "4df3b479-f260-4c5b-a1b3-a7b82b78e89c",
    "QUESTION_BANK": "46e14555-d469-4745-b7de-df40d3ce173e",
    "CREATE_TEST_TYPE": "d3839cfc-05f0-4add-9924-0fdb7973bdc1",
    "CREATE_TEMPLATE": "01342bc7-022f-4e9f-b6eb-56b6bde104d9",
    "TESTS_&_EXAMS": "d9b82cf1-a1f7-414f-a7b3-638df72ec284",
    "EXAM_CREATE_QUIZ": "2fdedd5c-2d22-4bf3-a3ae-22ebcf9e4a02",
    "EXAM_LIST_QUIZ": "50788d9d-67f9-4ce2-ba68-b547b560d545",
    "EXAM_LIST_ASSESSMENT": "29d75bac-95a8-4fd9-893e-48fe27a0533a",
    "EXAM_LIST_ADHOC": "77755a10-af01-4cf7-92ed-e94301cb8d26",
    "EXAM_LIST_ADHOCT2": "dc3b9be5-d5ef-4194-881f-f06d43a718c3",
    "EXAM_LIST_SUBJECTIVE": "0334dd61-f12d-4b3a-bc3c-96048f5e2b67",



    //TRANSPORT

    "ADD_SPECIAL_ROUTE":"a82a99d1-075d-4854-a4b6-2841b2a42478",
    "ADD_ROUTE":"b78ecf4b-2caa-4e6f-ac40-8dd9c89000a6",
    "ADD_STOPS":"f8293c34-a888-4af7-ab31-5171b1b62690",


    //TIMETABLE

    "MY_TIMETABLE": "14bf5a9c-4162-49e1-8771-b91ca4609139",
    "REQUEST_SUBMISSION": "e78f9eda-6dfd-467c-8f04-e880d71d7cba",
    "LAG_REPORTS": "2133b668-1056-48ae-bc09-dc0f88fb84fc",
    "TIMETABLE_REPORTS": "4604da63-1129-46e3-80e6-ff5fea9041d7",

    //COMMUNICATE

    "NOTIFICATIONS_LIST": "26c278fd-a6f4-41ca-ba4f-ebc641ebfb48",
    "CREATE_NOTIFICATION": "3828ea9f-a695-42c4-8cae-fbb0ea685615",
    "UPDATE_NOTIFICATION": "1057bea5-3c14-4e42-88d9-0e5d6e024d46",

    //APPROVALS

    "FEE_CONCESSION": "f1f068ac-96b8-422d-8428-c7f6d04501dc",
    "FEE_REFUND": "f495d458-648c-43c3-8221-71086a99bb92",
    "FEE_UNASSIGN": "12730ea6-b6cb-4443-a469-af61f01b32a0",
    "STUDENT_TRANSFER_APPROVAL": "8e842c7b-fe18-468c-a386-18ede157f6af",
    "STUDENT_STATUS_CHANGE": "84127107-ccc4-4380-9077-957858d5cc14",

}

export const getPermIdOfAction = (action) => {
    
    switch (action) {
        case 'Quiz-create':
            return PERMISSIONS.EXAM_CREATE_QUIZ;
        case 'Assessment-create':
            return PERMISSIONS.EXAM_CREATE_ASSESSMENT;
        case 'AdhocMockTest-create':
            return PERMISSIONS.EXAM_CREATE_ADHOC;
        case 'SubjectiveTest-create':
            return PERMISSIONS.EXAM_CREATE_SUBJECTIVE;
        case 'Quiz-list':
            return PERMISSIONS.EXAM_LIST_QUIZ;
        case 'Assessment-list':
            return PERMISSIONS.EXAM_LIST_ASSESSMENT;
        case 'AdhocMockTest-list':
            return PERMISSIONS.EXAM_LIST_ADHOC;
        case 'SubjectiveTest-list':
            return PERMISSIONS.EXAM_LIST_SUBJECTIVE;
        case 'AdhocMockTest-result':
            return PERMISSIONS.EXAM_RESULT_ADHOC;
        case 'AdhocMockTest-template':
            return PERMISSIONS.ADHOC_TEMPLATE_LIST;
        case 'SubjectiveTest-template':
            return PERMISSIONS.SUBJECTIVE_TEMPLATE_LIST;
        case 'AdhocMockTestT2-template':
            return PERMISSIONS.ADHOCT2_TEMPLATE_LIST;
        case 'AdhocMockTestT2-create':
            return PERMISSIONS.EXAM_CREATE_ADHOCT2;
        case 'AdhocMockTestT2-list':
            return PERMISSIONS.EXAM_LIST_ADHOCT2;
        case 'AdhocMockTestT2-result':
            return PERMISSIONS.EXAM_RESULT_ADHOCT2;
    }

}

const getIsBypassAuth = () => {
    return process.env.REACT_APP_BYPASS_AUTHORIZATION === 'true';
}



export const PERM_GROUPS = {
    ORG: [
        PERMISSIONS.CREATE_ORGANIZATION,
        PERMISSIONS.EDIT_ORGANIZATION,
        PERMISSIONS.CHANGE_ORG_STATUS,
        PERMISSIONS.VIEW_ORGANIZATION,
    ]
}
class Authorizer extends Component {
    constructor(props) {
        super(props);
    }


    isAuthorized = () => {
        if (getIsBypassAuth()) {
            return true;
        }

        if (this.props.userInfo && this.props.userInfo.isSuperAdmin) {
            return true;
        }

        return (this.props.permissionIds && this.props.permissionIds.length && this.props.permissionIds.includes(this.props.permId)) ? true : false
    }

    render() {
        return <React.Fragment>
            {this.isAuthorized() && <React.Fragment>
                {this.props.children}
            </React.Fragment>}
        </React.Fragment>;
    }
}


export const isAuthorized = (permissionIds, permId, userInfo) => {
    
    if (getIsBypassAuth()) {
        return true;
    }


    if (userInfo && userInfo.isSuperAdmin) {
        return true;
    }

    return permissionIds.includes(permId);
}


export const isAuthorizedOne = (permissionIds, permIds) => {
    if (getIsBypassAuth()) {
        return true;
    }

    let isAuthorized = false;
    if (permIds?.length) {
        for (let index = 0; index < permIds?.length; index++) {
            const permId = permIds[index];
            if (permissionIds.includes(permId)) {
                isAuthorized = true;
                break
            }
        }
    }
    return isAuthorized;
}


const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});

export default connect(mapStatesToProps, {})(Authorizer);