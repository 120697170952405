import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormFields, isFormValid, onTextChange, onDropDownChange } from '../../../utile/formHelper';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { Button } from 'primereact/button';
import Service from './../../services';
import { Toast } from 'primereact/toast';
import { getBoardsData, getBranchesLatest } from '../../../store/actions';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import withRouter from '../../lib/withRouter';
import LoadingComponent from '../../loadingComponent';
import ClassetDropdown from '../../../classetComponents/classetDropDown';
import { FileAttachmentIcon, MailIcon } from '../../svgIcons';
import ClassetInputText from '../../../classetComponents/classetInputText';
import ClassetCalendar from '../../../classetComponents/classetCalender';
import ClassetMultiSelect from '../../../classetComponents/classetMultiSelect';
import FileUpload from '../../fileUpload';
import ClassetTextArea from '../../../classetComponents/classetTextArea';
import { trimObj } from '../../../utile';

const createStaffFields = require('./createStaff.json');
const formFields = createStaffFields;

class CreateStaff extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(createStaffFields);
        this.state = {
            formValidations: this.formFields.formValidations,
            staffInfo: this.formFields.data,
            isLoading: false,
            genderDropDowns: [
                { label: 'Male', value: 'MALE' },
                { label: 'Female', value: 'FEMALE' }
            ],
            employmentStatusDropdowns: [
                { label: 'Permanent', value: 'Permanent' },
                { label: 'Temporary', value: 'Temporary' }
            ],
            licenceTypes: [
                { label: 'LMV', value: 'LMV' },
                { label: 'LMV-TR', value: 'LMV-TR' },
                { label: 'MGV', value: 'MGV' },
                { label: 'HMV', value: 'HMV' },
                { label: 'HGMV', value: 'HGMV' },
                { label: 'HPMV/HTV', value: 'HPMV/HTV' }
            ],
            designations: [
                { label: 'DRIVER', value: 'DRIVER' },
                { label: 'ATTENDER', value: 'ATTENDER' }
            ]
        };
        this.service = new Service();
    }
    checkAgeCreiteria = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        // Check if birthday has occurred this year
        return age > 18 || (age === 18 && (today.getMonth() > birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate())));
    };
    _onProgress = (filesInfo) => {};
    _onProgress1 = (filesInfo) => {};

    _afterFilesUploaded = (files) => {};

    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    };
    _onFilsUploadError1 = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    };
    _onLicenceFrontPhotoSuccess = (fileName, data) => {
        this.addAttachment('licenceFrontPhoto', fileName, data);
    };
    _afterLicenceFilesUploaded = (files) => { };

    _onLicenceBackPhototPhotoSuccess = (fileName, data) => {
        this.addAttachment('licenceBackPhoto', fileName, data);
    };
    addAttachment = (fieldName, fileName, data) => {
        this.setState((prevState) => ({
            staffInfo: {
                ...prevState.staffInfo,
                [fieldName]: {
                    fileName,
                    storageBucket: data.Bucket,
                    storageKey: data.Key,
                    storageLocation: data.Location
                }
            }
        }));
    };
    onCreateStaff = () => {
        this.setState({
            isLoading: true
        });
        const { staffInfo } = this.state;

        let checkEligibility = staffInfo.designation === "DRIVER" && this.checkAgeCreiteria(staffInfo?.dateOfBirth);

        if (!checkEligibility && staffInfo.designation === "DRIVER") {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Age should be greater than 18 years old', life: 3000 });
            return;
        }
        let isCompleteFormValid = true;

        const formStatus = isFormValid(createStaffFields, this.formFields.formValidations, trimObj(staffInfo));

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations
            });
            isCompleteFormValid = false;
        }
        const requiredFields = [
            { key: 'licenceNo', message: 'Licence No is required' },
            { key: 'licenceExpiryDate', message: 'Licence expiry Date is required' },
            { key: 'licenceType', message: 'Licence Type is required' },
            { key: 'licenceBackPhoto', message: 'Licence Back Photo is required' },
            { key: 'licenceFrontPhoto', message: 'Licence Front Photo is required' }
        ];

        let missingFields = [];

        // Check for missing fields if designation is DRIVER
        if (staffInfo?.designation === 'DRIVER') {
            for (const field of requiredFields) {
                if (!staffInfo?.[field.key]) {
                    missingFields.push(field.message);
                }
            }

            if (missingFields.length === requiredFields.length) {
                // All required fields are missing
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'All fields are required', life: 3000 });
            } else if (missingFields.length > 0) {
                // Show specific missing fields
                this.setState({ isLoading: false });
                this.toast.show({ severity: 'error', summary: 'Error', detail: missingFields.join(', '), life: 3000 });
            }
        }
        if (isCompleteFormValid) {
            let url = `${baseUrlAdmin}/transport-staff`;
            const academicYear = localStorage.getItem('userAcademicYear');
            staffInfo.academicYear = academicYear;
            this.service
                .post(url, staffInfo, true)
                .then((res) => {
                    if (res?.status && res?.res?.status) {
                        this.setState(
                            {
                                isLoading: false
                            },
                            () => {
                                this.toast.show({ severity: 'success', summary: 'Success', detail: res?.res?.message, life: 3000 });
                                this.props.onCancel();
                            }
                        );
                    } else {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.errMessage, life: 3000 });
                    }
                })
                .catch((err) => {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: err.errMessage, life: 3000 });
                });
        } else {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Please fill all the required fields.', detail: 'Please fill all the required fields.', life: 3000 });
        }
    };
    render() {
        const { staffInfo, formValidations, isShowAnalytics, editUser } = this.state;
        return (
            <>
                <div className="grid mt-2 sm:col-12 md:col-12 lg:col-12 column-gap-8 row-gap-5 pl-5">
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            First Name<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={'black'} />}
                                width={363}
                                value={staffInfo.firstName}
                                onChange={(e) => onTextChange(e.target.value, 'firstName', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations')}
                                placeholder="Enter First Name"
                            />
                        </div>
                        {formValidations && !formValidations.fields['firstName'].isValid && <p className="p-error text-lg">{formValidations.fields['firstName'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            Last Name<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                icon={<MailIcon width={24} height={24} color={'black'} />}
                                width={363}
                                value={staffInfo.lastName}
                                onChange={(e) => onTextChange(e.target.value, 'lastName', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations')}
                                placeholder="Enter Last Name"
                            />
                        </div>
                        {formValidations && !formValidations.fields['lastName'].isValid && <p className="p-error text-lg">{formValidations.fields['lastName'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            Gender<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetDropdown
                                width={363}
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                optionLabel="label"
                                optionValue="value"
                                value={staffInfo.gender}
                                options={this.state.genderDropDowns}
                                onChange={(e) => {
                                    onDropDownChange(e.target.value, 'gender', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                }}
                                placeholder="Select Gender"
                            />
                        </div>
                        {formValidations && !formValidations.fields['gender'].isValid && <p className="p-error text-lg">{formValidations.fields['gender'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            Date of Birth<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetCalendar
                                icon={<MailIcon width={24} height={24} color={'black'} />}
                                className=" border-round-md add-vehicle-field-input text-xl w-full"
                                width={363}
                                calendarMode={'single'}
                                value={staffInfo.dateOfBirth && new Date(staffInfo.dateOfBirth)}
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'dateOfBirth', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                }}
                                maxDate={new Date()}
                                placeholder="Select DOB"
                            />
                        </div>
                        {formValidations && !formValidations.fields['dateOfBirth'].isValid && <p className="p-error text-lg">{formValidations.fields['dateOfBirth'].errorMsg}</p>}
                    </div>

                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            Mobile Number<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                width={363}
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                value={staffInfo.mobileNo}
                                keyfilter="int"
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'mobileNo', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                }}
                                placeholder="Enter Mobile Number"
                            />
                        </div>
                        {formValidations && !formValidations.fields['mobileNo'].isValid && <p className="p-error text-lg">{formValidations.fields['mobileNo'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            Emergency Contact Number<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                width={363}
                                icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                value={staffInfo.emergencyContactNo}
                                keyfilter="int"
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'emergencyContactNo', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                }}
                                placeholder="Select Contact Number"
                            />
                        </div>
                        {formValidations && !formValidations.fields['emergencyContactNo'].isValid && <p className="p-error text-lg">{formValidations.fields['emergencyContactNo'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            Employment Status<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetDropdown
                                width={363}
                                optionLabel="label"
                                optionValue="value"
                                value={staffInfo.employmentStatus}
                                options={this.state.employmentStatusDropdowns}
                                keyfilter="int"
                                onChange={(e) => {
                                    onDropDownChange(e.target.value, 'employmentStatus', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                }}
                                placeholder="Employment Status"
                            />
                        </div>
                        {formValidations && !formValidations.fields['employmentStatus'].isValid && <p className="p-error text-lg">{formValidations.fields['employmentStatus'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            Employment Id<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                width={363}
                                value={staffInfo.employmentId}
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'employmentId', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                }}
                                placeholder="Select Employment Id"
                            />
                        </div>
                        {formValidations && !formValidations.fields['employmentId'].isValid && <p className="p-error text-lg">{formValidations.fields['employmentId'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            Designation<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetDropdown
                                width={363}
                                value={staffInfo.designation}
                                optionLabel="label"
                                optionValue="value"
                                options={this.state.designations}
                                onChange={(e) => {
                                    onDropDownChange(e.target.value, 'designation', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                }}
                                placeholder="Enter Designation"
                            />
                        </div>
                        {formValidations && !formValidations.fields['designation'].isValid && <p className="p-error text-lg">{formValidations.fields['designation'].errorMsg}</p>}
                    </div>
                    {staffInfo?.designation == 'DRIVER' && (
                        <>
                            <div className="col-span-1 ">
                                <p className="transport-fee-form-label">
                                    Licence No<span className="ma-required">*</span>
                                </p>
                                <div className="flex">
                                    <ClassetInputText
                                        width={363}
                                        icon={<MailIcon width={24} height={24} color={'#667A85'} />}
                                        value={staffInfo.licenceNo}
                                        onChange={(e) => {
                                            onTextChange(e.target.value, 'licenceNo', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                        }}
                                        placeholder="Enter Licence No"
                                    />
                                </div>
                                {formValidations && !formValidations.fields['licenceNo'].isValid && <p className="p-error text-lg">{formValidations.fields['licenceNo'].errorMsg}</p>}
                            </div>
                            <div className="col-span-1 ">
                                <p className="transport-fee-form-label">
                                    Licence Expiry Date<span className="ma-required">*</span>
                                </p>
                                <div className="flex">
                                    <ClassetCalendar
                                        width={363}
                                        minDate={new Date()}
                                        calendarMode={'single'}
                                        value={staffInfo.licenceExpiryDate && new Date(staffInfo.licenceExpiryDate)}
                                        onChange={(e) => {
                                            onTextChange(e.target.value, 'licenceExpiryDate', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                        }}
                                        placeholder="Enter Licence Expiry Date"
                                    />
                                </div>

                                {formValidations && !formValidations.fields['licenceExpiryDate'].isValid && <p className="p-error text-lg">{formValidations.fields['licenceExpiryDate'].errorMsg}</p>}
                            </div>
                            <div className="col-span-1 ">
                                <p className="transport-fee-form-label">
                                    Licence Type<span className="ma-required">*</span>
                                </p>
                                <div className="flex">
                                    <ClassetDropdown
                                        width={363}
                                        optionLabel="label"
                                        optionValue="value"
                                        value={staffInfo.licenceType}
                                        options={this.state.licenceTypes}
                                        onChange={(e) => {
                                            onDropDownChange(e.target.value, 'licenceType', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                        }}
                                        placeholder="Enter Licence Type"
                                    />
                                </div>
                                {formValidations && !formValidations.fields['licenceType'].isValid && <p className="p-error text-lg">{formValidations.fields['licenceType'].errorMsg}</p>}
                            </div>
                        </>
                    )}
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            Educational Qualification<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetInputText
                                width={363}
                                value={staffInfo.educationalQualification}
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'educationalQualification', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                }}
                                placeholder="Enter Educational Qualification"
                            />
                        </div>
                        {formValidations && !formValidations.fields['educationalQualification'].isValid && <p className="p-error text-lg">{formValidations.fields['educationalQualification'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1 ">
                        <p className="transport-fee-form-label">
                            Date of Joining<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetCalendar
                                width={363}
                                calendarMode={'single'}
                                value={staffInfo.dateOfJoining && new Date(staffInfo.dateOfJoining)}
                                onChange={(e) => {
                                    onTextChange(e.target.value, 'dateOfJoining', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                }}
                                placeholder="Select Date of Joining"
                            />
                        </div>
                        {formValidations && !formValidations.fields['dateOfJoining'].isValid && <p className="p-error text-lg">{formValidations.fields['dateOfJoining'].errorMsg}</p>}
                    </div>
                    <div className="col-span-1 ">
                        <p className="add-vehicle-field-label">
                            Branch<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetDropdown
                                width={363}
                                optionLabel="name"
                                optionValue="key"
                                value={staffInfo.branchId}
                                options={this.props.branchData}
                                onChange={(e) => {
                                    onDropDownChange(e.target.value, 'branchId', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations');
                                }}
                                placeholder={
                                    <div className="flex justify-content-start  align-items-center">
                                        <span className="">Select Branch</span>
                                    </div>
                                }
                            />
                        </div>
                        {formValidations && !formValidations.fields['branchId'].isValid && <p className="p-error text-lg">{formValidations.fields['branchId'].errorMsg}</p>}
                    </div>
                    {staffInfo?.designation == 'DRIVER' && (
                        <>
                            <div className="col-span-1">
                                <p className="transport-fee-form-label">
                                    Upload Licence Front Photo<span className="ma-required">*</span>
                                </p>
                                <div className="flex">
                                    <FileUpload
                                        id={'licenceFrontPhoto'}
                                        multiple={false}
                                        onProgress={this._onProgress}
                                        onSucess={this._onLicenceFrontPhotoSuccess}
                                        maxFileSize={307200}
                                        onError={this._onFilsUploadError}
                                        accept={'images'}
                                        title={'Images'}
                                        afterFilesUploaded={this._afterFilesUploaded}
                                        className="border-solid"
                                    >
                                        <div className="flex items-center">
                                            <ClassetInputText
                                                width={363}
                                                icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                                value={staffInfo.licenceFrontPhoto?.fileName}
                                                placeholder="Upload Photo"
                                                disabled={true}
                                            />
                                            {(staffInfo.licenceFrontPhoto?.storageLocation || staffInfo.licenceFrontPhoto?.storageLocation) && (
                                                <div className="ml-2" >
                                                    <img src={staffInfo.licenceFrontPhoto?.storageLocation || staffInfo.licenceFrontPhoto?.storageLocation} alt="Uploaded Photo" width={50} height={50} />
                                                </div>
                                            )}
                                        </div>
                                    </FileUpload>
                                </div>
                            </div>
                            <div className="col-span-1">
                                <p className="transport-fee-form-label">
                                    Upload Licence Back Photo<span className="ma-required">*</span>
                                </p>
                                <div className="flex">
                                    <FileUpload
                                        id={'licenceBackPhoto'}
                                        multiple={false}
                                        onProgress={this._onProgress1}
                                        onSucess={this._onLicenceBackPhototPhotoSuccess}
                                        //  Reset={this.state.Reset}
                                        maxFileSize={307200}
                                        onError={this._onFilsUploadError1}
                                        accept={'images'}
                                        title={'Images'}
                                        afterFilesUploaded={this._afterLicenceFilesUploaded}
                                        className="border-solid"
                                    >
                                        <div className="flex items-center">
                                            <ClassetInputText
                                                width={363}
                                                icon={<FileAttachmentIcon width={24} height={24} color={'black'} />}
                                                className="add-vehicle-field-input w-full "
                                                value={staffInfo.licenceBackPhoto?.fileName}
                                                placeholder="Upload Photo"
                                                disabled={true}
                                            />
                                            {(staffInfo.licenceBackPhoto?.storageLocation || staffInfo.licenceBackPhoto?.storageLocation) && (
                                                <div className="ml-2" >
                                                    <img src={staffInfo.licenceBackPhoto?.storageLocation || staffInfo.licenceBackPhoto?.storageLocation} alt="Uploaded Photo" width={50} height={50} />
                                                </div>
                                            )}
                                        </div>
                                    </FileUpload>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="col-span-1">
                        <p className="transport-fee-form-label">
                            Address<span className="ma-required">*</span>
                        </p>
                        <div className="flex">
                            <ClassetTextArea
                                placeholder="Address"
                                width={550}
                                value={staffInfo.address}
                                onChange={(e) => onTextChange(e.target.value, 'address', this, formFields, staffInfo, formValidations, 'staffInfo', 'formValidations')}
                            />
                        </div>
                        {formValidations && !formValidations.fields['address'].isValid && <p className="p-error text-lg">{formValidations.fields['address'].errorMsg}</p>}
                    </div>
                </div>
                <div className="flex justify-content-center mb-4 gap-3 mt-8">
                    <Button label={this.props.editData ? 'Update' : 'Save'} className="confirmDialogAcceptBtn px-6 mr-5 w-19rem" onClick={() => this.onCreateStaff()} />
                    <Button label="Cancel" className="confirmDialogCancelBtn" onClick={this.props.onCancel} />
                </div>
                {this.state.isLoading && (
                    <>
                        <LoadingComponent />
                    </>
                )}
                <Toast ref={(el) => (this.toast = el)} position="bottom-right" />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoardsWithoutMeluha'),
    isLoading: state.boardsData.loading,
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    _id: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data._id,
    branchData:
        (state.branchDataLatest &&
            state.branchDataLatest.data &&
            state.branchDataLatest.data.data.filter((each) => {
                if (each.level == 1) {
                    return { ...each };
                }
            })) ||
        []
});

export default connect(mapStateToProps, {
    getBoardsData,
    getBranchesLatest
})(withRouter(CreateStaff));
