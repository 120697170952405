import React, { Component } from 'react'
import { connect } from 'react-redux';
import Service from '../../services';
import ClassetDropdown from '../../classetComponents/classetDropDown';
import { BoardsIconInActive, GradesIcon } from '../svgIcons';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../loadingComponent';
import { getBoardsData, getBranchesLatest } from '../../store/actions';
import { baseUrlAdmin } from '../../store/apiConstants';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
let selectedBoardClasses;
let filteredSections;
class BranchToSectionDDColSEc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boardId: null,
            classId: null,
            groupId: null,
            branchId: null,
            sectionId: this.props?.rowData?.tosectionId?this.props?.rowData?.tosectionId:null ,
            boards: [],
            classes: [],
            curriculumInfo: {},
            selectedrowData: this.props?.rowData
        };
        this.service = new Service()
    }

    componentDidMount() {
        this.getData()
        console.log(this.props?.rowData,"this.props?.rowData")
    }

    componentDidUpdate() {
        if (this.props && (this.props?.defaultDDValues !== undefined && this.props?.defaultDDValues !== null)) {
            const { branchId, boardId, classId, sectionId } = this.props.defaultDDValues


            if (branchId.length && boardId.length && classId.length && sectionId.length) {
                if (this.state.boardId == null && this.state.classId == null && this.state.branchId == null && this.state.sectionId == null) {

                    let filteredBoards = this.props.boards.filter(board => board.assignedBranches.includes(branchId));
                    const selectedBoard = filteredBoards && filteredBoards.find((board) => board.boardId === boardId);
                    let selectedClass = selectedBoard?.classes?.find((classd) => classd.classId == classId)

                    let filteredSections = selectedClass?.sections?.filter((sec) => sec.branchId == branchId)

                    this.setState({
                        boardId: boardId,
                        classId: classId,
                        branchId: branchId,
                        sectionId: sectionId,
                        boards: filteredBoards,
                        classes: selectedBoard.classes,
                        sections: filteredSections
                    }, () => this.setDropdownsData())


                }
            }

        }


    }

    getData = () => {
        this.props.getBranchesLatest();
        this.props.getBoardsData(true)
    }

    onChangeBranch = (branchId) => {
        this.setState({
            boards: [], classes: [], sections: [],
        });
        let selectedbranchName = this.props.branchData?.find((branchd) => branchd.key == branchId).name;
        let filteredBoards = this.props.boards.filter(board => board.assignedBranches.includes(branchId));
        this.setState({
            branchId: branchId,
            selectedbranchName: selectedbranchName,
            boards: filteredBoards,
            classes: [],
            boardId: '',
            selectedBoardName: '',
            classId: '',
            selectedClass: {},
            branches: [],
            selectedClassName: '',
            sections: [],
            sectionId: '',
            selectedsectionName: '',
        });
    };

    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], sections: [],
        });
        const selectedBoard = this.state.boards && this.props.boards.find((board) => board.boardId === boardId);

        this.setState({
            classes: selectedBoard.classes,
            boardId,
            selectedBoardName: selectedBoard.boardName,
            classId: '',
            selectedClass: {},
            branches: [],
            selectedClassName: '',
            sections: [],
            sectionId: '',
            selectedsectionName: '',

        }, () => this.setDropdownsData());
    };

    onChangeClass = (classId) => {
        let selectedClass = this.state.classes?.find((classd) => classd.classId == classId)

        let filteredSections = selectedClass?.sections.filter((sec) => sec.branchId == this.state.branchId)

        this.setState({
            classId: classId,
            selectedClass: selectedClass,
            branches: selectedClass.branches,
            selectedClassName: selectedClass.className,
            sections: filteredSections,
            sectionId: '',
            selectedsectionName: '',
        }, () => this.setDropdownsData());
    };

    onChangeSection = (sectionId) => {
        

        let sectionName=filteredSections&& filteredSections.length>0 && filteredSections.find((sec) => sec.sectionId == sectionId)?.sectionName
     
        this.setState({
            sectionId: sectionId,
            selectedsectionName: sectionName,
        }, () => this.setDropdownsData());
    };

    setDropdownsData = () => {
        let {
            boardId,
            classId,
            branchId,
            sectionId,
            selectedBoardName,
            selectedClassName, selectedbranchName, selectedsectionName
        } = this.state
        let returnData = {
            boardId, classId, branchId, sectionId, selectedBoardName,
            selectedClassName, selectedbranchName, selectedsectionName
        }
        this.props.setValues(returnData)
    }



    render() {
        selectedBoardClasses = this.props?.boards.find(board => {
            return board?.boardId === this.state.selectedrowData?.fmboardId;
        })?.classes || [];

        filteredSections = selectedBoardClasses.find(e => e.classId == this.state.selectedrowData?.fmclassId)?.sections.filter((sec) => sec.branchId == this.state.selectedrowData?.fmbranchId)
  
        return (
            <div>
                <div >
                    <div className='grid '>
                        <div className="col-12">
                            <div className="row-3 my-2">
                                <ClassetDropdown required={true} label={'Branch'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Branch'} options={this.props?.branchData}
                                    value={this.state.selectedrowData?.fmbranchId} disabled={this.state.selectedrowData?.fmbranchId} onChange={(e) => this.onChangeBranch(e.value)} optionLabel="name"
                                    optionValue="key" />
                            </div>
                            <div className="row-3 my-2">
                                <ClassetDropdown required={true} label={'Board'} icon={<BoardsIconInActive height={16.5} width={19.5} />} placeholder={'Select Board'} options={this.props?.boards} value={this.state.selectedrowData?.fmboardId} onChange={(e) => this.onChangeBoard(e.value)} optionLabel="boardName"
                                    optionValue="boardId" disabled={this.state.selectedrowData?.fmboardId} />
                            </div>
                            <div className="row-3 my-2">
                                <ClassetDropdown required={true} label={'Grade'} icon={<GradesIcon />} placeholder={'Select Grade'} options={selectedBoardClasses}
                                    value={this.state.selectedrowData?.fmclassId} onChange={(e) => this.onChangeClass(e.value)} optionLabel="className"
                                    optionValue="classId" disabled={this.state.selectedrowData?.fmclassId} />
                            </div>
                            <div className="row-3 my-2">
                                <ClassetDropdown required={true} label={'Section'} icon={<GradesIcon />} placeholder={'Select Section'} options={filteredSections}
                                    value={this.state?.sectionId} onChange={(e) => this.onChangeSection(e.value)} optionLabel="sectionName"
                                    optionValue="sectionId" />
                            </div>
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <>
                    <LoadingComponent />
                </>}
            </div>
        )
    }
}

const mapStatesToProps = (state) => ({
    // boards: state.boardsData.boards,
    boards: userAssignedBoards(state, 'activeBoards'),
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data.filter((each => {
        if (each.level == 1) {
            return { ...each }
        }
    })) || [],
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, { getBoardsData, getBranchesLatest })(BranchToSectionDDColSEc);