import React, { Component } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';

// import Navbar from './../navbar';
import Authorizer, { PERMISSIONS, isAuthorized } from '../session/authorizer';
import Authentication from '../session';
import { connect } from 'react-redux';
import withRouter from '../lib/withRouter';
import { TabMenu } from 'primereact/tabmenu';
import { primaryColor } from '../../store/apiConstants';
import FeeType from './FeeType/feeType';
import FeeCategory from './FeeCatagory/feeCategory';
import FeeStructure from './FeeStructure/feeStructure';
// import GeneralSettings from './GeneralSettings.js/generalSettings';
import './styles.scss'
import FeeInstallments from '../schoolSetup/installments'
const items = [
    { label: 'Management' },
    { label: 'Transport Management', className: 'ma-active-item' },
    // { label: 'Role Creation', className: 'ma-active-item' }
];

const home = { icon: 'pi pi-home' }

const tabs = [
    { label: 'Fee Type' },
    { label: 'Fee Category' },
    { label: 'Fee Structure' },
    { label: 'General Settings' },
];


class FeeSetUpIndex extends Component {


    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
        };
    }


    onTabChange = (currentTab) => {
        this.setState({
            currentTab
        })
    }




    render() {

        return (
            <div>


                <div className="">
                    <div className="grid ml-1 mr-1 h-4rem">
                        <div className="md:col-12 lg:col-12 xl:col-12">
                            <div className="grid doubts-tabs ">
                            <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 0 ? { borderBottom: `2px solid ${primaryColor}` } : { borderBottom: '2px solid lightgrey' }}>
                                    <p className="tab-p" onClick={() => { this.onTabChange(0) }}>Fee Installments</p>
                                </div>
                                 
                                <div className="cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 1? { borderBottom: `3px solid ${primaryColor}` } : { borderBottom: '1px solid lightgrey' }}>
                                    <p className="tabheadText" onClick={() => { this.onTabChange(1) }}>Fee Type</p>
                                </div>


                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab === 2 ? { borderBottom: `2px solid ${primaryColor}` } : { borderBottom: '2px solid lightgrey' }}>
                                    <p className="tab-p" onClick={() => { this.onTabChange(2) }}>Fee Category</p>
                                </div>


                                <div className=" cursor-pointer col-3 doubts-tab-w" style={this.state.currentTab ===3 ? { borderBottom: `2px solid ${primaryColor}` } : { borderBottom: '2px solid lightgrey' }}>
                                    <p className="tab-p" onClick={() => { this.onTabChange(3) }}>Fee Structure</p>
                                </div>


                                
                            </div>
                        </div>
                    </div>
                    {
                        this.state.currentTab ==1 && <>
                            <Authorizer permId={PERMISSIONS.FEE_TYPES_LIST} >
                                <FeeType />
                            </Authorizer>
                        </>
                    }
                    {
                        this.state.currentTab == 2 && <><Authorizer permId={PERMISSIONS.FEE_CATEGORY_LIST} >
                            <FeeCategory />
                        </Authorizer>
                        </>
                    }
                    {
                        this.state.currentTab == 3 && <> <Authorizer permId={PERMISSIONS.FEE_STRUCTURE_LIST} >
                            <FeeStructure />
                        </Authorizer>
                        </>
                    }
                     {
                        this.state.currentTab == 0 && <> <Authorizer permId={PERMISSIONS.GENERAL_SETTINGS} >
                            {/* <GeneralSettings /> */}
                            <FeeInstallments />
                        </Authorizer>
                        </>
                    }
                    {/* {
                        this.state.currentTab == 3 && <> <Authorizer permId={PERMISSIONS.GENERAL_SETTINGS} >
                            <GeneralSettings />
                        </Authorizer>
                        </>
                    } */}
                </div>

            </div >
        )
    }
}

const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});
export default connect(mapStatesToProps, {})(Authentication(withRouter(FeeSetUpIndex)));